import axios from 'axios'

export function getDirtyValues(values, initialValues): any {
  const dirtyValues = {}
  Object.keys(values).forEach((key) => {
    const currentValue = values[key]
    const initialValue = initialValues[key]
    if (currentValue !== initialValue) {
      dirtyValues[key] = currentValue
    }
  })
  return dirtyValues
}

// calculate days and/or minutes until date
export function daysUntilDate(date: Date = new Date(), floor: boolean = false): string {
  // convert date to date object
  if (date instanceof Date === false) date = new Date(date)

  const now = new Date()
  const diff = date.getTime() - now.getTime()
  const years = Math.ceil(diff / (1000 * 60 * 60 * 24 * 365))
  const months = Math.ceil(diff / (1000 * 60 * 60 * 24 * 30))
  const days = Math.ceil(diff / (1000 * 60 * 60 * 24))
  const hours = Math.ceil(diff / (1000 * 60 * 60))

  if (Math.abs(years) > 1) {
    const label = years > 1 ? ' years' : ' year'
    return floor ? Math.abs(years) + label : years + label
  } else if (Math.abs(months) > 1) {
    const label = months > 1 ? ' months' : ' month'
    return floor ? Math.abs(months) + label : months + label
  } else if (Math.abs(days) > 1) {
    const label = days > 1 ? ' days' : ' day'
    return floor ? Math.abs(days) + label : days + label
  } else if (Math.abs(hours) > 1 / 24) {
    // if the difference is more than an hour
    const hours = Math.ceil(Math.abs(diff) / (1000 * 60 * 60))
    const label = hours > 1 ? ' hours' : ' hour'
    return floor ? Math.abs(hours) + label : hours + label
  } else {
    const minutes = Math.ceil(Math.abs(diff) / (1000 * 60))
    const label = minutes > 1 ? ' minutes' : ' minute'
    return floor ? Math.abs(minutes) + label : minutes + label
  }
}

// get random bootstrap color
export function randomBSColor(excludeColors: string[] = []): string {
  const colors = ['primary', 'secondary', 'success', 'danger', 'warning', 'info']
  const availableColors = colors.filter((c) => !excludeColors.includes(c))
  const r = availableColors[Math.floor(Math.random() * availableColors.length)]
  return r
}

// format number. Add a thousand separator as a space.  Only allow two decimal places
export function formatNumber(value: number = 0, decimals: number = 2, shorten = false): string {
  if (shorten && value > 999) {
    const suffixes = ['', 'K', 'M', 'B', 'T']
    const tier = Math.floor(Math.log10(value) / 3)
    const suffix = suffixes[tier]

    // Scale the number to the corresponding tier.
    const scaled = value / Math.pow(10, tier * 3)

    // Round to one decimal place if necessary.
    const rounded = scaled % 1 === 0 ? scaled.toFixed(0) : scaled.toFixed(1)

    return `${rounded}${suffix}`
  } else {
    try {
      if (decimals === 0) {
        return value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      } else {
        return value.toFixed(decimals).replace(/\d(?=(\d{3})+\.)/g, '$&,')
      }
    } catch (err) {
      return ''
    }
  }
}

// get currency symbol for currency code
export function formatMoney(
  value: number = 0,
  currency: string = 'ZAR',
  decimals: number = 2,
  shorten: boolean = false
): string {
  return `${getCurrencySymbol(currency)}${formatNumber(value, decimals, shorten)}`
}

export function formatSeconds(seconds: number, shorten: boolean = false): string {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const remainingSeconds = (seconds % 60).toFixed(0)

  if (!shorten) {
    return `${hours}:${minutes}:${remainingSeconds}`
  } else {
    // only show the highest value
    if (hours > 0) {
      return `${hours}h`
    } else if (minutes > 0) {
      return `${minutes}m`
    } else {
      return `${remainingSeconds}s`
    }
  }
}

// trim string to 12 words
export function trimString(str: string, limit): string {
  if (!str) return ''
  // add '...' to the end of the string if it's longer than the limit
  const ellipsis = str.split(' ').length > limit ? '...' : ''
  return str.split(' ').splice(0, limit).join(' ') + ellipsis
}

// trim characters from string
export function trimCharacters(str: string, limit): string {
  if (!str) return ''
  return str.length > limit ? str.substring(0, limit) + '...' : str
}

// format date in d M Y
export function formatDateDMY(date: string): string {
  return new Date(date).toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  })
}

// format date in DMY H:I
export function formatDateDMYHI(date: string = new Date().toString()): string {
  return new Date(date).toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  })
}

export function formatTimeHI(date: string): string {
  return new Date(date).toLocaleDateString('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
  })
}

export function getCurrencySymbol(currency: string = 'ZAR'): string {
  const currencySymbols = {
    ZAR: 'R',
    USD: '$',
    GBP: '£',
    EUR: '€',
    JPY: '¥',
    CNY: '¥',
    INR: '₹',
    AUD: '$',
    CAD: '$',
    CHF: 'Fr',
    SEK: 'kr',
    NZD: '$',
    MXN: '$',
    SGD: '$',
    HKD: '$',
    NOK: 'kr',
    KRW: '₩',
    TRY: '₺',
    RUB: '₽',
    BRL: 'R$',
    ZMW: 'ZK',
    KES: 'KSh',
    NGN: '₦',
    GHS: 'GH₵',
    UGX: 'USh',
    TZS: 'TSh',
    MZN: 'MT',
    XAF: 'FCFA',
    XOF: 'CFA',
  }

  return currencySymbols[currency] || currency
}

/**
 * Copy values of existing object into a new Type.  exclude keys that are not in the new Type
 */
export function copyValues<T extends object, U extends T>(obj: T, newType: U): U {
  const newObj = {} as U

  Object.keys(newType).forEach((key) => {
    newObj[key] = obj[key]
  })

  return newObj
}

export function replacePhoneCountryCodeWithZero(phone: string): string {
  if (!phone) return ''
  const s = phone.toString().replace(/^27/, '0')
  return s
}

export function generateTicketQRCode(ticketId: string): string {
  const url = process.env.REACT_APP_PUBLIC_URL
  const text = `${url}/check/${ticketId}`
  return `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${text}`
}

export function pick(object, keys) {
  return keys.reduce((obj, key) => {
    if (object && Object.prototype.hasOwnProperty.call(object, key)) {
      // eslint-disable-next-line no-param-reassign
      obj[key] = object[key]
    }
    return obj
  }, {})
}

export function omit(object, keys) {
  const shallowCopy = {...object}
  keys.forEach((key) => delete shallowCopy[key])
  return shallowCopy
}

export function pluralise(count: number, word: string): string {
  return count === 1 ? word : `${word}s`
}

export function generateRandomKey(): string {
  return Math.random().toString(36).substring(7)
}

export async function uploadFile(file, onProgress) {
  if (!file || !file.type) return

  // only accept images or pdfs
  if (!file.type.includes('image') && !file.type.includes('pdf')) {
    throw new Error('The file must be an image or a pdf')
  }

  // Ensure the contentType matches exactly what AWS expects
  const response = await axios.get('/aws/getSignedUrl', {params: {contentType: file.type}})
  const {url} = response.data

  if (!url) new Error('Failed to get signed url')

  // create new instance of axios

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open('PUT', url)

    // Ensure the Content-Type header matches the file type
    xhr.setRequestHeader('Content-Type', file.type)

    xhr.upload.onprogress = function (event) {
      if (event.lengthComputable) {
        const progress = (event.loaded / event.total) * 100
        if (onProgress) {
          onProgress(progress)
        }
      }
    }

    xhr.onload = function () {
      if (xhr.status === 200) {
        console.log('File uploaded successfully')
        resolve(url.split('?')[0]) // Return the S3 file URL without the presigned query string
      } else {
        reject(new Error('Failed to upload file: ' + xhr.responseText))
      }
    }

    xhr.onerror = function () {
      console.error('Error during the upload process.')
      reject(new Error('Error during the upload process.'))
    }

    xhr.send(file.slice(0, file.size))
  })
}

export function isValidEmail(email: string | null | undefined) {
  if (!email) return false

  const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regex.test(email.toLowerCase())
}

export function isHandle(handle: string): boolean {
  // exclude certain handles
  const exclude = [
    'event',
    'events',
    'booking',
    'bookings',
    'test',
    'tests',
    'example',
    'examples',
    'skybookings',
    'auth',
    'api',
    'app',
    'apps',
    'web',
    'website',
    'public',
    'private',
    'admin',
    'administrator',
    'moderator',
    'moderators',
    'login',
    'register',
    'forgot',
    'reset',
    'verify',
    'confirm',
    'logout',
    'account',
    'accounts',
    'settings',
    'profile',
    'profiles',
    'user',
    'users',
    'patron',
    'patrons',
    'client',
    'clients',
    'ticket',
    'wallet',
    'scanner',
    'scanners',
    'public',
    '404',
    '500',
    '403',
    '401',
    '400',
    '200',
    '300',
    '100',
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
  ]
  if (exclude.includes(handle)) return false

  return /^[a-z0-9_]{3,25}$/.test(handle)
}

export const loadScript = (src) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = src
    script.onload = () => resolve(script)
    script.onerror = () => reject(new Error(`Failed to load script: ${src}`))
    document.body.appendChild(script)
  })
}

export const formatPhoneNumber = (phoneNumber: string, partialhide: boolean = false) => {
  if (!phoneNumber) return ''
  // retplace first 27 with 0
  phoneNumber = phoneNumber.toString().replace(/^27/, '0')

  // add spaces between numbers
  const formatted = phoneNumber.toString().replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3')

  if (partialhide) {
    // hide middle 3 digits
    return formatted.replace(/(\d{3})\s(\d{3})\s(\d{4})/, '$1 *** $3')
  }

  return formatted
}

export const partialHideEmail = (email: string): string => {
  const [username, domain] = email.split('@')

  // show first, last char of username and * in between
  const hidden =
    username.substring(0, 1) +
    '*'.repeat(username.length - 2) +
    username.substring(username.length - 1)

  return `${hidden}@${domain}`
}

/**
 * Turn camelCase string into normal sentence with spaces
 * @param str
 * @returns
 */

export const normaliseCamelCase = (str: string): string => {
  if (!str) return ''
  return str.replace(/([A-Z])/g, ' $1').replace(/^./, (s) => s.toUpperCase())
}

export const capitaliseFirstLetter = (string: string) => {
  if (!string) return ''
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const removeHtmlTags = (str: string): string => {
  if (!str) return ''
  // convert escaped html tags to normal tags
  str = str.replace(/&lt;/g, '<').replace(/&gt;/g, '>')

  // remove all html tags
  return str.replace(/<[^>]*>/g, '')
}

export const isMongoId = (id: string): boolean => {
  return /^[a-f\d]{24}$/i.test(id)
}
