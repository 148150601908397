import clsx from 'clsx'
import {getCSSVariableValue} from '../../assets/ts/_utils'
import {KTIcon} from '../../helpers'
import {Link, Navigate, useNavigate} from 'react-router-dom'
import {useEventsStore} from '../../../app/modules/events/core/eventsStore'
import {useMessageStore} from '../../../app/pages/messages/core/messagesStore'

/* eslint-disable jsx-a11y/anchor-is-valid */
type Props = {
  toggleBtnClass?: string
  toggleBtnIconClass?: string
  menuPlacement?: string
  menuTrigger?: string
}

const CreateMenu = ({
  toggleBtnClass = '',
  toggleBtnIconClass = 'fs-1',
  menuPlacement = 'bottom-end',
  menuTrigger = "{default: 'click', lg: 'hover'}",
}: Props) => {
  const {unsetCurrentEvent} = useEventsStore()
  const {unsetCurrentMessage} = useMessageStore()
  const navigate = useNavigate()

  const handleCreateEvent = () => {
    unsetCurrentEvent()
    navigate('events/create')
  }

  const handleCreateMessage = () => {
    unsetCurrentMessage()
    navigate('messages/create')
  }

  return (
    <>
      {/* begin::Menu toggle */}
      <a
        href='#'
        className={clsx('btn btn-active-secondary btn-sm', toggleBtnClass)}
        style={{border: `1px solid ${getCSSVariableValue('--bs-secondary')}`}}
        data-kt-menu-trigger={menuTrigger}
        data-kt-menu-attach='parent'
        data-kt-menu-placement={menuPlacement}
      >
        <KTIcon
          iconName='plus-circle'
          iconType='outline'
          className={clsx('fs-3', toggleBtnIconClass)}
        />
        Create
      </a>
      {/* begin::Menu toggle */}

      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-175px'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item px-3 my-0'>
          <a onClick={handleCreateEvent} className={clsx('menu-link px-3 py-2')}>
            <span className='menu-icon' data-kt-element='icon'>
              <KTIcon iconName='calendar' iconType='outline' className='fs-1' />
            </span>
            <span className='menu-title'>Create Event</span>
          </a>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item */}
        <div className='menu-item px-3 my-0'>
          <a onClick={handleCreateMessage} className={clsx('menu-link px-3 py-2')}>
            <span className='menu-icon' data-kt-element='icon'>
              <KTIcon iconName='messages' iconType='outline' className='fs-1' />
            </span>
            <span className='menu-title'>Create Message</span>
          </a>
        </div>
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  )
}

export {CreateMenu}
