import {FC} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, KTIcon, WithChildren} from '../../../../helpers'
import {useLayout} from '../../../core'
import {isMobileDevice} from '../../../../assets/ts/_utils'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  className?: string
  onClick?: () => void
}

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
  onClick = () => {},
  className = '',
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {app} = config

  const handleClick = () => {
    // emit onClick
    return onClick()
  }

  return (
    <div className={`menu-item ${className}`}>
      <Link
        onClick={handleClick}
        className={clsx('menu-link without-sub', {active: isActive})}
        to={to}
      >
        <div className='d-flex flex-column align-items-center'>
          {hasBullet && (
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
          )}
          {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
            <span className='menu-icon'>
              {' '}
              <KTIcon
                iconName={icon}
                iconType={isActive ? 'solid' : 'outline'}
                className='fs-2 text-dark'
              />
            </span>
          )}
          {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
            <i className={clsx('bi fs-3', fontIcon)}></i>
          )}
          {!isMobileDevice() && (
            <span className={clsx('small-menu-title', {'fw-bold text-dark': isActive === true})}>
              {title}
            </span>
          )}
        </div>
        <span className={clsx('menu-title', {'fw-bold text-dark': isActive === true})}>
          {title}
        </span>
      </Link>
      {children}
    </div>
  )
}

export {SidebarMenuItem}
