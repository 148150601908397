import {FC} from 'react'
import {QueryPartModel} from '../../../modules/patrons/core/patronStore'
import {isMongoId, normaliseCamelCase} from '../../../../_helpers/_helpers'
import {EventModel, eventsStore} from '../../../modules/events/core/eventsStore'

type Props = {
  queryPart: QueryPartModel
  mode?: 'badge' | 'text'
  showConnector: boolean
  events: EventModel[]
}

const allOperators = [
  {
    label: 'is',
    value: 'eq',
  },
  {
    label: 'is not',
    value: 'neq',
  },
  {
    label: 'is greater than',
    value: 'gt',
  },
  {
    label: 'is less than',
    value: 'lt',
  },
  {
    label: 'contains',
    value: 'contains',
  },
  {
    label: 'does not contain',
    value: 'ncontains',
  },
  {
    label: 'include',
    value: 'in',
  },
  {
    label: 'exclude',
    value: 'nin',
  },
]

const QueryPartBadge: FC<Props> = (props) => {
  const {events = [], queryPart, mode = 'badge', showConnector = false} = props
  return (
    <>
      {mode === 'badge' ? (
        <div className='d-flex align-items-center mb-3'>
          <div className='d-flex align-items-center border border-secondary p-3 px-5 rounded bg-white '>
            <div className='d-flex'>
              <span className='fw-bold me-3'>{normaliseCamelCase(queryPart.field)}</span>
              <span className='text-muted me-3'>
                {allOperators.find((f) => f.value === queryPart.operator)?.label}
              </span>
              <span className='fw-bold'>
                {isMongoId(queryPart.value)
                  ? events.find((e) => e.id === queryPart.value)?.name
                  : queryPart.value}
              </span>
            </div>
          </div>
          {showConnector && (
            <div className='d-flex mx-3'>
              <span className='fw-bold fs-7 badge badge-secondary '>{queryPart.connector}</span>
            </div>
          )}
        </div>
      ) : (
        <>
          {`${normaliseCamelCase(queryPart.field).toLowerCase()} `}

          {` ${allOperators.find((f) => f.value === queryPart.operator)?.label} `}

          {` ${
            isMongoId(queryPart.value)
              ? events.find((e) => e.id === queryPart.value)?.name
              : queryPart.value
          } `}

          {showConnector === true && ` ${queryPart.connector.toLowerCase()} `}
        </>
      )}
    </>
  )
}

export default QueryPartBadge
