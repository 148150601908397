import React, {useState} from 'react'
import {ErrorMessage, Field, Form, Formik, FormikValues} from 'formik'
import clsx from 'clsx'
import * as Yup from 'yup'
import {useEventsStore} from '../core/eventsStore'
import 'react-autocomplete-input/dist/bundle.css'
import NumberInput from '../../../../_components/NumberInput'
import {KTIcon} from '../../../../_metronic/helpers'
import {isMobileDevice} from '../../../../_metronic/assets/ts/_utils'
import {Modal} from 'react-bootstrap'
import {pluralise} from '../../../../_helpers/_helpers'
import TiptapEditor from '../../../../_components/TipTap/Tiptap'
import placeholders from '../../../../_helpers/_placeholders'

// write validation schema with yup
const validationSchema = Yup.object().shape({
  pendingNotification: Yup.string().required('Pending notification is required'),
  pendingEmail: Yup.string().required('Pending email is required'),

  completedNotification: Yup.string().required('completed notification is required'),
  completedEmail: Yup.string().required('Completed email is required'),

  cancelledNotification: Yup.string().required('Cancelled notification is required'),
  cancelledEmail: Yup.string().required('Cancelled notification is required'),

  beforeNotification: Yup.string().required('Reminder notification is required'),
  beforeEmail: Yup.string().required('Reminder notification is required'),
  beforeDays: Yup.number(),

  afterNotification: Yup.string().required('Thank you notification is required'),
  afterEmail: Yup.string().required('Thank you notification is required'),
  afterDays: Yup.number(),
})

type Props = {
  onValidation?: (values: FormikValues) => void
  onSave?: (values: FormikValues) => void
  step?: string
}

const EventNotifications = (props: Props) => {
  const {step} = props
  const [loading, setLoading] = useState(false)
  const [saveMessage, setSaveMessage] = useState('')
  const [saveIsError, setSaveIsError] = useState<boolean | undefined>(undefined)
  const {currentEvent, saveEvent} = useEventsStore()
  const [showPendingModal, setShowPendingModal] = useState(false)
  const [showCompletedModal, setShowCompletedModal] = useState(false)
  const [showCancelledModal, setShowCancelledModal] = useState(false)
  const [showAbandonedModal, setShowAbandonedModal] = useState(false)
  const [showReminderModal, setShowReminderModal] = useState(false)
  const [showThankYouModal, setShowThankYouModal] = useState(false)

  const handleSubmit = (values, actions: FormikValues) => {
    setLoading(true)

    saveEvent(values)
      .then((response) => {
        setSaveIsError(false)
        setSaveMessage('Event successfully updated')

        setTimeout(() => {
          setSaveIsError(undefined)
        }, 3000)
      })

      .catch((err) => {
        setSaveIsError(true)
        setSaveMessage(err.message)
      })

      .finally(() => setLoading(false))
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 p-5 p-md-10 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_event_notifications'
        aria-expanded='true'
        aria-controls='kt_event_notifications'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>{step && step + '. '}Patron Notifications</h3>
          {/* loading  */}
        </div>
        <div className='card-toolbar'>
          {loading ? (
            <div className='spinner-border spinner-border-sm'></div>
          ) : (
            <>
              {saveIsError === true && (
                <KTIcon iconName='information' iconType='solid' className='text-danger fs-3' />
              )}
              {saveIsError === false && (
                <KTIcon iconName='check-circle' iconType='solid' className='text-success fs-3' />
              )}
            </>
          )}
        </div>
      </div>

      <div id='kt_event_notifications' className='show'>
        <Formik
          validationSchema={validationSchema}
          initialValues={currentEvent}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {(form) => {
            return (
              <Form>
                <>
                  <div className='card-body border-top p-5  p-md-10'>
                    <div className='d-flex notifications-settings flex-column'>
                      {/* PENDING NOTIFICATION */}
                      <div className='notification-item mb-3'>
                        <div className='d-flex flex-column'>
                          <div className='d-flex align-items-start justify-content-between'>
                            <label className='form-check form-check-custom cursor-pointer text-dark form-check-solid align-items-start mb-5'>
                              <Field
                                className='form-check-input mt-2 me-3'
                                type='checkbox'
                                name='pendingEnabled'
                                disabled={loading}
                                // update form values when checkbox is clicked
                                onClick={() => form.submitForm()}
                              />
                              <span className='form-check-label d-flex flex-column align-items-start'>
                                <span
                                  className={clsx('fw-bolder fs-5 text-dark mb-0', {
                                    'text-dark': form.values.pendingEnabled === true,
                                    'text-gray-800': form.values.pendingEnabled === false,
                                  })}
                                >
                                  Send 'Pending Bookings' Notifications
                                </span>
                                <span className='text-muted fw-normal fs-6'>
                                  Sent when a booking status is set to pending
                                </span>
                              </span>
                            </label>
                            {form.values.pendingEnabled && (
                              <div
                                className='btn btn-light btn-sm fs-7'
                                onClick={() => setShowPendingModal(true)}
                              >
                                <KTIcon iconName='pencil' iconType='outline' />
                                {!isMobileDevice() && 'customise'}
                              </div>
                            )}

                            {/* START PENDING MODAL */}
                            <Modal
                              size='lg'
                              onHide={() => setShowPendingModal(false)}
                              show={showPendingModal}
                            >
                              <Modal.Header closeButton>
                                <Modal.Title>Customise Pending Notifications</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <div className='accordion' id='accordionEventNotifications'>
                                  <div className='accordion-item mb-3'>
                                    <h2 className='accordion-header' id='headingShortMessage'>
                                      <button
                                        className='accordion-button bg-light collapsed text-dark'
                                        type='button'
                                        data-bs-toggle='collapse'
                                        data-bs-target='#collapseShortMessage'
                                        aria-expanded='false'
                                        aria-controls='collapseShortMessage'
                                      >
                                        <div className='d-flex flex-column'>
                                          <span className='fw-bold fs-5 mb-2'>
                                            Customise Short Message
                                          </span>
                                          <span className='text-muted fw-normal'>
                                            Short message sent via sms or push notification
                                          </span>
                                        </div>
                                      </button>
                                    </h2>
                                    <div
                                      id='collapseShortMessage'
                                      className='accordion-collapse collapse'
                                      aria-labelledby='headingShortMessage'
                                      data-bs-parent='#accordionEventNotifications'
                                    >
                                      <div className='accordion-body'>
                                        <div className='row'>
                                          <div className='col'>
                                            <label className='fw-bold fs-6 mb-5'>
                                              Short Message:
                                              <span className='text-muted fw-normal ms-3'>
                                                short message sent via sms or push notification
                                              </span>
                                            </label>

                                            <TiptapEditor
                                              name='pendingNotification'
                                              className='form-control form-control-solid'
                                              suggestions={[
                                                ...placeholders.generic,
                                                ...placeholders.event,
                                                ...placeholders.booking,
                                              ]}
                                              showSuggestions={true}
                                            />
                                            <ErrorMessage name='pendingNotification'>
                                              {(msg) => <div className='text-danger'>{msg}</div>}
                                            </ErrorMessage>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className='accordion-item mb-3'>
                                    <h2 className='accordion-header' id='headingPendingEmail'>
                                      <button
                                        className='accordion-button text-dark bg-light collapsed'
                                        type='button'
                                        data-bs-toggle='collapse'
                                        data-bs-target='#collapsePendingEmail'
                                        aria-expanded='false'
                                        aria-controls='collapsePendingEmail'
                                      >
                                        <div className='d-flex flex-column'>
                                          <span className='fw-bold fs-5 mb-2'>Customise Email</span>
                                          <span className='text-muted fw-normal'>
                                            Email sent when a booking status is set to pending
                                          </span>
                                        </div>
                                      </button>
                                    </h2>
                                    <div
                                      id='collapsePendingEmail'
                                      className='accordion-collapse collapse'
                                      aria-labelledby='headingPendingEmail'
                                      data-bs-parent='#accordionEventNotifications'
                                    >
                                      <div className='accordion-body'>
                                        <div className='row mb-10'>
                                          <div className='col'>
                                            <label className='fw-bold mb-5 fs-6'>
                                              Pending Email Subject
                                            </label>
                                            <TiptapEditor
                                              suggestions={[
                                                ...placeholders.generic,
                                                ...placeholders.event,
                                                ...placeholders.booking,
                                              ]}
                                              name='pendingEmailSubject'
                                              className='form-control form-control form-control-solid'
                                            />
                                            <ErrorMessage name='pendingEmailSubject'>
                                              {(msg) => <div className='text-danger'>{msg}</div>}
                                            </ErrorMessage>
                                          </div>
                                        </div>
                                        <div className='row'>
                                          <div className='col'>
                                            <label className='fw-bold mb-5 fs-6'>
                                              Email Content
                                            </label>
                                            <TiptapEditor
                                              name='pendingEmail'
                                              className='form-control form-control form-control-solid'
                                              suggestions={[
                                                ...placeholders.generic,
                                                ...placeholders.event,
                                                ...placeholders.booking,
                                              ]}
                                              showToolbar
                                              showSuggestions={false}
                                            />
                                            <ErrorMessage name='pendingEmail'>
                                              {(msg) => <div className='text-danger'>{msg}</div>}
                                            </ErrorMessage>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <div className='d-flex flex-column align-items-end'>
                                  <div className='d-flex'>
                                    <button
                                      type='button'
                                      className='btn btn-secondary me-3'
                                      onClick={() => setShowPendingModal(false)}
                                    >
                                      Close
                                    </button>
                                    <button
                                      type='button'
                                      onClick={() => {
                                        form.submitForm()
                                        setShowPendingModal(false)
                                      }}
                                      className='btn btn-primary'
                                    >
                                      {loading ? (
                                        <>
                                          <span className='spinner-border spinner-border-sm'></span>
                                          Just a sec...
                                        </>
                                      ) : (
                                        'Save & Close'
                                      )}
                                    </button>
                                  </div>
                                  {(saveIsError === true || saveIsError === false) && (
                                    <div className='d-flex align-items-end fs-7 fw-bold me-0 py-3 px-6'>
                                      <span
                                        className={clsx('text', {
                                          'text-danger': saveIsError === true,
                                          'text-success': saveIsError === false,
                                        })}
                                      >
                                        {saveMessage}
                                      </span>{' '}
                                    </div>
                                  )}
                                </div>
                              </Modal.Footer>
                            </Modal>
                          </div>
                        </div>

                        <div
                          id='flush-collapseOne'
                          className='accordion-collapse collapse'
                          aria-labelledby='flush-headingOne'
                          data-bs-parent='#accordionFlushExample'
                        >
                          <div className='accordion-body'>
                            <div className='fv-row mb-10'>
                              <div className='row'>
                                <div className='col'>
                                  <label className='fw-bold fs-6'>
                                    Short Message:
                                    <span className='text-muted fw-normal ms-3'>
                                      short message sent via sms or push notification
                                    </span>
                                  </label>

                                  <Field
                                    as='textarea'
                                    className='form-control form-control-lg '
                                    name='pendingNotification'
                                  />
                                  <ErrorMessage name='pendingNotification'>
                                    {(msg) => <div className='text-danger'>{msg}</div>}
                                  </ErrorMessage>
                                </div>
                              </div>
                            </div>

                            <div className='fv-row mb-10'>
                              <div className='row'>
                                <div className='col'>
                                  <label className='fw-bold fs-6'>Email Subject</label>
                                  <Field
                                    name='pendingEmailSubject'
                                    className='form-control form-control-lg '
                                  />

                                  <ErrorMessage name='pendingEmail'>
                                    {(msg) => <div className='text-danger'>{msg}</div>}
                                  </ErrorMessage>
                                </div>
                              </div>
                            </div>

                            <div className='fv-row mb-10'>
                              <div className='row'>
                                <div className='col'>
                                  <label className='fw-bold fs-6'>Email Content</label>
                                  <TiptapEditor
                                    name='pendingEmail'
                                    suggestions={[
                                      ...placeholders.generic,
                                      ...placeholders.event,
                                      ...placeholders.booking,
                                    ]}
                                    showToolbar
                                  />

                                  <ErrorMessage name='pendingEmail'>
                                    {(msg) => <div className='text-danger'>{msg}</div>}
                                  </ErrorMessage>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* COMPLETED NOTIFICATION */}
                      <div className='notification-item mb-3'>
                        <div className='d-flex flex-column'>
                          <div className='d-flex align-items-start justify-content-between'>
                            <label className='form-check form-check-custom cursor-pointer text-dark form-check-solid align-items-start mb-5'>
                              <Field
                                className='form-check-input mt-2 me-3'
                                type='checkbox'
                                name='completedEnabled'
                                disabled={loading}
                                onClick={() => form.submitForm()}
                              />
                              <span className='form-check-label d-flex flex-column align-items-start'>
                                <span
                                  className={clsx('fw-bolder fs-5 mb-0', {
                                    'text-dark': form.values.completedEnabled === true,
                                    'text-gray-800': form.values.completedEnabled === false,
                                  })}
                                >
                                  Send 'Completed Bookings' Notifications
                                </span>
                                <span className='text-muted fw-normal fs-6'>
                                  Sent when a booking status is set to completed
                                </span>
                              </span>
                            </label>
                            {form.values.completedEnabled && (
                              <div
                                className='btn btn-light btn-sm fs-7'
                                onClick={() => setShowCompletedModal(true)}
                              >
                                <KTIcon iconName='pencil' iconType='outline' />
                                {!isMobileDevice() && 'customise'}
                              </div>
                            )}

                            <Modal
                              size='lg'
                              onHide={() => setShowCompletedModal(false)}
                              show={showCompletedModal}
                            >
                              <Modal.Header closeButton>
                                <Modal.Title>Customise Completed Notifications</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <div className='accordion' id='accordionEventNotifications'>
                                  <div className='accordion-item mb-3'>
                                    <h2 className='accordion-header' id='headingShortMessage'>
                                      <button
                                        className='accordion-button bg-light collapsed text-dark'
                                        type='button'
                                        data-bs-toggle='collapse'
                                        data-bs-target='#collapseShortMessage'
                                        aria-expanded='false'
                                        aria-controls='collapseShortMessage'
                                      >
                                        <div className='d-flex flex-column'>
                                          <span className='fw-bold fs-5 mb-2'>
                                            Customise Short Message
                                          </span>
                                          <span className='text-muted fw-normal'>
                                            Short message sent via sms or push notification
                                          </span>
                                        </div>
                                      </button>
                                    </h2>
                                    <div
                                      id='collapseShortMessage'
                                      className='accordion-collapse collapse'
                                      aria-labelledby='headingShortMessage'
                                      data-bs-parent='#accordionEventNotifications'
                                    >
                                      <div className='accordion-body'>
                                        <div className='row'>
                                          <div className='col'>
                                            <label className='fw-bold fs-6 mb-5'>
                                              Short Message:
                                              <span className='text-muted fw-normal ms-3'>
                                                short message sent via sms or push notification
                                              </span>
                                            </label>
                                            <TiptapEditor
                                              suggestions={[
                                                ...placeholders.generic,
                                                ...placeholders.event,
                                                ...placeholders.booking,
                                              ]}
                                              className='form-control form-control form-control-solid'
                                              name='completedNotification'
                                            />
                                            <ErrorMessage name='completedNotification'>
                                              {(msg) => <div className='text-danger'>{msg}</div>}
                                            </ErrorMessage>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className='accordion-item mb-3'>
                                    <h2 className='accordion-header' id='headingCompletedEmail'>
                                      <button
                                        className='accordion-button text-dark bg-light collapsed'
                                        type='button'
                                        data-bs-toggle='collapse'
                                        data-bs-target='#collapseCompletedEmail'
                                        aria-expanded='false'
                                        aria-controls='collapseCompletedEmail'
                                      >
                                        <div className='d-flex flex-column'>
                                          <span className='fw-bold fs-5 mb-2'>Customise Email</span>
                                          <span className='text-muted fw-normal'>
                                            Email sent when a booking status is set to completed
                                          </span>
                                        </div>
                                      </button>
                                    </h2>
                                    <div
                                      id='collapseCompletedEmail'
                                      className='accordion-collapse collapse'
                                      aria-labelledby='headingCompletedEmail'
                                      data-bs-parent='#accordionEventNotifications'
                                    >
                                      <div className='accordion-body'>
                                        <div className='row mb-10'>
                                          <div className='col'>
                                            <label className='fw-bold mb-5 fs-6'>
                                              Completed Email Subject
                                            </label>
                                            <TiptapEditor
                                              suggestions={[
                                                ...placeholders.generic,
                                                ...placeholders.event,
                                                ...placeholders.booking,
                                              ]}
                                              name='completedEmailSubject'
                                              className='form-control form-control form-control-solid'
                                            />
                                            <ErrorMessage name='completedEmailSubject'>
                                              {(msg) => <div className='text-danger'>{msg}</div>}
                                            </ErrorMessage>
                                          </div>
                                        </div>
                                        <div className='row'>
                                          <div className='col'>
                                            <label className='fw-bold mb-5 fs-6'>
                                              Email Content
                                            </label>
                                            <TiptapEditor
                                              name='completedEmail'
                                              className='form-control form-control-solid'
                                              suggestions={[
                                                ...placeholders.generic,
                                                ...placeholders.event,
                                                ...placeholders.booking,
                                              ]}
                                              showSuggestions={false}
                                              showToolbar
                                            />
                                            <ErrorMessage name='completedEmail'>
                                              {(msg) => <div className='text-danger'>{msg}</div>}
                                            </ErrorMessage>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <div className='d-flex flex-column align-items-end'>
                                  <div className='d-flex'>
                                    <button
                                      type='button'
                                      className='btn btn-secondary me-3'
                                      onClick={() => setShowCompletedModal(false)}
                                    >
                                      Close
                                    </button>
                                    <button
                                      type='button'
                                      className='btn btn-primary'
                                      onClick={() => {
                                        form.submitForm()
                                        setShowCompletedModal(false)
                                      }}
                                    >
                                      {loading ? (
                                        <>
                                          <span className='spinner-border spinner-border-sm'></span>
                                          Just a sec...
                                        </>
                                      ) : (
                                        'Save & Close'
                                      )}
                                    </button>
                                  </div>
                                  {(saveIsError === true || saveIsError === false) && (
                                    <div className='d-flex align-items-end fs-7 fw-bold me-0 py-3 px-6'>
                                      <span
                                        className={clsx('text', {
                                          'text-danger': saveIsError === true,
                                          'text-success': saveIsError === false,
                                        })}
                                      >
                                        {saveMessage}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </Modal.Footer>
                            </Modal>
                          </div>
                        </div>
                      </div>

                      {/* CANCELLED NOTIFICATION */}
                      <div className='notification-item mb-3'>
                        <div className='d-flex flex-column'>
                          <div className='d-flex align-items-start justify-content-between'>
                            <label className='form-check form-check-custom cursor-pointer text-dark form-check-solid align-items-start mb-5'>
                              <Field
                                className='form-check-input mt-2 me-3'
                                type='checkbox'
                                name='cancelledEnabled'
                                disabled={loading}
                                onClick={() => form.submitForm()}
                              />
                              <span className='form-check-label d-flex flex-column align-items-start'>
                                <span
                                  className={clsx('fw-bolder fs-5 mb-0', {
                                    'text-dark': form.values.cancelledEnabled === true,
                                    'text-gray-800': form.values.cancelledEnabled === false,
                                  })}
                                >
                                  Send 'Cancelled Bookings' Notifications
                                </span>
                                <span className='text-muted fw-normal fs-6'>
                                  Sent when a booking status is set to cancelled
                                </span>
                              </span>
                            </label>
                            {form.values.cancelledEnabled && (
                              <div
                                className='btn btn-light btn-sm fs-7'
                                onClick={() => setShowCancelledModal(true)}
                              >
                                <KTIcon iconName='pencil' iconType='outline' />
                                {!isMobileDevice() && 'customise'}
                              </div>
                            )}

                            <Modal
                              size='lg'
                              onHide={() => setShowCancelledModal(false)}
                              show={showCancelledModal}
                            >
                              <Modal.Header closeButton>
                                <Modal.Title>Customise Cancelled Notifications</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <div className='accordion' id='accordionEventNotifications'>
                                  <div className='accordion-item mb-3'>
                                    <h2 className='accordion-header' id='headingShortMessage'>
                                      <button
                                        className='accordion-button bg-light collapsed text-dark'
                                        type='button'
                                        data-bs-toggle='collapse'
                                        data-bs-target='#collapseShortMessage'
                                        aria-expanded='false'
                                        aria-controls='collapseShortMessage'
                                      >
                                        <div className='d-flex flex-column'>
                                          <span className='fw-bold fs-5 mb-2'>
                                            Customise Short Message
                                          </span>
                                          <span className='text-muted fw-normal'>
                                            Short message sent via sms or push notification
                                          </span>
                                        </div>
                                      </button>
                                    </h2>
                                    <div
                                      id='collapseShortMessage'
                                      className='accordion-collapse collapse'
                                      aria-labelledby='headingShortMessage'
                                      data-bs-parent='#accordionEventNotifications'
                                    >
                                      <div className='accordion-body'>
                                        <div className='row'>
                                          <div className='col'>
                                            <label className='fw-bold fs-6 mb-5'>
                                              Short Message:
                                              <span className='text-muted fw-normal ms-3'>
                                                short message sent via sms or push notification
                                              </span>
                                            </label>
                                            <TiptapEditor
                                              className='form-control form-control-solid'
                                              name='cancelledNotification'
                                              suggestions={[
                                                ...placeholders.generic,
                                                ...placeholders.event,
                                                ...placeholders.booking,
                                              ]}
                                            />
                                            <ErrorMessage name='cancelledNotification'>
                                              {(msg) => <div className='text-danger'>{msg}</div>}
                                            </ErrorMessage>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className='accordion-item mb-3'>
                                    <h2 className='accordion-header' id='headingCancelledEmail'>
                                      <button
                                        className='accordion-button text-dark bg-light collapsed'
                                        type='button'
                                        data-bs-toggle='collapse'
                                        data-bs-target='#collapseCancelledEmail'
                                        aria-expanded='false'
                                        aria-controls='collapseCancelledEmail'
                                      >
                                        <div className='d-flex flex-column'>
                                          <span className='fw-bold fs-5 mb-2'>Customise Email</span>
                                          <span className='text-muted fw-normal'>
                                            Email sent when a booking status is set to cancelled
                                          </span>
                                        </div>
                                      </button>
                                    </h2>
                                    <div
                                      id='collapseCancelledEmail'
                                      className='accordion-collapse collapse'
                                      aria-labelledby='headingCancelledEmail'
                                      data-bs-parent='#accordionEventNotifications'
                                    >
                                      <div className='accordion-body'>
                                        <div className='row mb-10'>
                                          <div className='col'>
                                            <label className='fw-bold mb-5 fs-6'>
                                              Cancelled Email Subject
                                            </label>
                                            <TiptapEditor
                                              name='cancelledEmailSubject'
                                              className='form-control form-control-solid'
                                              suggestions={[
                                                ...placeholders.generic,
                                                ...placeholders.event,
                                                ...placeholders.booking,
                                              ]}
                                            />
                                            <ErrorMessage name='cancelledEmailSubject'>
                                              {(msg) => <div className='text-danger'>{msg}</div>}
                                            </ErrorMessage>
                                          </div>
                                        </div>
                                        <div className='row'>
                                          <div className='col'>
                                            <label className='fw-bold mb-5 fs-6'>
                                              Email Content
                                            </label>
                                            <TiptapEditor
                                              className='form-control form-control-solid'
                                              name='cancelledEmail'
                                              suggestions={[
                                                ...placeholders.generic,
                                                ...placeholders.event,
                                                ...placeholders.booking,
                                              ]}
                                              showSuggestions={false}
                                              showToolbar
                                            />
                                            <ErrorMessage name='cancelledEmail'>
                                              {(msg) => <div className='text-danger'>{msg}</div>}
                                            </ErrorMessage>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <div className='d-flex flex-column align-items-end'>
                                  <div className='d-flex'>
                                    <button
                                      type='button'
                                      className='btn btn-secondary me-3'
                                      onClick={() => setShowCancelledModal(false)}
                                    >
                                      Close
                                    </button>
                                    <button
                                      type='button'
                                      className='btn btn-primary'
                                      onClick={() => {
                                        form.submitForm()
                                        setShowCancelledModal(false)
                                      }}
                                    >
                                      {loading ? (
                                        <>
                                          <span className='spinner-border spinner-border-sm'></span>
                                          Just a sec...
                                        </>
                                      ) : (
                                        'Save & Close'
                                      )}
                                    </button>
                                  </div>
                                  {(saveIsError === true || saveIsError === false) && (
                                    <div className='d-flex align-items-end fs-7 fw-bold me-0 py-3 px-6'>
                                      <span
                                        className={clsx('text', {
                                          'text-danger': saveIsError === true,
                                          'text-success': saveIsError === false,
                                        })}
                                      >
                                        {saveMessage}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </Modal.Footer>
                            </Modal>
                          </div>
                        </div>
                      </div>

                      {/* ABANDONED NOTIFICATION */}
                      {currentEvent.sellTickets && (
                        <div className='notification-item mb-3'>
                          <div className='d-flex flex-column'>
                            <div className='d-flex align-items-start justify-content-between'>
                              <label className='form-check form-check-custom cursor-pointer text-dark form-check-solid align-items-start mb-5'>
                                <Field
                                  className='form-check-input mt-2 me-3'
                                  type='checkbox'
                                  name='abandonedEnabled'
                                  disabled={loading}
                                  onClick={() => form.submitForm()}
                                />
                                <span className='form-check-label d-flex flex-column align-items-start'>
                                  <span
                                    className={clsx('fw-bolder fs-5 mb-0', {
                                      'text-dark': form.values.abandonedEnabled === true,
                                      'text-gray-800': form.values.abandonedEnabled === false,
                                    })}
                                  >
                                    Send 'Abandoned Bookings' Notifications
                                  </span>
                                  <span className='text-muted fw-normal fs-6'>
                                    Sent when a booking status is set to abandoned
                                  </span>
                                </span>
                              </label>
                              {form.values.abandonedEnabled && (
                                <div
                                  className='btn btn-light btn-sm fs-7'
                                  onClick={() => setShowAbandonedModal(true)}
                                >
                                  <KTIcon iconName='pencil' iconType='outline' />
                                  {!isMobileDevice() && 'customise'}
                                </div>
                              )}

                              <Modal
                                size='lg'
                                onHide={() => setShowAbandonedModal(false)}
                                show={showAbandonedModal}
                              >
                                <Modal.Header closeButton>
                                  <Modal.Title>Customise Abandoned Notifications</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  <div className='accordion' id='accordionEventNotifications'>
                                    <div className='accordion-item mb-3'>
                                      <h2 className='accordion-header' id='headingShortMessage'>
                                        <button
                                          className='accordion-button bg-light collapsed text-dark'
                                          type='button'
                                          data-bs-toggle='collapse'
                                          data-bs-target='#collapseAbandonedShortMessage'
                                          aria-expanded='false'
                                          aria-controls='collapseAbandonedShortMessage'
                                        >
                                          <div className='d-flex flex-column'>
                                            <span className='fw-bold fs-5 mb-2'>
                                              Customise Short Message
                                            </span>
                                            <span className='text-muted fw-normal'>
                                              Short message sent via sms or push notification
                                            </span>
                                          </div>
                                        </button>
                                      </h2>
                                      <div
                                        id='collapseAbandonedShortMessage'
                                        className='accordion-collapse collapse'
                                        aria-labelledby='headingShortMessage'
                                        data-bs-parent='#accordionEventNotifications'
                                      >
                                        <div className='accordion-body'>
                                          <div className='row'>
                                            <div className='col'>
                                              <label className='fw-bold fs-6 mb-5'>
                                                Short Message:
                                                <span className='text-muted fw-normal ms-3'>
                                                  short message sent via sms or push notification
                                                </span>
                                              </label>
                                              <TiptapEditor
                                                className='form-control form-control-solid'
                                                name='abandonedNotification'
                                                suggestions={[
                                                  ...placeholders.generic,
                                                  ...placeholders.event,
                                                  ...placeholders.booking,
                                                ]}
                                              />
                                              <ErrorMessage name='abandonedNotification'>
                                                {(msg) => <div className='text-danger'>{msg}</div>}
                                              </ErrorMessage>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className='accordion-item mb-3'>
                                      <h2 className='accordion-header' id='headingAbandonedEmail'>
                                        <button
                                          className='accordion-button text-dark bg-light collapsed'
                                          type='button'
                                          data-bs-toggle='collapse'
                                          data-bs-target='#collapseAbandonedEmail'
                                          aria-expanded='false'
                                          aria-controls='collapseAbandonedEmail'
                                        >
                                          <div className='d-flex flex-column'>
                                            <span className='fw-bold fs-5 mb-2'>
                                              Customise Email
                                            </span>
                                            <span className='text-muted fw-normal'>
                                              Email sent when a booking status is set to abandoned
                                            </span>
                                          </div>
                                        </button>
                                      </h2>
                                      <div
                                        id='collapseAbandonedEmail'
                                        className='accordion-collapse collapse'
                                        aria-labelledby='headingAbandonedEmail'
                                        data-bs-parent='#accordionEventNotifications'
                                      >
                                        <div className='accordion-body'>
                                          <div className='row mb-10'>
                                            <div className='col'>
                                              <label className='fw-bold mb-5 fs-6'>
                                                Abandoned Email Subject
                                              </label>
                                              <TiptapEditor
                                                suggestions={[
                                                  ...placeholders.generic,
                                                  ...placeholders.event,
                                                  ...placeholders.booking,
                                                ]}
                                                name='abandonedEmailSubject'
                                                className='form-control form-control-solid'
                                              />
                                              <ErrorMessage name='abandonedEmailSubject'>
                                                {(msg) => <div className='text-danger'>{msg}</div>}
                                              </ErrorMessage>
                                            </div>
                                          </div>
                                          <div className='row'>
                                            <div className='col'>
                                              <label className='fw-bold mb-5 fs-6'>
                                                Email Content
                                              </label>
                                              <TiptapEditor
                                                name='abandonedEmail'
                                                className='form-control form-control-solid'
                                                suggestions={[
                                                  ...placeholders.generic,
                                                  ...placeholders.event,
                                                  ...placeholders.booking,
                                                ]}
                                                showSuggestions={false}
                                                showToolbar
                                              />
                                              <ErrorMessage name='abandonedEmail'>
                                                {(msg) => <div className='text-danger'>{msg}</div>}
                                              </ErrorMessage>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Modal.Body>
                                <Modal.Footer>
                                  <div className='d-flex flex-column align-items-end'>
                                    <div className='d-flex'>
                                      <button
                                        type='button'
                                        className='btn btn-secondary me-3'
                                        onClick={() => setShowAbandonedModal(false)}
                                      >
                                        Close
                                      </button>
                                      <button
                                        type='button'
                                        className='btn btn-primary'
                                        onClick={() => {
                                          form.submitForm()
                                          setShowAbandonedModal(false)
                                        }}
                                      >
                                        {loading ? (
                                          <>
                                            <span className='spinner-border spinner-border-sm'></span>
                                            Just a sec...
                                          </>
                                        ) : (
                                          'Save & Close'
                                        )}
                                      </button>
                                    </div>
                                    {(saveIsError === true || saveIsError === false) && (
                                      <div className='d-flex align-items-end fs-7 fw-bold me-0 py-3 px-6'>
                                        <span
                                          className={clsx('text', {
                                            'text-danger': saveIsError === true,
                                            'text-success': saveIsError === false,
                                          })}
                                        >
                                          {saveMessage}
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                </Modal.Footer>
                              </Modal>
                            </div>
                          </div>
                        </div>
                      )}

                      {/* REMINDER NOTIFICATION */}
                      <div className='notification-item mb-3'>
                        <div className='d-flex flex-column'>
                          <div className='d-flex align-items-start justify-content-between'>
                            <label className='form-check form-check-custom cursor-pointer text-dark form-check-solid align-items-start mb-5'>
                              <Field
                                className='form-check-input mt-2 me-3'
                                type='checkbox'
                                name='beforeEnabled'
                                disabled={loading}
                                onClick={() => form.submitForm()}
                              />
                              <span className='form-check-label d-flex flex-column align-items-start'>
                                <span
                                  className={clsx('fw-bolder fs-5 mb-0', {
                                    'text-dark': form.values.beforeEnabled === true,
                                    'text-gray-800': form.values.beforeEnabled === false,
                                  })}
                                >
                                  Send 'Reminder' Notifications
                                </span>
                                <span className='text-muted fw-normal fs-6'>
                                  Sent {currentEvent.beforeDays.toString()}{' '}
                                  {pluralise(currentEvent.beforeDays, 'day')} before the event
                                </span>
                              </span>
                            </label>
                            {form.values.beforeEnabled && (
                              <div
                                className='btn btn-light btn-sm fs-7'
                                onClick={() => setShowReminderModal(true)}
                              >
                                <KTIcon iconName='pencil' iconType='outline' />
                                {!isMobileDevice() && 'customise'}
                              </div>
                            )}

                            <Modal
                              size='lg'
                              onHide={() => setShowReminderModal(false)}
                              show={showReminderModal}
                            >
                              <Modal.Header closeButton>
                                <Modal.Title>Customise Reminder Notifications</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                {/* NumberInput above the accordion */}
                                <div className='mb-10'>
                                  <div className='row'>
                                    <div className='col col-md-8 px-5'>
                                      <label className='fw-bold fs-6 me-3'>
                                        Reminder Delay (days)
                                      </label>
                                      <div className='form-text text-muted'>
                                        set the number of days before the event to send the reminder
                                      </div>
                                    </div>
                                    <div className='col col-md-4'>
                                      <NumberInput
                                        name='beforeDays'
                                        min={1}
                                        max={7}
                                        disabled={!form.values.beforeEnabled}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className='accordion' id='accordionEventNotifications'>
                                  <div className='accordion-item mb-3'>
                                    <h2
                                      className='accordion-header'
                                      id='headingReminderShortMessage'
                                    >
                                      <button
                                        className='accordion-button bg-light collapsed text-dark'
                                        type='button'
                                        data-bs-toggle='collapse'
                                        data-bs-target='#collapseReminderShortMessage'
                                        aria-expanded='false'
                                        aria-controls='collapseReminderShortMessage'
                                      >
                                        <div className='d-flex flex-column'>
                                          <span className='fw-bold fs-5 mb-2'>
                                            Customise Short Message
                                          </span>
                                          <span className='text-muted fw-normal'>
                                            Short message sent via sms or push notification
                                          </span>
                                        </div>
                                      </button>
                                    </h2>
                                    <div
                                      id='collapseReminderShortMessage'
                                      className='accordion-collapse collapse'
                                      aria-labelledby='headingReminderShortMessage'
                                      data-bs-parent='#accordionEventNotifications'
                                    >
                                      <div className='accordion-body'>
                                        <div className='row'>
                                          <div className='col'>
                                            <label className='fw-bold fs-6 mb-5'>
                                              Short Message:
                                              <span className='text-muted fw-normal ms-3'>
                                                short message sent via sms or push notification
                                              </span>
                                            </label>
                                            <TiptapEditor
                                              className='form-control form-control-solid'
                                              name='beforeNotification'
                                              suggestions={[
                                                ...placeholders.generic,
                                                ...placeholders.event,
                                                ...placeholders.booking,
                                              ]}
                                            />
                                            <ErrorMessage name='beforeNotification'>
                                              {(msg) => <div className='text-danger'>{msg}</div>}
                                            </ErrorMessage>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className='accordion-item mb-3'>
                                    <h2 className='accordion-header' id='headingReminderEmail'>
                                      <button
                                        className='accordion-button text-dark bg-light collapsed'
                                        type='button'
                                        data-bs-toggle='collapse'
                                        data-bs-target='#collapseReminderEmail'
                                        aria-expanded='false'
                                        aria-controls='collapseReminderEmail'
                                      >
                                        <div className='d-flex flex-column'>
                                          <span className='fw-bold fs-5 mb-2'>Customise Email</span>
                                          <span className='text-muted fw-normal'>
                                            Email sent when a reminder is triggered
                                          </span>
                                        </div>
                                      </button>
                                    </h2>
                                    <div
                                      id='collapseReminderEmail'
                                      className='accordion-collapse collapse'
                                      aria-labelledby='headingReminderEmail'
                                      data-bs-parent='#accordionEventNotifications'
                                    >
                                      <div className='accordion-body'>
                                        <div className='row mb-10'>
                                          <div className='col'>
                                            <label className='fw-bold mb-5 fs-6'>
                                              Reminder Email Subject
                                            </label>
                                            <TiptapEditor
                                              name='beforeEmailSubject'
                                              className='form-control form-control-solid'
                                              suggestions={[
                                                ...placeholders.generic,
                                                ...placeholders.event,
                                                ...placeholders.booking,
                                              ]}
                                            />
                                            <ErrorMessage name='beforeEmailSubject'>
                                              {(msg) => <div className='text-danger'>{msg}</div>}
                                            </ErrorMessage>
                                          </div>
                                        </div>
                                        <div className='row'>
                                          <div className='col'>
                                            <label className='fw-bold mb-5 fs-6'>
                                              Email Content
                                            </label>
                                            <TiptapEditor
                                              className='form-control form-control-solid'
                                              name='beforeEmail'
                                              suggestions={[
                                                ...placeholders.generic,
                                                ...placeholders.event,
                                                ...placeholders.booking,
                                              ]}
                                              showSuggestions={false}
                                              showToolbar
                                            />
                                            <ErrorMessage name='beforeEmail'>
                                              {(msg) => <div className='text-danger'>{msg}</div>}
                                            </ErrorMessage>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <div className='d-flex flex-column align-items-end'>
                                  <div className='d-flex'>
                                    <button
                                      type='button'
                                      className='btn btn-secondary me-3'
                                      onClick={() => setShowReminderModal(false)}
                                    >
                                      Close
                                    </button>
                                    <button
                                      type='button'
                                      className='btn btn-primary'
                                      onClick={() => {
                                        form.submitForm()
                                        setShowReminderModal(false)
                                      }}
                                    >
                                      {loading ? (
                                        <>
                                          <span className='spinner-border spinner-border-sm'></span>
                                          Just a sec...
                                        </>
                                      ) : (
                                        'Save & Close'
                                      )}
                                    </button>
                                  </div>
                                  {(saveIsError === true || saveIsError === false) && (
                                    <div className='d-flex align-items-end fs-7 fw-bold me-0 py-3 px-6'>
                                      <span
                                        className={clsx('text', {
                                          'text-danger': saveIsError === true,
                                          'text-success': saveIsError === false,
                                        })}
                                      >
                                        {saveMessage}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </Modal.Footer>
                            </Modal>
                          </div>
                        </div>
                      </div>

                      {/* THANK YOU NOTIFICATION */}
                      <div className='notification-item mb-3'>
                        <div className='d-flex flex-column'>
                          <div className='d-flex align-items-start justify-content-between'>
                            <label className='form-check form-check-custom cursor-pointer text-dark form-check-solid align-items-start mb-5'>
                              <Field
                                className='form-check-input mt-2 me-3'
                                type='checkbox'
                                name='afterEnabled'
                                disabled={loading}
                                onClick={() => form.submitForm()}
                              />
                              <span className='form-check-label d-flex flex-column align-items-start'>
                                <span
                                  className={clsx('fw-bolder fs-5 mb-0', {
                                    'text-dark': form.values.afterEnabled,
                                    'text-gray-800': !form.values.afterEnabled,
                                  })}
                                >
                                  Send 'Thank You' Notifications
                                </span>
                                <span className='text-muted fw-normal fs-6'>
                                  Sent {currentEvent.afterDays}{' '}
                                  {pluralise(currentEvent.afterDays, 'day')} after the event
                                </span>
                              </span>
                            </label>
                            {form.values.afterEnabled && (
                              <div
                                className='btn btn-light btn-sm fs-7'
                                onClick={() => setShowThankYouModal(true)}
                              >
                                <KTIcon iconName='pencil' iconType='outline' />
                                {!isMobileDevice() && 'customise'}
                              </div>
                            )}

                            <Modal
                              size='lg'
                              onHide={() => setShowThankYouModal(false)}
                              show={showThankYouModal}
                            >
                              <Modal.Header closeButton>
                                <Modal.Title>Customise Thank You Notifications</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <div className='accordion' id='accordionEventNotifications'>
                                  <div className='mb-10'>
                                    <div className='row'>
                                      <div className='col col-md-8 px-5'>
                                        <label className='fw-bold fs-6 me-3'>
                                          Reminder Delay (days)
                                        </label>
                                        <div className='form-text text-muted'>
                                          set the number of days after the event to send the
                                          reminder
                                        </div>
                                      </div>
                                      <div className='col col-md-4'>
                                        <NumberInput
                                          name='afterDays'
                                          min={1}
                                          max={7}
                                          disabled={!form.values.afterEnabled}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className='accordion-item mb-3'>
                                    <h2
                                      className='accordion-header'
                                      id='headingThankYouShortMessage'
                                    >
                                      <button
                                        className='accordion-button bg-light collapsed text-dark'
                                        type='button'
                                        data-bs-toggle='collapse'
                                        data-bs-target='#collapseThankYouShortMessage'
                                        aria-expanded='false'
                                        aria-controls='collapseThankYouShortMessage'
                                      >
                                        <div className='d-flex flex-column'>
                                          <span className='fw-bold fs-5 mb-2'>
                                            Customise Short Message
                                          </span>
                                          <span className='text-muted fw-normal'>
                                            Short message sent via sms or push notification
                                          </span>
                                        </div>
                                      </button>
                                    </h2>
                                    <div
                                      id='collapseThankYouShortMessage'
                                      className='accordion-collapse collapse'
                                      aria-labelledby='headingThankYouShortMessage'
                                      data-bs-parent='#accordionEventNotifications'
                                    >
                                      <div className='accordion-body'>
                                        <div className='row'>
                                          <div className='col'>
                                            <label className='fw-bold fs-6 mb-5'>
                                              Short Message:
                                              <span className='text-muted fw-normal ms-3'>
                                                short message sent via sms or push notification
                                              </span>
                                            </label>
                                            <TiptapEditor
                                              className='form-control form-control-solid'
                                              name='afterNotification'
                                              suggestions={[
                                                ...placeholders.generic,
                                                ...placeholders.event,
                                                ...placeholders.booking,
                                              ]}
                                            />
                                            <ErrorMessage name='afterNotification'>
                                              {(msg) => <div className='text-danger'>{msg}</div>}
                                            </ErrorMessage>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className='accordion-item mb-3'>
                                    <h2 className='accordion-header' id='headingThankYouEmail'>
                                      <button
                                        className='accordion-button text-dark bg-light collapsed'
                                        type='button'
                                        data-bs-toggle='collapse'
                                        data-bs-target='#collapseThankYouEmail'
                                        aria-expanded='false'
                                        aria-controls='collapseThankYouEmail'
                                      >
                                        <div className='d-flex flex-column'>
                                          <span className='fw-bold fs-5 mb-2'>Customise Email</span>
                                          <span className='text-muted fw-normal'>
                                            Email sent after a booking is completed
                                          </span>
                                        </div>
                                      </button>
                                    </h2>
                                    <div
                                      id='collapseThankYouEmail'
                                      className='accordion-collapse collapse'
                                      aria-labelledby='headingThankYouEmail'
                                      data-bs-parent='#accordionEventNotifications'
                                    >
                                      <div className='accordion-body'>
                                        <div className='row mb-10'>
                                          <div className='col'>
                                            <label className='fw-bold mb-5 fs-6'>
                                              Thank You Email Subject
                                            </label>
                                            <TiptapEditor
                                              name='afterEmailSubject'
                                              className='form-control form-control-solid'
                                              suggestions={[
                                                ...placeholders.generic,
                                                ...placeholders.event,
                                                ...placeholders.booking,
                                              ]}
                                            />
                                            <ErrorMessage name='afterEmailSubject'>
                                              {(msg) => <div className='text-danger'>{msg}</div>}
                                            </ErrorMessage>
                                          </div>
                                        </div>
                                        <div className='row'>
                                          <div className='col'>
                                            <label className='fw-bold mb-5 fs-6'>
                                              Email Content
                                            </label>
                                            <TiptapEditor
                                              className='form-control form-control-solid'
                                              name='afterEmail'
                                              suggestions={[
                                                ...placeholders.generic,
                                                ...placeholders.event,
                                                ...placeholders.booking,
                                              ]}
                                              showSuggestions={false}
                                              showToolbar
                                            />
                                            <ErrorMessage name='afterEmail'>
                                              {(msg) => <div className='text-danger'>{msg}</div>}
                                            </ErrorMessage>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <div className='d-flex flex-column align-items-end'>
                                  <div className='d-flex'>
                                    <button
                                      type='button'
                                      className='btn btn-secondary me-3'
                                      onClick={() => setShowThankYouModal(false)}
                                    >
                                      Close
                                    </button>
                                    <button
                                      type='button'
                                      className='btn btn-primary'
                                      onClick={() => {
                                        form.submitForm()
                                        setShowThankYouModal(false)
                                      }}
                                    >
                                      {loading ? (
                                        <>
                                          <span className='spinner-border spinner-border-sm'></span>
                                          Just a sec...
                                        </>
                                      ) : (
                                        'Save & Close'
                                      )}
                                    </button>
                                  </div>
                                  {(saveIsError === true || saveIsError === false) && (
                                    <div className='d-flex align-items-end fs-7 fw-bold me-0 py-3 px-6'>
                                      <span
                                        className={clsx('text', {
                                          'text-danger': saveIsError === true,
                                          'text-success': saveIsError === false,
                                        })}
                                      >
                                        {saveMessage}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </Modal.Footer>
                            </Modal>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

export {EventNotifications}
