const PageLoadingSpinner = () => {
  // get kt_theme_mode_value from local storage
  let themeMode = localStorage.getItem('kt_theme_mode_value')

  const styles = {
    borderRadius: '0.475rem',
    // boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
    backgroundColor: '#fff',
    color: '#7e8299',
    fontWeight: '500',
    margin: '0',
    width: 'auto',
    padding: '1rem 2rem',
    top: 'calc(50% - 2rem)',
    left: 'calc(50% - 4rem)',
    zIndex: 9999,
  }

  const darkModeStyles = {
    ...styles,
    backgroundColor: '#1c1e35',
    color: '#fff',
  }

  // enforce dark mode if system preference is dark & theme mode is set to use system preferences
  if (themeMode === 'system' && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    themeMode = 'dark'
  }

  if (themeMode === 'dark') {
    return (
      <div style={{...darkModeStyles, position: 'absolute', textAlign: 'center'}}>
        {/* spinner */}
        <div className='spinner-border spinner-border-sm text-primary me-2' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </div>
        Just a sec...
      </div>
    )
  }

  return (
    <div className='glass-pane' style={{...styles, position: 'absolute', textAlign: 'center'}}>
      {/* spinner */}
      <div className='spinner-border spinner-border-sm text-primary me-2' role='status'>
        <span className='visually-hidden'>Loading...</span>
      </div>
      Just a sec...
    </div>
  )
}

export {PageLoadingSpinner}
