import {create, StateCreator} from 'zustand'
import {devtools} from 'zustand/middleware'
import {_getBlogPosts} from './appSettingsApi'

export type AppSettingsModel = {
  baseUrl: string
  apiUrl: string
  uploadsUrl: string
  fee: number
  gatewayFeePercentage: number
  gatewayFee: number
  version: string
  paystackPublicKey: string
  name: string
  vat: number
  sbPhone: string
  sbEmail: string
  gaMeasurementId: string
  gaDebugMode: boolean
  messageFee: number
}

export type BreadcrumbModel = {
  object: string
  title: string
  link: string
}

// WORDPRESS BLOG POST
export type BlogPostModel = {
  title: string
  content: string
  excerpt: string
  link: string
  date: string
  image: string
}

export const initialAppSettings: AppSettingsModel = {
  baseUrl: process.env.REACT_APP_PUBLIC_URL || 'http://localhost:4000',
  apiUrl: process.env.REACT_APP_API_URL || 'http://localhost:3000/v1',
  uploadsUrl: process.env.REACT_APP_API_URL + '/uploads' || 'http://localhost:3000/v1/uploads',
  fee: Number(process.env.REACT_APP_SB_COMMISSION_PERCENTAGE) || 5,
  gatewayFeePercentage: Number(process.env.REACT_APP_PAYSTACK_FEES_PERCENTAGE) || 2.9,
  gatewayFee: Number(process.env.REACT_APP_PAYSTACK_FEES) || 1,
  version: process.env.REACT_APP_VERSION || '2.0.0',
  paystackPublicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY || 'pk_test_1234567890',
  name: process.env.REACT_APP_THEME_NAME || 'skybookings',
  vat: Number(process.env.REACT_APP_VAT_PERCENTAGE) || 15,
  sbPhone: process.env.REACT_APP_SB_PHONE || '',
  sbEmail: process.env.REACT_APP_SB_EMAIL || '',
  gaMeasurementId: process.env.REACT_APP_GA_MEASUREMENT_ID || '',
  gaDebugMode: process.env.REACT_APP_GA_DEBUG_MODE === 'true',
  messageFee: Number(process.env.REACT_APP_MESSAGE_FEE) || 0,
}

type AppSettingsStore = {
  appSettings: AppSettingsModel
  currentPage: string
  breadcrumbs: BreadcrumbModel[]
  setSettings: (appSettings: AppSettingsModel) => void
  getSettings: () => AppSettingsModel
  setCurrentPage: (currentPage: string) => void
  setBreadcrumbs: (breadCrumbs: BreadcrumbModel[]) => void
  getBlogPosts: () => Promise<BlogPostModel[]>
}

const createStore: StateCreator<AppSettingsStore> = (set, get) => ({
  appSettings: initialAppSettings,
  currentPage: '',
  breadcrumbs: [],
  setSettings: (appSettings: AppSettingsModel) => set({appSettings}),
  getSettings: () => get().appSettings,
  setCurrentPage: (currentPage: string) => set({currentPage}),
  setBreadcrumbs: (breadcrumbs: BreadcrumbModel[]) => set({breadcrumbs}),
  getBlogPosts: async () => {
    // get blog posts
    const results = await _getBlogPosts(get().appSettings.baseUrl)
    const posts: BlogPostModel[] = results.data.map((post: any) => {
      return {
        title: post.title.rendered,
        content: post.content.rendered,
        excerpt: post.excerpt.rendered,
        link: post.link,
        date: post.date,
        image: post.jetpack_featured_media_url,
      }
    })

    return posts
  },
})

export const appSettingsStore = create(devtools(createStore))
export const useAppSettingsStore = appSettingsStore
