import {FC, useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {eventsStore} from '../events/core/eventsStore'
import {daysUntilDate} from '../../../_helpers/_helpers'
import {BookingTicketModel, useBookingStore} from './core/bookingsStore'
import {PageLoadingSpinner} from '../../../_components/PageLoadingSpinner'
import {BookingTicketItem} from './SingleEventPartials/BookingTicketItem'

const PublicBookingTicketsPage: FC = () => {
  const {bookingId, eventId} = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const {publicEvent, getPublicEvent} = eventsStore()
  const {getManyBookingTickets} = useBookingStore()
  const [bookingTickets, setBookingTickets] = useState<BookingTicketModel[]>([])

  useEffect(() => {
    if (eventId && bookingId) {
      setIsLoading(true)
      Promise.all([getPublicEvent(eventId), getManyBookingTickets(bookingId, {})])
        .then(([event, bts]) => {
          setBookingTickets(bts)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }

    // eslint-disable-next-line
  }, [eventId])

  return (
    <>
      {isLoading ? (
        <PageLoadingSpinner />
      ) : (
        <>
          <div className='d-flex mw-600px w-100 mt-10 px-10 container align-items-center'>
            <div className='d-flex py-3 align-items-sm-center'>
              {/* begin::Symbol */}
              <div className='me-5'>
                <div
                  style={{
                    backgroundImage: `url(${publicEvent.artwork})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundColor: 'black',
                  }}
                  className='w-50px h-50px symbol symbol-50px'
                />
              </div>
              {/* end::Symbol */}
              {/* begin::Section */}

              <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
                <div className='flex-grow-1 me-2'>
                  <Link
                    to={`/event/${publicEvent.id}`}
                    className='d-flex flex-grow-1 flex-row-fluid flex-wrap'
                  >
                    <span className='text-dark fw-bolder d-block fs-3'>{publicEvent.name}</span>
                  </Link>
                  <span className='text-muted fw-semibold d-block fs-7'>
                    Starts in {daysUntilDate(new Date(publicEvent?.startDate), true)}
                  </span>
                </div>
              </div>

              {/* end::Section */}
            </div>
          </div>
          <div className='d-flex flex-wrap mw-600px w-100 px-10 container mb-10'>
            {bookingTickets.map((ticket: any) => (
              <BookingTicketItem
                key={ticket.id}
                event={publicEvent}
                bookingTicket={ticket}
                className='w-100 mt-5'
              />
            ))}
          </div>
        </>
      )}
    </>
  )
}

export {PublicBookingTicketsPage}
