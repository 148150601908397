import {FieldArray, Form, Formik} from 'formik'
import React, {FC, useEffect, useState} from 'react'
import {
  daysUntilDate,
  formatMoney,
  formatNumber,
  getCurrencySymbol,
  loadScript,
} from '../../../_helpers/_helpers'
import {eventsStore} from '../events/core/eventsStore'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {BookingTicketItem} from './PublicBookingPartials/BookingTicketItem'
import {usePatronStore} from '../patrons/core/patronStore'
import clsx from 'clsx'
import {Modal} from 'react-bootstrap'
import toast, {Toaster} from 'react-hot-toast'
import {BookingModel, BookingTicketModel, bookingStore} from './core/bookingsStore'
import {PatronAuth} from '../patrons/PatronAuth'
import {useAuthStore} from '../auth/core/authStore'
import {KTIcon} from '../../../_metronic/helpers'
import {initialTransaction, useTransactionStore} from '../transactions/core/transactionsStore'
import {useAppSettingsStore} from '../settings/core/appSettingsStore'
import Swal from 'sweetalert2'
import {
  GA_EVENT_BOOKING_IDENTIFY_PATRON,
  GA_EVENT_BOOKING_SELECT_TICKET,
  GA_EVENT_BOOKING_BOOKING_CONFIRMED,
  GaEventType,
  initializeGA,
  trackEvent,
  trackPageView,
  GA_EVENT_BOOKING_VIEW_PAGE,
} from '../../../_helpers/_analytics'

const PublicBookingPage: FC = () => {
  const {currentBooking, getBooking, syncTickets, saveBooking, resetCurrentBooking} = bookingStore()
  const {isPatronLoggedIn, loadPatronFromToken, currentPatron} = usePatronStore()
  const {postTransaction} = useTransactionStore()
  const {publicEvent, getPublicEvent} = eventsStore()
  const {eventId} = useParams<{eventId: string}>()
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const navigate = useNavigate()
  const {appSettings} = useAppSettingsStore()
  const {isLoggedIn, logout} = useAuthStore()
  const [currency, setCurrency] = useState<string>('ZAR')
  const [showSupportModal, setSupportModal] = useState<boolean>(false)
  const [isTicketSelected, setIsTicketSelected] = useState<boolean>(false)

  // SETUP PAY STACK WHEN PATRON CHANGES
  useEffect(() => {
    if (currentPatron.id) {
      // SET CURRENCY
      setCurrency(publicEvent.currency ?? 'ZAR')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPatron])

  // google analytics
  useEffect(() => {
    initializeGA()
    trackPageView(window.location.pathname)
  }, [publicEvent.name])

  //load event and tickes
  useEffect(() => {
    resetCurrentBooking()
    setIsLoading(false)

    if (eventId && publicEvent.id !== eventId) {
      // get event id
      getPublicEvent(eventId).then((res) => {
        syncTickets(res.tickets)
      })
    } else {
      syncTickets(publicEvent.tickets)

      // GA: track booking viewed
      trackEvent(GA_EVENT_BOOKING_VIEW_PAGE, {
        eventId: publicEvent.id ? publicEvent.id : '',
        accountId: publicEvent.account,
      })
    }

    // load patron
    if (localStorage.getItem('patronToken') && !currentPatron.id) {
      loadPatronFromToken()
    }

    //eslint-disable-next-line
  }, [eventId, publicEvent.id])

  // GA: check if patron is logged in
  useEffect(() => {
    if (currentPatron.id && publicEvent.id) {
      trackEvent(GA_EVENT_BOOKING_IDENTIFY_PATRON, {
        eventId: publicEvent.id,
        accountId: publicEvent.account,
        patronId: currentPatron.id,
      })
    }

    // eslint-disable-next-line
  }, [currentPatron.id])

  // GA: track whether tickets are selected
  useEffect(() => {
    if (isTicketSelected && publicEvent.id) {
      const arg: GaEventType = {
        eventId: publicEvent.id,
        accountId: publicEvent.account,
      }

      // add patron id if available
      if (currentPatron.id) arg.patronId = currentPatron.id

      // track event
      trackEvent(GA_EVENT_BOOKING_SELECT_TICKET, arg)
    }

    // eslint-disable-next-line
  }, [isTicketSelected])

  const handleSubmit = async (values: BookingModel, action) => {
    setIsLoading(true)
    try {
      // 1. CREATE BOOKING
      const payload: BookingModel = {
        ...values,
        event: {id: publicEvent.id || ''},
        patron: {id: currentPatron.id || ''},
        value: values.bookingTickets.reduce(
          (acc, bookingTicket) =>
            bookingTicket.ticket.enableVariablePrice === true
              ? acc + bookingTicket.value
              : acc + bookingTicket.qty * bookingTicket.ticket.price,
          0
        ),
      }
      const booking = await saveBooking(payload)

      // ga event
      trackEvent(GA_EVENT_BOOKING_BOOKING_CONFIRMED, {
        eventId: booking.event.id ?? '',
        accountId: publicEvent.account,
        patronId: currentPatron.id ?? '',
      })

      // 2. CREATE TRANSACTION IF SELLING TICKETS & LAUNCH PAYMENT
      if (publicEvent.sellTickets && payload.value > 0) {
        const transactionPayload: any = {
          ...initialTransaction,
          patron: booking.patron.id,
          booking: booking.id,
          event: booking.event.id,
          status: 'pending',
          type: 'payment',
          amount: booking.value,
          description: 'Booking Payment: ' + booking.id,
          gateway: 'paystack',
          gatewayTransactionId: booking.id,
          reconciled: false,
          account: booking.event.account,
        }

        const transaction = await postTransaction(transactionPayload)

        // launch paystack payment modal
        const paymentProps = {
          key: appSettings.paystackPublicKey,
          email: currentPatron.email
            ? currentPatron.email
            : `${currentPatron.phone}@skybookings.com`,
          currency: currency,
          text: 'Pay Now',
          ref: transaction.id,
          amount: transaction.amount * 100,
          callback: () => handlePaymentDone(transaction),
          onClose: handlePaymentClosed,
          metadata: {},
        }

        // get ga from cookie (if it is set)
        const ga = document.cookie
          .split(';')
          .find((c) => c.trim().startsWith('_ga'))
          ?.split('=')[1]
          .split('.')
          .slice(2)
          .join('.')

        if (ga) paymentProps.metadata = {ga}

        // load paystack popup
        await loadScript('https://js.paystack.co/v1/inline.js')
        const handler = window.PaystackPop.setup(paymentProps)
        handler.openIframe()
      } else {
        // 3. IF NOT SELLING TICKETS, JUST CONFIRM BOOKING
        setIsLoading(false)
        if (booking.id) navigate(`/bookings/${booking.id}/tickets`)
      }
    } catch (err: any) {
      setIsLoading(false)
      toast.error(err.data.response.message)
      return
    }
  }

  const handlePaymentDone = async (response) => {
    setIsLoading(false)

    // show swwal
    Swal.fire({
      title: 'Payment Successful',
      text: 'Please wait a moment while we verify your payment.  You will be directed to the confirmation page in 5 seconds.',
      icon: 'success',
      showCancelButton: false,
      showConfirmButton: false,
      backdrop: true,
      allowOutsideClick: false,
      customClass: {
        container: 'white-backdrop',
      },
    })

    // loop every 2 seconds to check if booking status is complete
    const checkBookingStatus = async () => {
      const booking = await getBooking(response.booking)
      if (booking.status === 'complete') {
        clearInterval(interval)
        setIsLoading(false)
        Swal.close()

        // navigate to tickets page
        navigate(`/bookings/${response.booking}/tickets`)
      }
    }

    // check booking status every 2 seconds
    const interval = setInterval(checkBookingStatus, 1000)
  }
  const handlePaymentClosed = () => {
    setIsLoading(false)
  }

  return (
    <>
      <Toaster position='top-center' />

      {/* HEADER */}
      <div className='d-flex mw-600px w-100 mt-10 container align-items-center'>
        <div className='d-flex py-3 align-items-sm-center'>
          {/* begin::Symbol */}
          <div className='me-5'>
            <div
              style={{
                backgroundImage: `url(${publicEvent.artwork})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundColor: 'black',
              }}
              className='w-50px h-50px symbol symbol-50px'
            />
          </div>
          {/* end::Symbol */}
          {/* begin::Section */}

          <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
            <div className='flex-grow-1 me-2'>
              <Link
                to={`/event/${publicEvent.id}`}
                className='d-flex flex-grow-1 flex-row-fluid flex-wrap'
              >
                <span className='text-dark fw-bolder d-block fs-6'>{publicEvent.name}</span>
              </Link>
              <span className='text-muted fw-semibold d-block fs-7'>
                Starts in {daysUntilDate(new Date(publicEvent?.startDate), true)}
              </span>
            </div>
          </div>

          {/* end::Section */}
        </div>
      </div>

      {/* PATRON LOGIN */}
      <div className='d-flex mw-600px w-100 mt-10 mb-10 container align-items-center'>
        <div className='w-100 card card-custom'>
          <div
            className='card-header collapsible cursor-pointer rotate'
            data-bs-toggle='collapse'
            data-bs-target='#card_patron_collapsible'
          >
            <h3 className='card-title fw-bolder'>
              <span className='me-3'>
                {!isPatronLoggedIn && (
                  <KTIcon iconName='information-2' iconType='solid' className='text-danger fs-2' />
                )}
                {isPatronLoggedIn && (
                  <KTIcon iconName='check-circle' iconType='solid' className='text-success fs-2' />
                )}
              </span>
              Step 1. Identify Yourself
            </h3>
            <div className='card-toolbar rotate-180'>
              <i className='ki-duotone ki-down fs-1'></i>
            </div>
          </div>
          <div id='card_patron_collapsible' className={`collapse show `}>
            <div className='card-body'>
              <PatronAuth />
            </div>
          </div>
        </div>
      </div>

      <Formik
        initialValues={currentBooking}
        validateOnChange={true}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {(form) => {
          // if tickets are selected, set state
          if (
            form.values.bookingTickets &&
            form.values.bookingTickets.some((item) => item.qty > 0)
          ) {
            setIsTicketSelected(true)
          }

          return (
            <Form>
              <div className='w-100 container d-flex mw-600px flex-column flex-row justify-content-center align-items-center'>
                <div className='w-100 mb-10 card card-custom'>
                  <div
                    className='card-header collapsible cursor-pointer rotate'
                    data-bs-toggle='collapse'
                    data-bs-target='#card_tickets_collapsible'
                  >
                    <h3 className='card-title fw-bolder fs-4'>
                      <div className='me-3'>
                        {' '}
                        {form.values.bookingTickets &&
                        form.values.bookingTickets.some((item) => item.qty > 0) ? (
                          <KTIcon
                            iconName='check-circle'
                            iconType='solid'
                            className='text-success fs-2'
                          />
                        ) : (
                          <KTIcon
                            iconName='information-2'
                            iconType='solid'
                            className='text-danger fs-2'
                          />
                        )}
                      </div>
                      Step 2. Choose Tickets
                    </h3>
                    <div className='card-toolbar rotate-180'>
                      <i className='ki-duotone ki-down fs-1'></i>
                    </div>
                  </div>
                  <div id='card_tickets_collapsible' className={`collapse show`}>
                    <div className='card-body'>
                      <FieldArray name='bookingTickets'>
                        {({form}) => {
                          return (
                            form.values.bookingTickets &&
                            form.values.bookingTickets.map(
                              (bookingTicket: BookingTicketModel, index) => (
                                <BookingTicketItem
                                  key={bookingTicket.ticket.id}
                                  index={index}
                                  bookingTicket={bookingTicket}
                                  form={form}
                                />
                              )
                            )
                          )
                        }}
                      </FieldArray>
                      {(!form.values.bookingTickets ||
                        !form.values.bookingTickets.some(
                          (bt) => bt.ticket?.canBook?.canBook === true
                        )) && (
                        <div className='text-center d-flex flex-column'>
                          <h3 className='text-center text-danger'>No tickets available</h3>
                          <span className='text-center text-muted mb-5'>
                            Tickets for this event are not available at the moment.
                            <Link to={`/event/${publicEvent.id}`}> Go back to event</Link>
                          </span>
                        </div>
                      )}
                      <div className='card-footer'>
                        {publicEvent.sellTickets && (
                          <h3 className='d-flex justify-content-end '>
                            Total: {getCurrencySymbol(publicEvent.currency)}{' '}
                            {formatNumber(
                              form.values.bookingTickets
                                ? form.values.bookingTickets.reduce(
                                    (acc, bookingTicket) =>
                                      bookingTicket.ticket.enableVariablePrice === true
                                        ? acc + bookingTicket.value
                                        : acc + bookingTicket.qty * bookingTicket.ticket.price,
                                    0
                                  )
                                : 0,
                              0
                            )}
                          </h3>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='w-100 mb-10 card card-custom'>
                  <div
                    className={clsx('card-header collapsible cursor-pointer rotate', {
                      'text-dark':
                        form.values.bookingTickets &&
                        form.values.bookingTickets.some((item) => item.qty > 0),
                      'text-muted':
                        form.values.bookingTickets &&
                        !form.values.bookingTickets.some((item) => item.qty > 0),
                    })}
                    data-bs-toggle='collapse'
                    data-bs-target='#card_payments_collapsible'
                  >
                    <h3 className='card-title fw-bolder'>
                      <div className='me-3'>
                        {' '}
                        {isPatronLoggedIn &&
                        form.values.bookingTickets &&
                        form.values.bookingTickets.some((item) => item.qty > 0) ? (
                          <KTIcon
                            iconName='check-circle'
                            iconType='solid'
                            className='text-success fs-2'
                          />
                        ) : (
                          <KTIcon
                            iconName='information-2'
                            iconType='solid'
                            className='text-danger fs-2'
                          />
                        )}
                      </div>
                      Step 3. Proceed to Pay
                    </h3>
                    <div className='card-toolbar rotate-180'>
                      <i className='ki-duotone ki-down fs-1'></i>
                    </div>
                  </div>
                  <div id='card_payments_collapsible' className={`collapse show`}>
                    <div className='card-body'>
                      {!isLoading &&
                      isPatronLoggedIn &&
                      form.values.bookingTickets &&
                      form.values.bookingTickets.some((item) => item.qty > 0) ? (
                        <>
                          <button
                            type='submit'
                            className={clsx('btn btn-lg w-100 fw-bolder', {
                              'btn-primary':
                                form.values.bookingTickets &&
                                form.values.bookingTickets.some((item) => item.qty > 0),
                              'btn-secondary':
                                form.values.bookingTickets &&
                                !form.values.bookingTickets.some((item) => item.qty > 0),
                            })}
                          >
                            {!publicEvent.sellTickets ? (
                              'Confirm Booking'
                            ) : (
                              <>
                                Pay {''}
                                {formatMoney(
                                  form.values.bookingTickets
                                    ? form.values.bookingTickets.reduce(
                                        (acc, bookingTicket) =>
                                          bookingTicket.ticket.enableVariablePrice === true
                                            ? acc + bookingTicket.value
                                            : acc + bookingTicket.qty * bookingTicket.ticket.price,
                                        0
                                      )
                                    : 0,
                                  publicEvent.currency,
                                  0
                                )}
                              </>
                            )}
                          </button>
                        </>
                      ) : (
                        <button
                          type='button'
                          disabled
                          className='btn btn-lg w-100 fw-bolder btn-light-danger'
                        >
                          {isLoading ? (
                            <>
                              <span className='spinner-border spinner-border-sm'></span>{' '}
                              Processing...
                            </>
                          ) : (
                            <>
                              {!isPatronLoggedIn && 'Please identify yourself to proceed'}
                              {isPatronLoggedIn &&
                                !form.values.bookingTickets.some((item) => item.qty > 0) &&
                                'Please select tickets to proceed'}
                            </>
                          )}
                        </button>
                      )}

                      <div className='d-flex justify-content-center mt-5'>
                        <div className='text-muted fw-bold fs-7 me-2'>Need help?</div>
                        <button
                          type='button'
                          onClick={() => setSupportModal(true)}
                          className='btn btn-link text-primary fw-bold p-0 fs-7'
                        >
                          Contact Support
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
      <Modal
        show={showSupportModal}
        onHide={() => setSupportModal(false)}
        dialogClassName='modal-dialog-centered'
      >
        <Modal.Header closeButton>
          <Modal.Title>Support</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='d-flex flex-column'>
            {/* email us button */}
            <a
              rel='noreferrer'
              href={`mailto:${appSettings.sbEmail}?subject=Help with booking for ${publicEvent.name}`}
              target='_blank'
              className='btn btn-light mb-5 btn-dark'
            >
              <KTIcon iconName='sms' iconType='outline' className='me-2' />
              Email Skybookings
            </a>

            {/* whatsapp us button */}
            <a
              rel='noreferrer'
              href={`https://wa.me/${appSettings.sbPhone}?text=Hi Skybookings. I need help with my booking for ${publicEvent.name}.`}
              target='_blank'
              className='btn btn-light mb-5 btn-success'
            >
              <KTIcon iconName='whatsapp' iconType='outline' className='me-2' />
              WhatsApp Skybookings
            </a>
          </div>
        </Modal.Body>
      </Modal>

      {isLoggedIn() && (
        <Modal show={true} centered>
          <Modal.Header>
            <Modal.Title className='text-center'>Admin User Detected</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='d-flex '>
              <KTIcon
                iconName='cross-circle'
                className='svg-icon-4x svg-icon fs-3x svg-icon-warning'
              />

              <span className='ms-3'>
                You are currently logged in as an admin user. Please logout to proceed.
              </span>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className='btn btn-light-danger' onClick={logout}>
              Logout
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  )
}

export {PublicBookingPage}
