import React, {useState} from 'react'
import {ErrorMessage, Field, Form, Formik, FormikValues} from 'formik'
import clsx from 'clsx'
import SubmitButton from '../../../../_components/SubmitButton'
import * as Yup from 'yup'

import ImageInput from '../../../../_components/ImageInput'
import {useEventsStore} from '../core/eventsStore'
import TiptapEditor from '../../../../_components/TipTap/Tiptap'

// write validation schema with yup
const validationSchema = Yup.object().shape({
  excerpt: Yup.string().required('Excerpt is required'),
  buttonLabel: Yup.string().required('Button Label is required'),
  content: Yup.string(),
  artwork: Yup.string().required('Artwork is required'),
})

type Props = {
  onValidation?: (values: FormikValues) => void
  onSave?: (values: FormikValues) => void
  step?: string
}

const EventAppearance = (props: Props) => {
  const {step} = props
  const [loading, setLoading] = useState(false)
  const [saveMessage, setSaveMessage] = useState('')
  const [saveIsError, setSaveIsError] = useState<boolean | undefined>(undefined)
  const {currentEvent, saveEvent, saveEventImage} = useEventsStore()

  const handleSubmit = (values, actions: FormikValues) => {
    setLoading(true)

    saveEvent(values)
      .then((response) => {
        setSaveIsError(false)
        setSaveMessage('Event successfully updated')

        setTimeout(() => {
          setSaveIsError(undefined)
        }, 3000)
      })

      .catch((err) => {
        setSaveIsError(true)
        setSaveMessage(err.message)
      })

      .finally(() => setLoading(false))
  }

  const handleSaveArtwork = async (imageUrl: string) => {
    try {
      // listen to upload progress from global axios.
      await saveEventImage('artwork', imageUrl)
    } catch (err: any) {}
  }

  const handleSaveBanner = async (imageUrl: string) => {
    try {
      // listen to upload progress from global axios.
      await saveEventImage('banner', imageUrl)
    } catch (err: any) {}
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header p-5 p-md-10 border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_content_appearance'
        aria-expanded='true'
        aria-controls='kt_content_appearance'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>{step && step + '. '}Content & Appearance</h3>
        </div>
      </div>

      <div id='kt_content_appearance' className='show'>
        <Formik
          validationSchema={validationSchema}
          initialValues={currentEvent}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {(form) => {
            return (
              <Form>
                <>
                  {/* START::  Account Details */}
                  <div className='card-body border-top p-5 p-md-10'>
                    {/* Banner Artwork */}
                    <div className='fv-row mb-10'>
                      <div className='row'>
                        <div className='col'>
                          <ImageInput
                            title={'Event Banner: dimension 16:6'}
                            label={`Change Image`}
                            description={`Banner appears on the top of the event page on laptops and desktops only`}
                            onImageSave={handleSaveBanner}
                            aspectRatio={16 / 6}
                            initialImage={currentEvent.banner}
                            maxImageWidth={150}
                          />
                        </div>
                      </div>
                    </div>

                    {/* Main Artwork */}
                    <div className='fv-row mb-10'>
                      <div className='row'>
                        <div className='col'>
                          <ImageInput
                            title={'Event Artwork: dimension 4:5'}
                            label={`Change Image`}
                            description={`This is your main event artwork page.  Something you would typically post on social media`}
                            onImageSave={handleSaveArtwork}
                            aspectRatio={4 / 5}
                            initialImage={currentEvent.artwork}
                            maxImageWidth={50}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='fv-row mb-10'>
                      <div className='row'>
                        <div className='col'>
                          <div className='d-flex flex-column mb-3'>
                            <label className='required fw-bold fs-4'>Short Description: </label>
                            <span className='fw-normal fs-5 text-muted'>
                              Describe the event in one or two sentences
                            </span>
                          </div>

                          <Field
                            as='textarea'
                            className='form-control form-control-lg '
                            name='excerpt'
                          />
                          <ErrorMessage name='excerpt'>
                            {(msg) => <div className='text-danger'>{msg}</div>}
                          </ErrorMessage>
                        </div>
                      </div>
                    </div>

                    <div className='fv-row mb-10'>
                      <div className='row'>
                        <div className='col'>
                          <div className='d-flex flex-column mb-3'>
                            <label className='fw-bold fs-4'>Full Description</label>
                            <span className='fw-normal fs-5 text-muted'>
                              Sell your event. The more info the better.
                            </span>
                          </div>
                          <TiptapEditor
                            name='content'
                            className='form-control'
                            suggestions={[]}
                            showToolbar
                          />
                          <ErrorMessage name='content'>
                            {(msg) => <div className='text-danger'>{msg}</div>}
                          </ErrorMessage>
                        </div>
                      </div>
                    </div>

                    <div className='separator separator-dashed my-10'></div>
                    <div className='fv-row mb-10'>
                      <div className='row'>
                        <div className='col'>
                          <label className='form-label fs-5 fw-bolder required'>
                            Booking Button Label
                          </label>

                          <Field name='buttonLabel' className='form-control form-control-lg ' />
                          <div className='text-danger mt-2'>
                            <ErrorMessage name='buttonLabel' />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='card-footer d-flex justify-content-end p-5 p-md-10'>
                    {(saveIsError === true || saveIsError === false) && (
                      <div className='d-flex align-items-center py-3 px-6'>
                        <span
                          className={clsx('text fw-bolder', {
                            'text-danger': saveIsError === true,
                            'text-success': saveIsError === false,
                          })}
                        >
                          {saveMessage}
                        </span>{' '}
                      </div>
                    )}

                    <SubmitButton
                      isValid={form.isValid}
                      isSubmitting={loading}
                      label='Save Changes'
                    />
                  </div>
                  {/* END:: Account Details  */}
                </>
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

export {EventAppearance}
