import {Form, Modal} from 'react-bootstrap'
import {KTIcon} from '../../../../_metronic/helpers'
import {FC, useEffect, useState} from 'react'
import {ErrorMessage, Field, Formik} from 'formik'
import {
  initialClient,
  Pagination,
  ClientEventModel,
  useClientStore,
} from '../../clients/core/clientStore'
import SubmitButton from '../../../../_components/SubmitButton'
import {useEventsStore} from '../core/eventsStore'
import PhoneField from '../../../../_components/PhoneField'
import * as Yup from 'yup'
import EmailInput from '../../../../_components/EmailInput'

// Schema for yup
const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  phone: Yup.string().required('Phone is required'),
})

type Props = {
  show?: boolean
  onHide?: () => void
  clientEvent?: ClientEventModel
}

const ClientEventEditor: FC<Props> = (props) => {
  const [showModal, setShowModal] = useState(props.show || false)
  const {currentEvent} = useEventsStore()
  const {clientEvents, postClient, patchClient, getClientEvents, deleteClientEvent} =
    useClientStore()
  const [isSubmittingClient, setIsSubmittingClient] = useState(false)
  const [trashingClient, setTrashingClient] = useState<string>('')
  const [loadingClients, setLoadingClients] = useState(false)
  const [selectedClientEvent, setSelectedClientEvent] = useState<ClientEventModel | null>(
    props.clientEvent || null
  )

  useEffect(() => {
    if (!currentEvent.id) return

    // GET CLIENTS USING PAGINATION
    const pg: Pagination = {page: 1, limit: 100, event: currentEvent.id}
    setLoadingClients(true)
    getClientEvents(pg)
      .catch((err) => {
        console.error(err)
      })

      .finally(() => {
        setLoadingClients(false)
      })
  }, [])

  const handleSave = async (values) => {
    setIsSubmittingClient(true)
    try {
      // if no event id, return
      if (!currentEvent.id) return

      // post client
      if (selectedClientEvent) {
        await patchClient(values)
      } else {
        await postClient(currentEvent.id, values)
      }

      // get clientevents
      const pg: Pagination = {page: 1, limit: 100, event: currentEvent.id}
      await getClientEvents(pg)

      // close modal
      setShowModal(false)
    } catch (err) {
      console.error(err)
    }
    setIsSubmittingClient(false)
  }

  const handleTrashClient = async (clientId) => {
    if (!currentEvent.id) return

    try {
      setTrashingClient(clientId)
      await deleteClientEvent(clientId, currentEvent.id)
      setTrashingClient('')
    } catch (err) {
      setTrashingClient('')
    }
  }

  const handleSelectClient = (clientEvent: ClientEventModel) => {
    setSelectedClientEvent(clientEvent)
    setShowModal(true)
  }

  const handleAddClient = () => {
    setSelectedClientEvent(null)
    setShowModal(true)
  }

  return (
    <>
      <div className='d-flex w-100 align-items-center mb-5 justify-content-end'>
        <button
          className='btn btn-light-info btn-sm d-flex d-block align-items-center'
          type='button'
          onClick={handleAddClient}
        >
          <KTIcon iconName='plus-square' className='me-2' />
          Client
        </button>
      </div>

      {/* list of clients */}
      {clientEvents.map((eventClient) => (
        <div
          key={eventClient.id}
          className='d-flex bg-light align-items-center bg-hover-secondary mb-5 p-5 border-primary border-bottom-solid'
          style={{borderLeft: '3px solid #414141'}}
        >
          <div className='d-flex flex-column'>
            <span className='fw-bolder fs-5 text-dark mb-1 '>{eventClient.client.name}</span>
            <div className='d-flex flex-column flex-md-row'>
              <span className='text-muted fs-6 mb-1 me-md-3'>{eventClient.client.email}</span>
              <span className='text-muted fs-6'>+{eventClient.client.phone}</span>
            </div>
          </div>
          <div className='d-flex ms-auto'>
            <button
              className='btn btn-icon btn-light-dark btn-sm me-2 btn-active-primary'
              type='button'
              onClick={() => {
                handleSelectClient(eventClient)
              }}
            >
              <KTIcon iconName='pencil' iconType='outline' />
            </button>
            <button
              onClick={() => handleTrashClient(eventClient.client.id)}
              type='button'
              className='btn btn-icon btn-light-dark btn-sm btn-active-danger'
            >
              {trashingClient === eventClient.client.id ? (
                <span className='spinner text-light spinner-border text-primary spinner-sm'></span>
              ) : (
                <KTIcon iconName='trash' iconType='outline' />
              )}
            </button>
          </div>
        </div>
      ))}

      {/* Modal to add Client */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Body>
          <Formik
            initialValues={selectedClientEvent?.client || initialClient}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={handleSave}
          >
            {({isValid, submitForm, isSubmitting, values}) => (
              <Form
                onSubmit={(event) => {
                  event.preventDefault()
                  event.stopPropagation()
                  submitForm()
                }}
              >
                <div className='modal-header'>
                  <h2 className='fw-bolder'>{values?.id ? 'Update Client' : 'Add New Client'}</h2>
                  <div
                    className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                    data-bs-dismiss='modal'
                  >
                    <KTIcon iconName='x' className='fs-2' />
                  </div>
                </div>
                <div className='modal-body'>
                  <>
                    <div className='fv-row mb-10'>
                      <div className='col mb-10'>
                        <label className='form-label fw-bolder'>Client Name</label>
                        <Field
                          type='text'
                          className='form-control form-control-lg form-control-solid'
                          name='name'
                        />
                        <ErrorMessage name='name' component='div' className='text-danger' />
                      </div>
                      <div className='col mb-10'>
                        <label className='form-label fw-bolder'>Client Email</label>
                        <Field
                          name='email'
                          className='form-control form-control-lg form-control-solid'
                        />
                        <ErrorMessage name='email' component='div' className='text-danger' />
                      </div>
                    </div>

                    <div className='col mb-10'>
                      <label className='form-label fw-bolder'>Client Phone</label>
                      <PhoneField name='phone' />
                      <ErrorMessage name='phone' component='div' className='text-danger' />
                    </div>
                  </>
                </div>
                <div className='modal-footer'>
                  <button
                    type='button'
                    className='btn btn-light btn-active-light-danger me-3'
                    data-bs-dismiss='modal'
                    onClick={() => setShowModal(false)}
                  >
                    Cancel
                  </button>
                  <SubmitButton
                    isValid={isValid}
                    isSubmitting={isSubmittingClient}
                    label={values?.id ? 'Update Client' : 'Add Client'}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ClientEventEditor
