import {FC} from 'react'
import {EventModel} from '../app/modules/events/core/eventsStore'
import clsx from 'clsx'

type Props = {
  event: EventModel
  size: number
  showAvailabilityIndicator?: boolean
}

const EventThumbnail: FC<Props> = ({event, size, showAvailabilityIndicator = false}) => {
  return (
    <div
      style={{
        backgroundImage: `url(${event.artwork})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: 'black',
        position: 'relative',
      }}
      className={`w-${size}px h-${size}px symbol symbol-${size}px me-3`}
    >
      {showAvailabilityIndicator && (
        <div
          className={clsx('position-absolute bg-success w-15px h-15px rounded-circle', {
            'bg-success': event.canBook.canBook === true,
            'bg-danger': event.canBook.canBook === false,
          })}
          style={{border: '1px solid white', top: '-5px', right: '-5px'}}
        ></div>
      )}
    </div>
  )
}
export default EventThumbnail
