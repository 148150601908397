import {Field, Formik} from 'formik'
import {PatronModel, usePatronStore} from '../../../modules/patrons/core/patronStore'
import * as Yup from 'yup'
import {FC, useState} from 'react'
import PhoneField from '../../../../_components/PhoneField'
import {Form, Modal} from 'react-bootstrap'
import {EventModel} from '../../../modules/events/core/eventsStore'

type Props = {
  onChange?: (patron: PatronModel) => void
  onClose?: () => void
  onSaveError?: (error: any) => void
  patron: PatronModel
  buttonClassName?: string
  buttonLabel?: string
  tags?: string[]
  events?: EventModel[]
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
})

const PatronEditor: FC<Props> = (props) => {
  const {
    buttonLabel = 'edit',
    buttonClassName = 'btn btn-outline btn-sm btn-active-dark',
    patron,
    onSaveError,
  } = props
  const [showModal, setShowModal] = useState<boolean>(false)
  const {patchPatron} = usePatronStore()
  const [submitting, setSubmitting] = useState<boolean>(false)

  const handleSubmitPatron = async (values: PatronModel) => {
    setSubmitting(true)
    patchPatron({...values, id: patron.id})
      .finally(() => {
        setShowModal(false)
        setSubmitting(false)
      })
      .catch((err) => {
        onSaveError && onSaveError(err)
      })
  }

  return (
    <>
      <button onClick={() => setShowModal(true)} className={buttonClassName}>
        {buttonLabel}
      </button>
      <Formik
        enableReinitialize
        initialValues={patron as PatronModel}
        onSubmit={handleSubmitPatron}
        validationSchema={validationSchema}
      >
        {(form) => (
          <Form>
            <Modal
              show={showModal}
              onHide={() => setShowModal(false)}
              centered
              className='modal-dialog-centered modal-dialog-scrollable'
            >
              <Modal.Header>
                <h5 className='modal-title'>Edit Patron</h5>
                <button type='button' className='btn-close' onClick={() => setShowModal(false)} />
              </Modal.Header>

              <Modal.Body>
                {/* name */}
                <div className='fv-row mb-5'>
                  <div className='row'>
                    <div className='col'>
                      <label className='form-label fw-bold fs-6'>Name</label>

                      <Field name='name' className='form-control ' />
                      {form.errors.name && (
                        <div className='text-danger mt-2'>{form.errors.name}</div>
                      )}
                    </div>
                  </div>
                </div>

                {/* email */}
                <div className='fv-row mb-5'>
                  <div className='row'>
                    <div className='col'>
                      <label className='form-label fw-bold fs-6'>Email</label>

                      <Field name='email' className='form-control' />
                      {form.errors.email && (
                        <div className='text-danger mt-2'>{form.errors.email}</div>
                      )}
                    </div>
                  </div>
                </div>

                {/* phone */}
                <div className='fv-row'>
                  <div className='row'>
                    <div className='col'>
                      <label className='form-label fw-bold fs-6'>Phone</label>

                      <PhoneField required={false} name='phone' />
                      {form.errors.phone && (
                        <div className='text-danger mt-2'>{form.errors.phone}</div>
                      )}
                    </div>
                  </div>
                </div>

                <div className='separator my-5'></div>
                <label className='form-label fw-bold fs-6'>Tags</label>
                <div className='d-flex w-100 flex-wrap mb-5'>
                  {patron.tags?.map((tag) => (
                    <span key={tag} className='badge badge-secondary m-1'>
                      {tag}
                    </span>
                  ))}
                </div>

                <div className='separator my-5'></div>
                <div className='fv-row mb-5'>
                  <div className='row'>
                    <div className='col'>
                      <div className='d-flex me-5'>
                        <label className='form-check cursor-pointer'>
                          <Field type='checkbox' name='isBanned' className='form-check-input' />
                          <span className='form-check-label fw-bold fs-6'>Ban Patron</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {/* unsubscribed from email */}
                <div className='fv-row mb-5'>
                  <div className='row'>
                    <div className='col'>
                      <div className='d-flex me-5'>
                        <label className='form-check cursor-pointer'>
                          <Field
                            type='checkbox'
                            name='unsubscribedFromEmails'
                            className='form-check-input'
                            disabled={form.values.isBanned}
                          />
                          <span className='form-check-label fw-bold fs-6'>
                            Unsubscribed from Email
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                {/* unsubscribed from sms */}
                <div className='fv-row mb-5'>
                  <div className='row'>
                    <div className='col'>
                      <div className='d-flex me-5'>
                        <label className='form-check cursor-pointer'>
                          <Field
                            type='checkbox'
                            name='unsubscribedFromSMS'
                            className='form-check-input'
                            disabled={form.values.isBanned}
                          />
                          <span className='form-check-label fw-bold fs-6'>
                            Unsubscribed from SMS
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type='button'
                  onClick={() => setShowModal(false)}
                  className='btn btn-sm btn-active-secondary me-2'
                >
                  Cancel
                </button>

                <button
                  type='button'
                  onClick={() => form.submitForm()}
                  className='btn btn-active-dark btn-outline btn-sm'
                  disabled={!form.isValid || form.isSubmitting}
                >
                  {form.isSubmitting ? (
                    <span
                      className='spinner-border spinner-border-sm me-2'
                      role='status'
                      aria-hidden='true'
                    ></span>
                  ) : (
                    'Save Changes'
                  )}
                </button>
              </Modal.Footer>
            </Modal>
          </Form>
        )}
      </Formik>
    </>
  )
}
export default PatronEditor
