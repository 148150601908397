import {AsyncTypeahead, RenderMenuItemChildren} from 'react-bootstrap-typeahead'
import {useAccountsUsersStore} from '../settings/core/accountsUsersStore'
import {FC, useState} from 'react'
import {PatronModel, usePatronStore} from './core/patronStore'

type Props = {
  onSelect: (patron: PatronModel) => void
}
const PatronSearch: FC<Props> = ({onSelect}) => {
  const {searchPatrons} = usePatronStore()
  const {selectedAccountsUsers} = useAccountsUsersStore()
  const [options, setOptions] = useState<PatronModel[]>([])

  const handleSearch = (query: string) => {
    searchPatrons({search: query, limit: 5, page: 1, account: selectedAccountsUsers.account.id})
      .then((res) => {
        setOptions(res)
      })
      .catch((e) => console.error(e))
  }

  const handleSelection = (selected: any[]) => {
    if (selected.length && onSelect) {
      onSelect(selected[0])
    }
  }

  return (
    <AsyncTypeahead
      id='search-patrons'
      isLoading={false}
      minLength={3}
      labelKey={'name'}
      placeholder='Search for a patron...'
      onSearch={handleSearch}
      onChange={handleSelection}
      options={options}
    />
  )
}
export default PatronSearch
