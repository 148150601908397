import React, {FC, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {KTIcon} from '../../../../_metronic/helpers'

import {
  intitialPatronQuery,
  PatronQueryModel,
  QueryPartModel,
  usePatronStore,
} from '../../../modules/patrons/core/patronStore'
import {useAccountsUsersStore} from '../../../modules/settings/core/accountsUsersStore'
import {ErrorMessage, Field, FieldArray, Formik} from 'formik'
import toast, {Toaster} from 'react-hot-toast'
import QueryPartBadge from './QueryPartBadge'
import {EventModel} from '../../../modules/events/core/eventsStore'
import {Typeahead} from 'react-bootstrap-typeahead'
import Tippy from '@tippyjs/react'

const PREVIEW = 'preview'
const EDIT = 'edit'
const LIST = 'list'

type Props = {
  onApplyQuery: (patronQueryId: string) => void // filter: {role, last_login}
  onModeChange?: (mode: string) => void
  onResetQuery?: () => void
  onOpened?: () => void
  className?: string
  isLoading?: boolean
  displayMode?: string
  buttonLabel?: string
  buttonIcon?: string
  tags?: string[]
  events?: EventModel[]
}

type QueryField = {
  name: string
  label: string
  type: string
  operators: {label: string; value: string}[]
  options?: string[] | {id: string; label: string}[]
}

const initialQueryFields: QueryField[] = [
  // tags
  {
    name: 'tags',
    label: 'Tags',
    type: 'text',
    operators: [
      {
        label: 'includes',
        value: 'contains',
      },
      {
        label: 'do not include',
        value: 'ncontains',
      },
    ],
  },

  // events
  {
    name: 'events',
    label: 'Events Booked',
    type: 'text',
    operators: [
      {
        label: 'includes',
        value: 'contains',
      },
      {
        label: 'do not include',
        value: 'ncontains',
      },
    ],
  },

  // tickets
  {
    name: 'ticketsCount',
    label: 'Number of Tickets Issued',
    type: 'number',
    operators: [
      {
        label: 'is',
        value: 'eq',
      },
      {
        label: 'is not',
        value: 'neq',
      },
      {
        label: 'is greater than',
        value: 'gt',
      },
      {
        label: 'is less than',
        value: 'lt',
      },
    ],
  },

  // ticket value
  {
    name: 'ticketsValue',
    label: 'Amount Spent on Tickets',
    type: 'number',
    operators: [
      {
        label: 'is',
        value: 'eq',
      },
      {
        label: 'is not',
        value: 'neq',
      },
      {
        label: 'is greater than',
        value: 'gt',
      },
      {
        label: 'is less than',
        value: 'lt',
      },
    ],
  },

  // isBanned
  {
    name: 'isBanned',
    label: 'Is Banned',
    type: 'boolean',
    operators: [
      {
        label: 'is',
        value: 'eq',
      },
    ],
    options: ['true', 'false'],
  },

  // unsubscribedFromEmails
  {
    name: 'unsubscribedFromEmails',
    label: 'Unsubscribed From Emails',
    type: 'boolean',
    operators: [
      {
        label: 'is',
        value: 'eq',
      },
    ],
    options: ['true', 'false'],
  },

  // unsubscribedFromSMS
  {
    name: 'unsubscribedFromSMS',
    label: 'Unsubscribed From SMS',
    type: 'boolean',
    operators: [
      {
        label: 'is',
        value: 'eq',
      },
    ],
    options: ['true', 'false'],
  },
]

const initialQuery: QueryPartModel[] = [
  {
    field: initialQueryFields[0].name,
    operator: initialQueryFields[0].operators[0].value,
    value: initialQueryFields[0].options
      ? typeof initialQueryFields[0].options[0] === 'string'
        ? initialQueryFields[0].options[0]
        : initialQueryFields[0].options[0].id
      : '',

    connector: 'AND',
  },
]

const PatronQueryEditor: FC<Props> = ({
  onApplyQuery,
  onModeChange,
  onResetQuery,
  onOpened,
  isLoading,
  className = 'btn btn-sm btn-light btn-active-light-info',
  buttonLabel = 'Manage',
  buttonIcon = 'setting-2',
  displayMode = LIST,
  tags,
  events,
}) => {
  const [show, setShow] = useState<boolean>(false)
  const [mode, setMode] = useState<string>(displayMode)
  const [activePatronQuery, setActivePatronQuery] = useState<PatronQueryModel>(intitialPatronQuery)
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const {
    patronQueries,
    getPatronQueries,
    savePatronQuery,
    setCurrentPatronQuery,
    deletePatronQuery,
  } = usePatronStore()

  const {selectedAccountsUsers} = useAccountsUsersStore()
  const [queryFields, setQueryFields] = useState<QueryField[]>(initialQueryFields)

  // RENDERS

  useEffect(() => {
    if (!selectedAccountsUsers.account.id) return

    const payload: any = {}
    if (selectedAccountsUsers.account && selectedAccountsUsers.account.id) {
      payload.account = selectedAccountsUsers.account.id
    }
    getPatronQueries(payload).then((response) => {
      if (response.length === 0) {
        // add a default query
        const initQP = {...intitialPatronQuery, filters: initialQuery}
        setActivePatronQuery(initQP)
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccountsUsers.account.id])

  useEffect(() => {
    if (onModeChange) {
      onModeChange(mode)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode])

  // set query field options
  useEffect(() => {
    // if field is tags, set the options to the tags
    if (tags && queryFields) {
      const tagsField: QueryField = queryFields.find((f) => f.name === 'tags')!

      if (tagsField) {
        tagsField.options = tags.map((t) => ({id: t, label: t}))
      }
      // set the updated query fields
      const newFields = queryFields.map((f) => (f.name === 'tags' ? tagsField : f))
      setQueryFields(newFields!)
    }

    if (events && queryFields) {
      const eventsField: QueryField = queryFields.find((f) => f.name === 'events')!

      if (eventsField) {
        eventsField.options = events.map((e) => ({id: e.id!, label: e.name}))
      }

      const newFields = queryFields.map((f) => (f.name === 'events' ? eventsField : f))
      setQueryFields(newFields!)
    }
  }, [events, tags, queryFields])

  // LIST FUNCTIONSS
  const handleApplyFilter = (id: string) => {
    if (!id) return
    setCurrentPatronQuery(id)
    onApplyQuery(id)
    setShow(false)
  }

  const handleDeletePatronQuery = async (id: string) => {
    try {
      await deletePatronQuery(id)
    } catch (err) {
      toast.error('Could not delete circle')
    }
  }

  const handleCreatePatronQuery = () => {
    setMode(EDIT)
    setShow(true)
    const initQP = {...intitialPatronQuery, filters: initialQuery}
    setActivePatronQuery(initQP)
  }

  // EDITOR FUNCTIONS

  const handleEditSubmit = (values: any) => {
    setMode(PREVIEW)
    setActivePatronQuery({...activePatronQuery, filters: values})
    console.log('submitting >> ', values)
  }

  const handleRemoveQueryPart = (index: number) => {
    const newQuery = activePatronQuery.filters.filter((_, i) => i !== index)
    setActivePatronQuery({...activePatronQuery, filters: newQuery})
  }

  const handleChangeConnector = (newConnectorValue, form, index) => {
    // update the field value
    const queryPart: QueryPartModel = {...form.values[index]}
    queryPart.connector = newConnectorValue

    // update field values
    const nValues = form.values.map((qp, i) => (i === index ? queryPart : qp))
    form.setValues(nValues)
  }

  const handleAddQueryPart = () => {
    const activePatronQueryCopy = {...activePatronQuery}
    activePatronQueryCopy.filters.push(initialQuery[0])
    setActivePatronQuery(activePatronQueryCopy)
  }

  const handleChangeField = (newFieldValue, form, index) => {
    // update the field value
    const queryPart: QueryPartModel = {...form.values[index]}
    queryPart.field = newFieldValue

    // updated queryPart with default operator and value if its available
    const field = queryFields.find((f) => f.name === newFieldValue)!

    queryPart.operator = field?.operators[0].value
    queryPart.value = field?.options
      ? typeof field.options[0] === 'string'
        ? field.options[0]
        : field.options[0].id
      : ''

    // update field values
    const nValues = form.values.map((qp, i) => (i === index ? queryPart : qp))
    form.setValues(nValues)
  }

  // PREVIEW FUNCTIONS

  const handlePreviewSubmit = async (values: any) => {
    setIsSaving(true)
    try {
      // save the query
      const pq: PatronQueryModel = await savePatronQuery(values)
      const updatedValues = {...activePatronQuery, ...pq}
      setActivePatronQuery(updatedValues)

      // switch mode to list
      setMode(LIST)
    } catch (err) {
      toast.error('Could not save query')
      console.log(err)
    }
    setIsSaving(false)
  }

  return (
    <>
      <Toaster />
      {/* begin::Filter Button */}
      <Tippy content='Manage Circles' placement='top'>
        <button onClick={() => setShow(true)} className={className}>
          <KTIcon iconName={buttonIcon} iconType='outline' className='fs-3 text-dark' />{' '}
          {buttonLabel}
        </button>
      </Tippy>

      {/* end::Filter Button */}
      <Modal id='patron-query-editor' show={show} size='lg' centered onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {mode === EDIT ? 'Setup Circle' : mode === PREVIEW ? 'Save Circle' : 'Manage Circles'}
          </Modal.Title>
        </Modal.Header>

        {/* LIST EXISTING PATRONQUERIES */}
        {mode === LIST && (
          <>
            <Modal.Body>
              <div className='d-flex flex-column p-0 p-md-10'>
                {patronQueries.length === 0 && (
                  <div className='d-flex flex-column align-items-center border border-secondary border-dashed rounded bg-light p-5'>
                    <p className='text-center fs-5'>
                      No circles yet? Create circles to organise your patrons
                    </p>
                  </div>
                )}
                <div className='d-flex justify-content-end'>
                  <button
                    className='btn btn-sm btn-outline btn-active-dark mb-5'
                    onClick={handleCreatePatronQuery}
                  >
                    <KTIcon iconName='plus-circle' iconType='outline' className='me-1 fs-5' />
                    Add Circle
                  </button>
                </div>
                {patronQueries.length > 0 &&
                  patronQueries.map((patronQuery) => (
                    <div
                      key={patronQuery.id}
                      className='d-flex justify-content-between mb-3 border border-secondary rounded bg-hover-light p-5'
                    >
                      <div className='d-flex flex-column'>
                        <span className='fw-bold fs-5'>{patronQuery.name}</span>
                        <span className='d-flex  fs-7'>
                          {patronQuery.filters.map((qp) => (
                            <QueryPartBadge
                              events={events ?? []}
                              key={qp.field}
                              queryPart={qp}
                              mode='text'
                              showConnector={
                                patronQuery.filters.indexOf(qp) < patronQuery.filters.length - 1
                                  ? true
                                  : false
                              }
                            />
                          ))}
                        </span>
                      </div>

                      <div className='d-flex'>
                        <button
                          className='btn btn-sm me-2 btn-icon btn-active-danger'
                          onClick={() => handleDeletePatronQuery(patronQuery.id!)}
                        >
                          <KTIcon iconName='trash' iconType='outline' className='fs-3' />
                        </button>
                        <button
                          className='btn btn-sm me-2 btn-icon btn-active-secondary'
                          onClick={() => {
                            setActivePatronQuery(patronQuery)
                            setMode(EDIT)
                          }}
                        >
                          <KTIcon iconName='pencil' iconType='outline' className='fs-3' />
                        </button>
                        <button
                          className='btn btn-sm btn-icon w-100px btn-active-secondary'
                          onClick={() => {
                            handleApplyFilter(patronQuery.id!)
                          }}
                        >
                          <KTIcon iconName='filter' iconType='outline' className='fs-3 me-2' />{' '}
                          apply filter
                        </button>
                      </div>
                    </div>
                  ))}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className='d-flex justify-content-end'>
                <button
                  onClick={() => setShow(false)}
                  className='btn btn-sm btn-active-danger me-2'
                >
                  close
                </button>
              </div>
            </Modal.Footer>
          </>
        )}

        {/* EDITOR */}
        {mode === EDIT && (
          <>
            <Formik
              initialValues={activePatronQuery.filters}
              enableReinitialize
              onSubmit={handleEditSubmit}
            >
              {(form) => (
                <>
                  <Modal.Body className='bg-light'>
                    <div className='d-flex flex-column'>
                      <div className='d-flex justify-content-between'>
                        <h3 className='fs-5 text-muted text-decoration-italics mb-5'>
                          Fetch patrons where...
                        </h3>
                      </div>

                      <FieldArray name='query'>
                        {({push, remove}) => {
                          return (
                            <>
                              {form.values.length > 0 &&
                                form.values.map((queryPart: QueryPartModel, index) => (
                                  <div key={`qp-${index}`}>
                                    <div
                                      className={
                                        'd-flex flex-column flex-md-row mb-15 align-items-center p-5 border border-secondary rounded bg-white shadow-sm'
                                      }
                                    >
                                      {/* Field */}
                                      <Field
                                        as='select'
                                        className='form-select form-control form-control-solid border border-secondary mb-5 mb-md-0'
                                        name={`[${index}].field`}
                                        onChange={(e) =>
                                          handleChangeField(e.target.value, form, index)
                                        }
                                      >
                                        {queryFields.map((field) => (
                                          <option key={field.name} value={field.name}>
                                            {field.label}
                                          </option>
                                        ))}
                                      </Field>

                                      {/* operator */}

                                      <div className='d-flex w-100 w-lg-550px mx-3 mb-5 mb-md-0'>
                                        <Field
                                          as='select'
                                          className='form-select form-control form-control-solid border border-secondary'
                                          name={`[${index}].operator`}
                                        >
                                          {queryFields
                                            .find((f) => f.name === form.values[index]?.field)
                                            ?.operators.map((operator) => (
                                              <option key={operator.value} value={operator.value}>
                                                {operator.label}
                                              </option>
                                            ))}
                                        </Field>
                                      </div>

                                      {/* value */}

                                      {queryFields.find((f) => f.name === form.values[index]?.field)
                                        ?.type === 'number' && (
                                        <Field
                                          type='number'
                                          name={`[${index}].value`}
                                          className='form-control form-control-solid  border border-secondary'
                                        />
                                      )}

                                      {queryFields.find((f) => f.name === form.values[index]?.field)
                                        ?.type === 'text' && (
                                        <>
                                          {queryFields.find(
                                            (f) => f.name === form.values[index]?.field
                                          )?.options ? (
                                            <Typeahead
                                              id='patron-editor-tags'
                                              multiple={false}
                                              allowNew
                                              className='min-w-200px'
                                              labelKey={(option) =>
                                                typeof option === 'string' ? option : option.label
                                              }
                                              onChange={(selected) => {
                                                form.setFieldValue(
                                                  `[${index}].value`,
                                                  typeof selected[0] === 'string'
                                                    ? selected[0]
                                                    : selected[0].id
                                                )
                                              }}
                                              options={
                                                queryFields.find(
                                                  (f) => f.name === form.values[index]?.field
                                                )?.options! as string[]
                                              }
                                            />
                                          ) : (
                                            <Field
                                              type='text'
                                              name={`[${index}].value`}
                                              className='form-control form-control-solid  border border-secondary'
                                            />
                                          )}
                                        </>
                                      )}

                                      {queryFields.find((f) => f.name === form.values[index]?.field)
                                        ?.type === 'boolean' && (
                                        <Field
                                          as='select'
                                          name={`[${index}].value`}
                                          className='form-select form-control form-control-solid  border border-secondary'
                                        >
                                          {queryFields
                                            .find((f) => f.name === form.values[index]?.field)
                                            ?.options?.map((option) => (
                                              <option key={option} value={option}>
                                                {option}
                                              </option>
                                            ))}
                                        </Field>
                                      )}

                                      {/* add button */}

                                      <div className='d-flex justify-content-end'>
                                        <button
                                          disabled={activePatronQuery.filters.length < 2}
                                          onClick={() => handleRemoveQueryPart(index)}
                                          className='btn btn-icon ms-3 fs-1'
                                        >
                                          <KTIcon
                                            className='fs-1 text-secondary text-hover-dark'
                                            iconName='cross-circle'
                                            iconType='solid'
                                          />
                                        </button>
                                      </div>
                                    </div>

                                    {index < activePatronQuery.filters.length - 1 && (
                                      <div className='d-flex justify-content-start mb-10'>
                                        {/* input group */}
                                        <div
                                          className='btn-group btn-group-sm btn-group-toggle'
                                          role='group'
                                          aria-label='Basic checkbox toggle button group'
                                        >
                                          <Field
                                            type='radio'
                                            className='btn-check '
                                            name={`[${index}].connector`}
                                            id='btnradio1'
                                            autocomplete='off'
                                            checked={form.values[index].connector === 'AND'}
                                          />
                                          <label
                                            onClick={() =>
                                              handleChangeConnector('AND', form, index)
                                            }
                                            className='btn btn-outline btn-active-secondary'
                                          >
                                            AND
                                          </label>

                                          <Field
                                            type='radio'
                                            className='btn-check'
                                            name={`[${index}].connector`}
                                            id='btnradio1'
                                            autocomplete='off'
                                            checked={form.values[index].connector === 'OR'}
                                          />
                                          <label
                                            onClick={() => handleChangeConnector('OR', form, index)}
                                            className='btn btn-outline btn-active-secondary'
                                          >
                                            OR
                                          </label>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                ))}
                            </>
                          )
                        }}
                      </FieldArray>
                      <div className='d-flex justify-content-between'>
                        <button
                          className='btn bg-white btn-active-info border border-secondary'
                          onClick={handleAddQueryPart}
                        >
                          <KTIcon iconName='plus-circle' iconType='outline' className='me-1 fs-5' />
                          Add Filter
                        </button>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <div className='d-flex justify-content-between w-100 '>
                      <button
                        onClick={() => setMode(LIST)}
                        className='btn btn-sm btn-active-dark border border-secondary'
                      >
                        <KTIcon iconName='left' iconType='outline' className='me-1 fs-5' />
                        Back
                      </button>
                      <button
                        onClick={() => form.submitForm()}
                        className='btn btn-sm btn-dark btn-active-info'
                      >
                        <KTIcon iconName='eye' iconType='outline' className='me-1 fs-5' />
                        Preview
                      </button>
                    </div>
                  </Modal.Footer>
                </>
              )}
            </Formik>
          </>
        )}

        {/* PREVIEW */}
        {mode === PREVIEW && (
          <>
            <Formik
              initialValues={activePatronQuery}
              enableReinitialize
              onSubmit={handlePreviewSubmit}
            >
              {(form) => (
                <>
                  <Modal.Body className='bg-light'>
                    <div className='d-flex flex-column p-0 px-md-20 py-md-10'>
                      <div className='fv-row mb-10'>
                        <div className='col'>
                          <label className='form-label fw-bolder'>Circle Name</label>
                          <Field
                            type='text'
                            className='form-control form-control-lg '
                            name='name'
                          />
                          <ErrorMessage name='name' component='div' className='text-danger' />
                        </div>
                      </div>
                      <div className='fv-row'>
                        <div className='col'>
                          <label className='form-label fw-bolder mb-3'>Get Patrons Where... </label>
                          <div className='d-flex w-100 flex-wrap'>
                            {activePatronQuery.filters.map((qp: QueryPartModel, index: number) => (
                              <QueryPartBadge
                                key={index}
                                events={events ?? []}
                                queryPart={qp}
                                showConnector={
                                  activePatronQuery.filters.indexOf(qp) <
                                  activePatronQuery.filters.length - 1
                                    ? true
                                    : false
                                }
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <div className='d-flex justify-content-between w-100'>
                      <button
                        onClick={() => setMode(EDIT)}
                        className='btn btn-sm btn-active-dark border border-secondary'
                      >
                        <KTIcon iconName='left' iconType='outline' className='me-1 fs-5' />
                        Back
                      </button>
                      <div className='d-flex'>
                        <button
                          onClick={() => setMode(LIST)}
                          className='btn btn-sm btn-active-light-danger me-2'
                        >
                          Cancel{' '}
                        </button>
                        <button className='btn btn-sm btn-dark' onClick={() => form.submitForm()}>
                          {isSaving ? (
                            <>
                              <span className='spinner-border spinner-border-sm me-2'></span>
                              Just a sec
                            </>
                          ) : (
                            <>
                              <KTIcon iconName='save' iconType='outline' className='me-1 fs-5' />
                              Save Circle
                            </>
                          )}
                        </button>
                      </div>
                    </div>
                  </Modal.Footer>
                </>
              )}
            </Formik>
          </>
        )}
      </Modal>
    </>
  )
}

export {PatronQueryEditor}
