/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useEventsStore} from './core/eventsStore'
import {useNavigate, useParams} from 'react-router-dom'
import {eventDateLabel} from '../../../_helpers/_templateHelpers'
import {formatDateDMYHI, formatMoney} from '../../../_helpers/_helpers'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import MapViewer from '../../../_components/MapViewer'
import {PageLoadingSpinner} from '../../../_components/PageLoadingSpinner'
import {isMobileDevice} from '../../../_metronic/assets/ts/_utils'
import {Helmet} from 'react-helmet'
import {useAppSettingsStore} from '../settings/core/appSettingsStore'
import {
  GA_EVENT_EVENT_VIEW_PAGE,
  initializeGA,
  trackEvent,
  trackPageView,
} from '../../../_helpers/_analytics'
import clsx from 'clsx'

const EventPublicPage: FC = () => {
  const {getPublicEvent, publicEvent} = useEventsStore()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const {eventId} = useParams<{eventId: string}>()
  const [bookingsPCT, setBookingsPCT] = useState<number>(0)
  const {appSettings} = useAppSettingsStore()
  const navigate = useNavigate()
  const [ogData, setOgData] = useState({
    title: '',
    description: '',
    url: '',
    image: '',
    siteName: '',
    type: 'event',
    street_address: '',
    start_time: '',
    end_time: '',
  })

  // GA PAGE VIEW
  useEffect(() => {
    initializeGA()
    trackPageView(window.location.pathname)
  }, [])

  useState(() => {
    // get event id
    setIsLoading(true)
    // if current event is not set
    if (eventId && eventId !== 'null') {
      getPublicEvent(eventId)
        .then((event) => {
          const pct = Math.ceil(
            (event.stats.bookingsCount / (event.stats.ticketsTotalQty || 1)) * 100
          )
          setBookingsPCT(pct)

          // set og data
          const og = {
            title: `skybookings.com | ${event.name}`,
            description: event.excerpt,
            url: `${appSettings.baseUrl}/events/${event.id}`,
            image: event.artwork,
            siteName: appSettings.name,
            type: 'event',
            street_address: '',
            start_time: event.startDate,
            end_time: event.endDate,
          }

          if (event.location) og.street_address = event.location
          setOgData(og)

          // RECORD GA EVENT
          if (event.id) {
            trackEvent(GA_EVENT_EVENT_VIEW_PAGE, {
              eventId: event.id,
              accountId: event.account,
            })
          }
        })

        .finally(() => {
          setIsLoading(false)
        })
    }

    // show header when scroll position is top

    const scrollHandler = () => {
      const header = document.querySelector('.header')
      const bannerHeight = document.querySelector('.artwork')?.clientHeight || 0
      const headerHeight = document.querySelector('.header')?.clientHeight || 0

      if (window.scrollY > bannerHeight - 300 - headerHeight) {
        header?.classList.add('d-block')
        header?.classList.remove('d-none')
      } else {
        header?.classList.add('d-none')
        header?.classList.remove('d-block')
      }
    }

    window.addEventListener('scroll', scrollHandler)

    return () => {
      window.removeEventListener('scroll', scrollHandler)
    }
  })

  return (
    <>
      {isLoading ? (
        <PageLoadingSpinner />
      ) : (
        <>
          <Helmet>
            <title>{ogData.title}</title>
            <meta name='description' content={ogData.description} />
            <link rel='canonical' href={ogData.url} />
            <meta property='og:title' content={ogData.title} />
            <meta property='og:description' content={ogData.description} />
            <meta property='og:url' content={ogData.url} />
            <meta property='og:image' content={ogData.image} />
            <meta property='og:site_name' content={ogData.siteName} />
            <meta property='og:street_address' content={ogData.street_address} />
            <meta property='og:type' content={ogData.type} />
            <meta property='og:start_time' content={ogData.start_time} />
            <meta property='og:end_time' content={ogData.end_time} />
            <meta name='twitter:card' content='summary_large_image' />
            <meta name='twitter:title' content={ogData.title} />
            <meta name='twitter:description' content={ogData.description} />
            <meta name='twitter:image' content={ogData.image} />
          </Helmet>

          {/* Container */}
          <div className='container d-flex flex-column'>
            {/* menu // header */}
            <div className='d-flex justify-content-between align-items-center my-10'>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/default.png')}
                className=' h-25px h-lg-25px app-sidebar-logo-default'
              />
              <div className='d-flex align-items-center justify-content-end'>
                <button
                  onClick={() => navigate('booking')}
                  disabled={publicEvent.canBook.canBook === false}
                  className='btn btn-danger btn-lg fw-bolder w-100'
                >
                  {publicEvent.canBook.canBook === false
                    ? publicEvent.canBook.code === 'eventFull'
                      ? 'Sold Out'
                      : 'Not Available'
                    : publicEvent.buttonLabel}
                </button>
              </div>
            </div>

            {/* MAIN BANNER */}
            <div className='d-flex artwork'>
              {!isMobileDevice() ? (
                <>
                  {publicEvent.banner && (
                    <img
                      src={publicEvent.banner}
                      alt={publicEvent.name}
                      className='w-100 rounded'
                    />
                  )}
                </>
              ) : (
                <img src={publicEvent.artwork} alt={publicEvent.name} className='w-100 rounded' />
              )}
            </div>

            {/* FIXED TOOLBAR */}
            {/* create fixed header, only visible when scroll position:top is 0 */}
            <div
              className={clsx(`header bg-black p-5 d-none`, {
                'fixed-bottom': isMobileDevice(),
                'fixed-top': !isMobileDevice(),
              })}
            >
              <div className='container'>
                <div className='d-flex justify-content-between align-items-center'>
                  <div className='d-flex align-items-center'>
                    <div className='d-flex flex-column justify-content-center mt-1'>
                      <h1 className='fs-7 fs-lg-3 mb-0 fw-bolder text-light pb-0 title'>
                        {publicEvent.name}
                      </h1>

                      {publicEvent.sellTickets && (
                        <div className=' text-muted mb-1 fs-7'>
                          Available from{' '}
                          {formatMoney(
                            publicEvent.stats.minTicketPrice || 0,
                            publicEvent.currency,
                            0
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='d-flex'>
                    <button
                      onClick={() => navigate('booking')}
                      disabled={publicEvent.canBook.canBook === false}
                      className='btn btn-danger btn-sm text-nowrap fw-bolder'
                    >
                      {publicEvent.canBook.canBook === false
                        ? publicEvent.canBook.code === 'eventFull'
                          ? 'Sold Out'
                          : 'Not Available'
                        : publicEvent.buttonLabel}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* content */}
            <div className='d-flex row'>
              <div className='col col-md-8 py-md-20 p-10'>
                {/* headline */}
                <h1 className='display-5 mb-0 display-lg-3 '>{publicEvent.name}</h1>
                <div className='d-flex my-2 fs-5 ps-2 mb-lg-10 align-items-center'>
                  <span>By {publicEvent.organiserName}</span>
                  <KTIcon iconName='verify' iconType='solid' className='ms-2 fs-3 text-warning' />
                </div>

                {/* tickets available from */}
                <div className='d-flex flex-wrap'>
                  {/* date */}
                  <div className='fs-5 me-4 mb-3 d-flex d-block-inline align-items-center'>
                    <KTIcon
                      iconName='calendar'
                      iconType='outline'
                      className='me-2 fs-4x text-dark'
                    />
                    <div className='d-flex flex-column fw-bold'>
                      <span
                        className='me-2 w-100'
                        dangerouslySetInnerHTML={{
                          __html: eventDateLabel(publicEvent.startDate, publicEvent.endDate),
                        }}
                      />
                      <span className='fw-normal'>{formatDateDMYHI(publicEvent.startDate)}</span>
                    </div>
                  </div>

                  <div className='fs-5 me-4 mb-3 d-flex align-items-center'>
                    <KTIcon
                      iconName='geolocation'
                      iconType='outline'
                      className='me-2 fs-4x text-dark'
                    />
                    <div className='d-flex flex-column'>
                      <span className='fw-bold '>Event Location:</span>
                      <span className='fw-normal '>{publicEvent.location}</span>
                    </div>
                  </div>
                </div>

                {/* Exceprt and availability */}
                <div className='d-flex flex-column event-excerpt my-3'>
                  <p className='fs-5 fw-semibold bg-light-warning p-3 rounded'>
                    {publicEvent.excerpt}
                  </p>
                </div>

                <div className='separator border-secondary mb-10'></div>

                <div className='d-flex flex-column event-content'>
                  {/* begin:  map */}
                  {publicEvent.location && (
                    <div className='map rounded mb-10'>
                      <MapViewer zoom={12} location={publicEvent.location} />
                    </div>
                  )}

                  {/* main content */}
                  <div
                    className='fs-4 event-content'
                    dangerouslySetInnerHTML={{__html: publicEvent.content}}
                  ></div>
                </div>

                {/* description  */}
              </div>
              <div className='col col-md-4 d-flex flex-column d-none d-md-block py-lg-20 p-10'>
                {publicEvent.artwork && (
                  <div className='mb-5'>
                    <img
                      src={publicEvent.artwork}
                      alt={publicEvent.name}
                      className='img-fluid rounded'
                    />
                  </div>
                )}

                <div className='d-flex flex-column'>
                  {/* booking button */}
                  <button
                    onClick={() => navigate('booking')}
                    disabled={publicEvent.canBook.canBook === false}
                    className='btn btn-danger btn-lg fw-bolder w-100 mb-2'
                  >
                    {publicEvent.canBook.canBook === false
                      ? publicEvent.canBook.code === 'eventFull'
                        ? 'Sold Out'
                        : 'Not Available'
                      : publicEvent.buttonLabel}
                  </button>

                  {publicEvent.sellTickets && (
                    <div className=' text-muted text-center mb-5 fs-7'>
                      Available from{' '}
                      {formatMoney(publicEvent.stats.minTicketPrice || 0, publicEvent.currency, 0)}
                    </div>
                  )}

                  {bookingsPCT > 80 && (
                    <>
                      <div className='d-flex flex-column text-white'>
                        <div className='d-flex justify-content-between fw-bold fs-6 opacity-75 w-100 mb-2'>
                          <span>{publicEvent.stats.bookingsCount} tickets booked</span>
                          <span>{bookingsPCT}%</span>
                        </div>

                        <div className='h-8pxw-100 bg-secondary bg-opacity-50 rounded'>
                          <div
                            className='bg-primary rounded h-8px'
                            role='progressbar'
                            style={{width: `${bookingsPCT}%`}}
                            aria-valuenow={50}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          ></div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* end::Row */}
        </>
      )}
    </>
  )
}

export {EventPublicPage}
