import {create, StateCreator} from 'zustand'
import {devtools} from 'zustand/middleware'
import {
  _cloneMessage,
  _deleteMessage,
  _getMessage,
  _getMessages,
  _patchMessage,
  _postMessage,
  _sendMessage,
  _sendTestMessage,
} from './messagesApi'
import {pick} from '../../../../_helpers/_helpers'

export type RecipientValueModel = {
  queryType: 'patronQuery' | 'bookingQuery'
  query: any
  number: number
}

export type MessageLogSummaryModel = {
  action: string
  patrons: number
  actions: number
}

export type MessageModel = {
  id?: string
  from?: string
  name: string
  account: string
  event?: string
  subject: string
  content: string
  shortContent?: string
  recipients: RecipientValueModel
  scheduledFor?: Date
  status: 'pending' | 'sent' | 'sending' | 'draft' | 'scheduled' | 'failed'
  channels: string[]
  excludePatrons?: string[]
  note: string
  previewContent?: string
  previewShortContent?: string
  dateCreated?: Date
  logs?: MessageLogSummaryModel[]
  dispatched?: number
}

export type Pagination = {
  account?: string
  status?: string
  limit: number
  page: number
  totalPages: number
  totalResults: number
}

export const initialMessage: MessageModel = {
  account: '',
  name: '',
  subject: '',
  content: '',
  recipients: {
    queryType: 'patronQuery',
    query: {},
    number: 0,
  },
  status: 'draft',
  channels: ['email'],
  excludePatrons: [],
  note: '',
}

export const initialPagination: Pagination = {
  limit: 15,
  page: 1,
  totalPages: 1,
  totalResults: 0,
}

type MessageStore = {
  messages: MessageModel[]
  currentMessage: MessageModel
  pagination: Pagination
  postMessage: (message: MessageModel) => Promise<MessageModel>
  setCurrentMessage: (message: MessageModel) => void
  unsetCurrentMessage: () => void
  patchMessage: (message: MessageModel) => Promise<MessageModel>
  deleteMessage: (messageId: string) => Promise<void>
  getMessage: (id: string) => Promise<MessageModel>
  getMessages: (query: Pagination) => Promise<MessageModel[]>
  sendMessage: (messageId: string) => Promise<void>
  sendTestMessage: (messageId: string, email?: string, phone?: string) => Promise<void>
  cloneMessage: (messageId: string) => Promise<MessageModel>
}

const createStore: StateCreator<MessageStore> = (set, get) => ({
  messages: [],
  currentMessage: initialMessage,
  pagination: initialPagination,

  setCurrentMessage: (message: MessageModel) => {
    set((state) => ({
      currentMessage: message,
    }))
  },

  unsetCurrentMessage: () => {
    set((state) => ({
      currentMessage: initialMessage,
    }))
  },

  postMessage: async (message: MessageModel) => {
    const payload = pick(message, [
      'from',
      'account',
      'event',
      'name',
      'subject',
      'content',
      'shortContent',
      'recipients',
      'status',
      'channels',
      'excludePatrons',
      'scheduledFor',
    ])

    if (!payload.event) delete payload.event

    const response = await _postMessage(payload)

    // convert recipients to object
    response.data.recipients = JSON.parse(response.data.recipients)

    set((state) => ({
      currentMessage: response.data,
      messages: [...state.messages, response.data],
    }))

    return response.data
  },

  patchMessage: async (message: MessageModel) => {
    const payload = pick(message, [
      'from',
      'name',
      'subject',
      'event',
      'content',
      'shortContent',
      'recipients',
      'status',
      'channels',
      'scheduledFor',
    ])
    const response = await _patchMessage(message.id, payload)

    // convert recipients to object
    response.data.recipients = JSON.parse(response.data.recipients)

    set((state) => ({
      currentMessage: response.data,
      messages: state.messages.map((m) => (m.id === response.data.id ? response.data : m)),
    }))

    return response.data
  },

  deleteMessage: async (messageId: string) => {
    await _deleteMessage(messageId)

    set((state) => ({
      messages: state.messages.filter((m) => m.id !== messageId),
    }))
  },

  getMessage: async (id: string) => {
    const response = await _getMessage(id)

    // convert recipients to object
    response.data.recipients = JSON.parse(response.data.recipients)

    set((state) => ({
      currentMessage: response.data,
    }))

    return response.data
  },

  getMessages: async (query: Pagination) => {
    const payload = pick(query, ['account', 'event', 'status', 'limit', 'page'])
    const response = await _getMessages(payload)

    // convert each recpient to object
    const newMessages = response.data.results.map((m) => {
      return {
        ...m,
        recipients: JSON.parse(m.recipients),
      }
    })

    // update pagination and messages
    set((state) => ({
      pagination: {
        ...state.pagination,
        ...query,
        totalPages: response.data.totalPages,
        totalResults: response.data.totalResults,
      },
      messages: newMessages,
    }))
    return newMessages
  },

  sendMessage: async (messageId: string) => {
    await _sendMessage(messageId)
  },

  sendTestMessage: async (messageId: string, email?: string, phone?: string) => {
    const payload = {
      phone,
      email,
    }
    await _sendTestMessage(messageId, payload)
  },

  cloneMessage: async (messageId: string) => {
    const response = await _cloneMessage(messageId)

    // convert recipients to object
    response.data.recipients = JSON.parse(response.data.recipients)

    set((state) => ({
      currentMessage: response.data,
      messages: [...state.messages, response.data],
    }))

    return response.data
  },
})

export const messageStore = create(devtools(createStore))
export const useMessageStore = messageStore
