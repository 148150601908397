import axios from 'axios'

export const MESSAGE_ENDPOINT = '/messages'

export const _postMessage = (message) => {
  return axios.post(MESSAGE_ENDPOINT, message)
}

export const _patchMessage = (messageId, message) => {
  return axios.patch(`${MESSAGE_ENDPOINT}/${messageId}`, message)
}

export const _deleteMessage = (messageId) => {
  return axios.delete(`${MESSAGE_ENDPOINT}/${messageId}`)
}

export const _getMessage = (messageId) => {
  return axios.get(`${MESSAGE_ENDPOINT}/${messageId}`)
}

export const _getMessages = (args) => {
  return axios.get(MESSAGE_ENDPOINT, {params: args})
}

export const _sendMessage = (messageId) => {
  return axios.post(`${MESSAGE_ENDPOINT}/${messageId}/send`)
}

export const _sendTestMessage = (messageId, payload) => {
  return axios.post(`${MESSAGE_ENDPOINT}/${messageId}/test`, payload)
}

export const _cloneMessage = (messageId) => {
  return axios.post(`${MESSAGE_ENDPOINT}/${messageId}/clone`)
}
