/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {BookingModel, useBookingStore} from './core/bookingsStore'
import {bookingStatusLabel} from '../../../_helpers/_templateHelpers'
import {daysUntilDate} from '../../../_helpers/_helpers'

type Props = {
  booking: BookingModel
}

const BookingItem: FC<Props> = ({booking}) => {
  const [badgeColor, setBadgeColor] = useState<string>('primary')
  const [statusLabel, setStatusLabel] = useState<string>('')
  const navigate = useNavigate()
  const {resetCurrentBooking} = useBookingStore()

  useEffect(() => {
    if (booking.status === 'pending') {
      setBadgeColor('warning')
    } else if (booking.status === 'complete') {
      setBadgeColor('success')
    } else if (booking.status === 'cancelled') {
      setBadgeColor('danger')
    }

    // set status label
    setStatusLabel(bookingStatusLabel(booking))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClick = () => {
    resetCurrentBooking()
    navigate(`/bookings/${booking.id}`)
  }

  return (
    <div
      onClick={handleClick}
      className='card border border-1 border-gray-300 border-hover h-100 cursor-pointer'
      style={{borderTop: `5px solid var(--bs-${badgeColor}) !important`}}
    >
      <div className='card-header border-0 '>
        <div className='card-title m-0'>
          <div
            style={{
              backgroundImage: `url(${booking.event.artwork})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundColor: 'black',
            }}
            className='w-50px h-50px symbol symbol-50px'
          />
          <div className='d-flex ms-3 flex-column'>
            <div className='fs-3 fw-bolder text-dark'>{booking.event.name}</div>
            {new Date(booking.event?.endDate ?? '') > new Date() ? (
              <div className='text-muted fs-5'>
                Starts in {daysUntilDate(new Date(booking.event?.startDate ?? ''), true)} days
              </div>
            ) : (
              <div className='text-muted fs-5'>
                {daysUntilDate(new Date(booking.event?.startDate ?? ''), true)} days ago
              </div>
            )}
          </div>
        </div>
      </div>

      <div className='card-body px-9'>
        <div className='fs-5 mt-1 mb-7' dangerouslySetInnerHTML={{__html: statusLabel}} />
      </div>
    </div>
  )
}

export {BookingItem}
