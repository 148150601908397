import {FC, useEffect, useState} from 'react'
import {MenuComponent} from '../../../../_metronic/assets/ts/components'
import {KTIcon} from '../../../../_metronic/helpers'
import clsx from 'clsx'
import {TicketModel, useEventsStore} from '../../events/core/eventsStore'
import {useBookingStore} from '../core/bookingsStore'
import Tippy from '@tippyjs/react'

type Props = {
  onFilter: (filter: any) => void // filter: {role, last_login}
  onReset?: () => void
  eventId: string | undefined
  isLoading?: boolean
}

const BookingsListFilter: FC<Props> = ({onReset, onFilter, isLoading, eventId}) => {
  const [isFiltered, setIsFiltered] = useState<boolean>(false)
  const [status, setStatus] = useState<string | undefined>()
  const [limit, setLimit] = useState<number | undefined>(25)
  const [ticket, setTicket] = useState<string | undefined>()
  const {getTickets} = useEventsStore()
  const [tickets, setTickets] = useState<TicketModel[]>([])
  const [loadingTickets, setLoadingTickets] = useState<boolean>(false)
  const {pagination} = useBookingStore()

  useEffect(() => {
    // get tickets to put in filter
    if (!eventId) return

    setLoadingTickets(true)
    getTickets(eventId, {limit: 100})
      .then((response) => {
        setTickets(response)
      })
      .finally(() => setLoadingTickets(false))

    // menu component reinit
    MenuComponent.reinitialization()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId])

  useEffect(() => {
    if (pagination.status || pagination.tickets) {
      if (pagination.status) setStatus(pagination.status)
      setIsFiltered(true)
    } else {
      setIsFiltered(false)
    }
  }, [pagination])

  const resetData = () => {
    if (onReset) onReset()
  }

  const filterData = () => {
    setIsFiltered(true)

    // do the same for limit
    onFilter({limit, status, tickets: ticket})
  }

  const changeStatus = (status: string) => {
    if (status) setStatus(status)
    if (!status) setStatus(undefined)
  }

  const changeTicket = (ticket: string) => {
    if (ticket) setTicket(ticket)
    if (!ticket) setTicket(undefined)
  }

  const changeLimit = (limit: number) => {
    setLimit(limit)
  }

  return (
    <>
      {/* begin::Filter Button */}
      <Tippy content='Filter Bookings' placement='top'>
        <button
          disabled={isLoading && isLoading}
          type='button'
          className={'btn btn-icon btn-sm btn-outline btn-active-secondary me-2'}
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
        >
          {loadingTickets ? (
            <span className='spinner-border spinner-border-sm'></span>
          ) : (
            <KTIcon
              iconName='filter'
              iconType={isFiltered ? 'solid' : 'outline'}
              className={clsx('fs-5 text-dark')}
            />
          )}
        </button>
      </Tippy>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          {/* begin::Input group */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Status:</label>
            {/* const bookingStatuses = ['pending', 'rejected', 'cancelled', 'complete']; */}
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='role'
              data-hide-search='true'
              onChange={(e) => changeStatus(e.target.value)}
              value={status}
            >
              <option value=''></option>
              <option value='complete'>Complete</option>
              <option value='pending'>Pending</option>
              <option value='rejected'>Rejected</option>
              <option value='cancelled'>Cancelled</option>
              <option value='abaondoned'>Abandoned</option>
            </select>
          </div>
          {/* end::Input group */}

          {/* tickets  */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Tickets:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='role'
              data-hide-search='true'
              onChange={(e) => {
                changeTicket(e.target.value)
              }}
              value={ticket}
            >
              <option value=''></option>
              {tickets.map((ticket) => (
                <option key={ticket.id} value={ticket.id}>
                  {ticket.name}
                </option>
              ))}
            </select>
          </div>

          {/* start:  Page Size Filtration */}
          <div className='mb-10'>
            <label className='form-label fs-6 fw-bold'>Page Size:</label>
            <select
              className='form-select form-select-solid fw-bolder'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              data-kt-user-table-filter='role'
              data-hide-search='true'
              onChange={(e) => {
                changeLimit(parseInt(e.target.value))
              }}
              value={limit}
            >
              <option value='10'>10</option>
              <option value='25'>25</option>
              <option value='50'>50</option>
              <option value='100'>100</option>
            </select>
          </div>

          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={resetData}
              className='btn btn-outline btn-sm btn-active-secondary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Reset
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={filterData}
              className='btn btn-sm btn-outline btn-active-dark fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Apply
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export {BookingsListFilter}
