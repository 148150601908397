import './styles.scss'

import Document from '@tiptap/extension-document'
import Mention from '@tiptap/extension-mention'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import StarterKit from '@tiptap/starter-kit'
import Link from '@tiptap/extension-link'
import PlaceHolder from '@tiptap/extension-placeholder'
import Image from '@tiptap/extension-image'
import {EditorProvider, mergeAttributes, useCurrentEditor} from '@tiptap/react'
import React, {FC} from 'react'

import suggestion from './suggestion'
import {Field} from 'formik'
import clsx from 'clsx'
import {Modal} from 'react-bootstrap'
import ImageInput from '../ImageInput'

type Props = {
  showSuggestions?: boolean
  showToolbar?: boolean
  suggestions?: string[]
  name: string
  className?: string
  placeholder?: string
  onChange?: (content: string) => void
}

// TIP TAP EDITOR
const TiptapEditor: FC<Props> = ({
  name,
  suggestions,
  showSuggestions = false,
  showToolbar = false,
  className,
  placeholder = 'Type here...',
  onChange,
}: Props) => {
  if (suggestions) {
    suggestion.items = ({query}) => {
      const r = suggestions
        .filter((item) => item.toLowerCase().startsWith(query.toLowerCase()))
        .slice(0, 5)

      return r
    }
  }

  const extensions = [
    Document,
    Paragraph,
    Text,
    StarterKit,
    Image.configure({
      HTMLAttributes: {
        style: 'width: 100%;height:auto;',
      },
    }),
    PlaceHolder.configure({
      placeholder,
    }),
    Mention.configure({
      renderHTML({options, node}) {
        return [
          'span',
          mergeAttributes(options.HTMLAttributes),
          `{{${node.attrs.label ?? node.attrs.id}}}`,
        ]
      },
      renderText({options, node}) {
        return `{{${node.attrs.label ?? node.attrs.id}}}`
      },
      HTMLAttributes: {
        class: 'mention',
      },
      suggestion,
    }),
    Link.configure({
      openOnClick: false,
    }),
  ]

  return (
    <Field>
      {({field, form}) => (
        <EditorProvider
          extensions={extensions}
          content={
            form.values[name] && form.values[name].replace(/&lt;/g, '<').replace(/&gt;/g, '>')
          }
          editorProps={{
            attributes: {
              class: `${className}`,
            },
          }}
          onUpdate={({editor}) => {
            form.setFieldValue(name, editor.getHTML())
            onChange && onChange(editor.getHTML())
          }}
          slotBefore={showToolbar && <MenuBar />}
          slotAfter={
            showSuggestions && (
              <FooterSuggestions
                field={field}
                form={form}
                showSuggestions={showSuggestions}
                suggestions={suggestions}
              />
            )
          }
        ></EditorProvider>
      )}
    </Field>
  )
}

const FooterSuggestions = ({field, form, suggestions, showSuggestions}) => {
  const {editor} = useCurrentEditor()
  const handleInsertMention = (suggestion) => {
    if (!editor) return
    editor
      .chain()
      .focus()
      .insertContent(
        `<span data-type="mention" class='mention' data-id="${suggestion}">{{${suggestion}}}</span>`
      )
      .run()
  }
  return (
    <div className='mt-3 overflow-x-auto d-flex'>
      {showSuggestions &&
        suggestions?.map((suggestion, index) => (
          <span
            onClick={() => handleInsertMention(suggestion)}
            className='badge d-flex flex-wrap me-2 mb-2 badge-light bg-hover-secondary cursor-pointer'
            key={index}
          >
            @{suggestion}
          </span>
        ))}
    </div>
  )
}

const MenuBar = () => {
  const {editor} = useCurrentEditor()
  const [showImageInput, setShowImageInput] = React.useState(false)
  const [size, setSize] = React.useState('1-1')

  if (!editor) {
    return null
  }

  const setLink = () => {
    const url = prompt('Enter the URL')

    if (url) {
      editor.chain().focus().extendMarkRange('link').setLink({href: url}).run()
    }
  }

  const unsetLink = () => {
    editor.chain().focus().extendMarkRange('link').unsetLink().run()
  }

  const handleImageSave = (imageUrl: string) => {
    editor.chain().focus().setImage({src: imageUrl}).run()
    setShowImageInput(false)
  }

  return (
    <>
      <div className='control-group'>
        <div className='button-group d-flex overflow-x-auto'>
          <button
            onClick={() => editor.chain().focus().toggleBold().run()}
            disabled={!editor.can().chain().focus().toggleBold().run()}
            className={clsx('btn btn-sm py-1 px-2 btn-light me-2 mb-2 text-nowrap', {
              'btn-secondary fw-bold': editor.isActive('bold'),
            })}
          >
            Bold
          </button>
          <button
            onClick={() => editor.chain().focus().toggleItalic().run()}
            disabled={!editor.can().chain().focus().toggleItalic().run()}
            className={clsx('btn btn-sm py-1 px-2 btn-light me-2 mb-2 text-nowrap', {
              'btn-secondary fw-bold': editor.isActive('italic'),
            })}
          >
            Italic
          </button>
          <button
            onClick={() => editor.chain().focus().toggleStrike().run()}
            disabled={!editor.can().chain().focus().toggleStrike().run()}
            className={clsx('btn btn-sm py-1 px-2 btn-light me-2 mb-2 text-nowrap', {
              'btn-secondary fw-bold': editor.isActive('strike'),
            })}
          >
            Strike
          </button>
          <button
            onClick={setLink}
            className={clsx('btn btn-sm py-1 px-2 btn-light me-2 mb-2 text-nowrap', {
              'btn-secondary fw-bold': editor.isActive('link'),
            })}
          >
            Link
          </button>
          <button
            onClick={unsetLink}
            className={clsx('btn btn-sm py-1 px-2 btn-light me-2 mb-2 text-nowrap', {
              'btn-secondary fw-bold': editor.isActive('link'),
            })}
          >
            Clear Link
          </button>
          <button
            onClick={() => setShowImageInput(true)}
            className='btn btn-sm py-1 px-2 btn-light me-2 mb-2 text-nowrap'
          >
            Add Image
          </button>
          <button
            onClick={() => editor.chain().focus().setParagraph().run()}
            className={clsx('btn btn-sm py-1 px-2 btn-light me-2 mb-2 text-nowrap', {
              'btn-secondary fw-bold': editor.isActive('paragraph'),
            })}
          >
            Paragraph
          </button>
          <button
            onClick={() => editor.chain().focus().toggleHeading({level: 1}).run()}
            className={clsx('btn btn-sm py-1 px-2 btn-light me-2 mb-2 text-nowrap', {
              'btn-secondary fw-bold': editor.isActive('heading'),
            })}
          >
            H1
          </button>
          <button
            onClick={() => editor.chain().focus().toggleHeading({level: 2}).run()}
            className={clsx('btn btn-sm py-1 px-2 btn-light me-2 mb-2 text-nowrap', {
              'btn-secondary fw-bold': editor.isActive('heading', {level: 2}),
            })}
          >
            H2
          </button>
          <button
            onClick={() => editor.chain().focus().toggleHeading({level: 3}).run()}
            className={clsx('btn btn-sm py-1 px-2 btn-light me-2 mb-2 text-nowrap', {
              'btn-secondary fw-bold': editor.isActive('heading', {level: 3}),
            })}
          >
            H3
          </button>

          <button
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            className={clsx('btn btn-sm py-1 px-2 btn-light me-2 mb-2 text-nowrap', {
              'btn-secondary fw-bold': editor.isActive('bulletList'),
            })}
          >
            Bullet list
          </button>
          <button
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            className={clsx('btn btn-sm py-1 px-2 btn-light me-2 mb-2 text-nowrap', {
              'btn-secondary fw-bold': editor.isActive('orderedList'),
            })}
          >
            Numbered list
          </button>

          <button
            onClick={() => editor.chain().focus().toggleBlockquote().run()}
            className={clsx('btn btn-sm py-1 px-2 btn-light me-2 mb-2 text-nowrap', {
              'btn-secondary fw-bold': editor.isActive('blockquote'),
            })}
          >
            Blockquote
          </button>
          <button
            onClick={() => editor.chain().focus().setHorizontalRule().run()}
            className={clsx('btn btn-sm py-1 px-2 btn-light me-2 mb-2 text-nowrap', {
              'btn-secondary fw-bold': editor.isActive('horizontalRule'),
            })}
          >
            Horizontal rule
          </button>

          <button
            onClick={() => editor.chain().focus().undo().run()}
            disabled={!editor.can().chain().focus().undo().run()}
            className={clsx('btn btn-sm py-1 px-2 btn-light me-2 mb-2 text-nowrap', {
              'btn-secondary fw-bold': editor.isActive('undo'),
            })}
          >
            Undo
          </button>
          <button
            onClick={() => editor.chain().focus().redo().run()}
            disabled={!editor.can().chain().focus().redo().run()}
            className={clsx('btn btn-sm py-1 px-2 btn-light me-2 mb-2 text-nowrap', {
              'btn-secondary fw-bold': editor.isActive('redo'),
            })}
          >
            Redo
          </button>
        </div>
      </div>
      <Modal show={showImageInput} centered onHide={() => setShowImageInput(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Insert Image</Modal.Title>
        </Modal.Header>
        <Modal.Body className='w-100'>
          {/* nav pills to switch sizes:  1:1 4:5 16:9 5:4 9:16*/}
          <div className='d-flex justify-content-center w-100'>
            <div className='nav nav-pills nav-sm nav-centered mb-5'>
              <button
                onClick={() => setSize('1-1')}
                className={clsx('btn btn-sm btn-outline me-2', {
                  'btn-dark': size === '1-1',
                })}
                data-bs-toggle='pill'
                data-bs-target='#1-1'
              >
                1:1
              </button>
              <button
                onClick={() => setSize('4-5')}
                className={clsx('btn btn-sm btn-outline me-2', {
                  'btn-dark': size === '4-5',
                })}
                data-bs-toggle='pill'
                data-bs-target='#4-5'
              >
                4:5
              </button>
              <button
                onClick={() => setSize('16-9')}
                className={clsx('btn btn-sm btn-outline me-2', {
                  'btn-dark': size === '16-9',
                })}
                data-bs-toggle='pill'
                data-bs-target='#16-9'
              >
                16:9
              </button>
              <button
                onClick={() => setSize('5-4')}
                className={clsx('btn btn-sm btn-outline me-2', {
                  'btn-dark': size === '5-4',
                })}
                data-bs-toggle='pill'
                data-bs-target='#5-4'
              >
                5:4
              </button>
              <button
                onClick={() => setSize('9-16')}
                className={clsx('btn btn-sm btn-outline me-2', {
                  'btn-dark': size === '9-16',
                })}
                data-bs-toggle='pill'
                data-bs-target='#9-16'
              >
                9:16
              </button>
            </div>
          </div>

          {/* tab panes for each size */}
          <div className='tab-content'>
            <div
              className={clsx('tab-pane', {
                'show active': size === '1-1',
              })}
              id='1-1'
            >
              <ImageInput aspectRatio={1} onImageSave={handleImageSave} />
            </div>
            <div
              className={clsx('tab-pane', {
                'show active': size === '4-5',
              })}
              id='4-5'
            >
              <ImageInput aspectRatio={4 / 5} onImageSave={handleImageSave} />
            </div>
            <div
              className={clsx('tab-pane', {
                'show active': size === '16-9',
              })}
              id='16-9'
            >
              <ImageInput aspectRatio={16 / 9} onImageSave={handleImageSave} />
            </div>
            <div
              className={clsx('tab-pane', {
                'show active': size === '5-4',
              })}
              id='5-4'
            >
              <ImageInput aspectRatio={5 / 4} onImageSave={handleImageSave} />
            </div>
            <div
              className={clsx('tab-pane', {
                'show active': size === '9-16',
              })}
              id='9-16'
            >
              <ImageInput aspectRatio={9 / 16} onImageSave={handleImageSave} />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default TiptapEditor
