import {Field} from 'formik'
import React from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const PhoneField = (props: any) => {
  const {name, required, ...rest} = props

  const handleError = (value) => {
    if (props.onError) {
      props.onError(value)
    }
  }
  return (
    <Field name={name}>
      {({field, form, meta}: any) => (
        <PhoneInput
          country={'za'}
          containerClass=''
          specialLabel=''
          defaultMask='.. ... ....'
          autoFormat={true}
          {...rest}
          countryCodeEditable={false}
          onChange={(value, country, e, formattedValue) => {
            // check if 3rd character is 0.  if so, set the field error to 'Invalid phone number'
            let msg = ''
            if (value.charAt(2) === '0') {
              msg = 'Please skip the 0 after the country code.  Enter number as +27 82 123 456'
              form.setFieldError(field.name, msg)
              handleError(msg)
            } else if (value.length > 11) {
              msg = 'There are too many numbers in the phone number'
              form.setFieldError(field.name, msg)
              handleError(msg)
            } else {
              form.setFieldValue(field.name, value)
              form.setFieldError(field.name, undefined)
              handleError(undefined)
            }

            if (props.onChange) {
              props.onChange(value, country, e, formattedValue)
            }
          }}
          onBlur={(e) => {
            // remove zeros from target value
            const value = e.target.value.replace(/[^0-9]/g, '')

            if (value.charAt(2) === '0') {
              form.setFieldError(
                field.name,
                'Please skip the 0 after the country code.  Enter number as +27 82 123 456'
              )
            } else if (e.target.value.length <= 5 && !required) {
              return
            } else if (value.length !== 11) {
              form.setFieldError(field.name, 'Invalid Phone Number.')
            }
          }}
          value={field.value.toString()}
          inputProps={{
            required: true,
            autoFocus: true,
            className: 'w-100 form-control form-control-lg',
          }}
        />
      )}
    </Field>
  )
}

export default PhoneField
