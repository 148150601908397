import {useEffect, useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {BookingsTable} from './BookingsTable'
import {useEventsStore} from '../../events/core/eventsStore'
import {BookingsPagination, initialBookingsPagination, useBookingStore} from '../core/bookingsStore'
import {useParams} from 'react-router-dom'
import {BookingsListFilter} from './BookingsListFilter'
import toast, {Toaster} from 'react-hot-toast'
import {PaginationControl} from 'react-bootstrap-pagination-control'
import {PageLoadingSpinner} from '../../../../_components/PageLoadingSpinner'
import {ManualBookingButton} from './ManualBookingButton'
import {useClientStore} from '../../clients/core/clientStore'
import {BreadcrumbModel, useAppSettingsStore} from '../../settings/core/appSettingsStore'
import {capitaliseFirstLetter, trimCharacters} from '../../../../_helpers/_helpers'
import {isMobileDevice} from '../../../../_metronic/assets/ts/_utils'
import clsx from 'clsx'
import Tippy from '@tippyjs/react'
import {BookingsListPatronSearch} from './BookingsListPatronSearch'
import {PatronModel} from '../../patrons/core/patronStore'

export function BookingsList() {
  const {resetCurrentBooking, getBookings, exportBookings, bookings, pagination} = useBookingStore()
  const [bookingsLoading, setBookingsLoading] = useState<boolean>(true)
  const [bookingsChecked, setBookingsChecked] = useState<boolean>(false)
  const [exporting, setExporting] = useState<boolean>(false)
  const [eventLoading, setEventLoading] = useState<boolean>(true)
  const {eventId} = useParams<{eventId: string}>()
  const {currentEvent, getEvent} = useEventsStore()
  const {isClientLoggedIn} = useClientStore()
  const {setBreadcrumbs, setCurrentPage} = useAppSettingsStore()

  useEffect(() => {
    // remove current booking
    resetCurrentBooking()

    // GET THE CURRENT EVENT
    if (currentEvent === undefined && eventId) {
      setEventLoading(true)
      getEvent(eventId).finally(() => {
        setEventLoading(false)
      })
    }

    // GET THE BOOKINGS
    setBookingsLoading(true)
    setBookingsChecked(false)

    const query: Partial<BookingsPagination> = {
      page: 1,
      event: eventId,
    }

    getBookings(query)
      .catch((error) => {
        toast.error('There was a problem fetching bookings')
      })
      .finally(() => {
        setBookingsLoading(false)
        setBookingsChecked(true)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEvent])

  // Setup Breadcrumbs
  useEffect(() => {
    // check if event exists

    const crumbs: BreadcrumbModel[] = [
      {
        object: 'Events',
        title: 'All',
        link: '/events',
      },
      {
        object: `Event`,
        title: `${trimCharacters(currentEvent.name, 20)}`,
        link: `/events/${eventId}`,
      },
      {
        object: 'Bookings',
        title: `Bookings`,
        link: `/events/${eventId}/bookings`,
      },
    ]
    setBreadcrumbs(crumbs)
    setCurrentPage('Bookings')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookings, eventLoading])

  const handleReset = () => {
    setBookingsLoading(true)

    // reset the pagination
    getBookings({...initialBookingsPagination, event: eventId})
      .catch((error) => {
        toast.error('There was a problem fetching bookings')
      })
      .finally(() => {
        setBookingsLoading(false)
      })
  }

  const handleFilter = (filter: Partial<BookingsPagination>) => {
    const payload = {...pagination, ...filter, page: 1}
    if (filter.status && filter.status === pagination.status) {
      delete payload.status
    }

    setBookingsLoading(true)

    getBookings(payload)
      .catch((error) => {
        toast.error('There was a problem fetching bookings')
      })

      .finally(() => {
        setBookingsLoading(false)
      })
  }

  const handlePaginationChange = (page: number) => {
    setBookingsLoading(true)
    getBookings({...pagination, page}).finally(() => {
      setBookingsLoading(false)
    })
  }

  const handleExport = async () => {
    try {
      // export bookings
      setExporting(true)
      const exportUrl = await exportBookings(pagination)

      if (exportUrl) {
        // exportUrl is a link to a file that can be downloaded (in an S3 bucket).
        // make a request to the exportUrl to download the file
        window.open(exportUrl)
      } else {
        toast.error('There was a problem exporting bookings')
      }
    } catch (e) {
      toast.error('There was a problem exporting bookings')
    }
    setExporting(false)
  }

  const handlePatronSearch = (patron: PatronModel) => {
    setBookingsLoading(true)
    getBookings({...pagination, page: 1, patron: patron.id!, event: eventId})
      .catch((error) => {
        toast.error('There was a problem fetching bookings')
      })
      .finally(() => {
        setBookingsLoading(false)
      })
  }

  return (
    <>
      <Toaster />

      {/* create overlay that when bookingsLoading  */}
      {bookingsLoading && <PageLoadingSpinner />}
      {/* end::Overlay */}

      <div className='row'>
        <div className='d-flex flex-column'>
          <div className='d-flex justify-content-between mb-10'>
            {/* COUNT */}
            <div className='d-flex flex-column'>
              <span className='fs-5 fw-bold'>
                {pagination.totalResults} {capitaliseFirstLetter(pagination.status ?? '')} Bookings
              </span>
              <span className='fs-4 text-muted'>{`Viewing ${bookings.length} on page ${pagination.page} of ${pagination.totalPages}`}</span>
            </div>

            {/* FILTER BY STATUS */}
            {!isMobileDevice() && (
              <div className='d-flex align-items-center'>
                <ul className='nav nav-light nav-bold nav-pills p-1 bg-light rounded border border-secondary'>
                  <li className='nav-item '>
                    <button
                      className={clsx('btn btn-sm', {
                        'btn-success': pagination.status === 'complete',
                        'btn-icon': pagination.status !== 'complete',
                      })}
                      onClick={() => handleFilter({status: 'complete'})}
                    >
                      <span className='rounded-circle rounded  w-20px h-20px bg-success'></span>
                      {pagination.status === 'complete' && 'complete'}
                    </button>
                  </li>
                  <li className='nav-item'>
                    <button
                      className={clsx('btn btn-sm', {
                        'btn-warning': pagination.status === 'pending',
                        'btn-icon': pagination.status !== 'pending',
                      })}
                      onClick={() => handleFilter({status: 'pending'})}
                    >
                      <span className='rounded-circle rounded  w-20px h-20px bg-warning'></span>
                      {pagination.status === 'pending' && 'pending'}
                    </button>
                  </li>
                  <li className='nav-item'>
                    <button
                      className={clsx('btn btn-sm', {
                        'btn-danger': pagination.status === 'cancelled',
                        'btn-icon': pagination.status !== 'cancelled',
                      })}
                      onClick={() => handleFilter({status: 'cancelled'})}
                    >
                      <span className='rounded-circle rounded  w-20px h-20px bg-danger'></span>
                      {pagination.status === 'cancelled' && 'cancelled'}
                    </button>
                  </li>
                  <li className='nav-item'>
                    <button
                      className={clsx('btn btn-sm', {
                        'btn-secondary': pagination.status === 'abandoned',
                        'btn-icon': pagination.status !== 'abandoned',
                      })}
                      onClick={() => handleFilter({status: 'abandoned'})}
                    >
                      <span className='rounded-circle rounded  w-20px h-20px bg-secondary'></span>
                      {pagination.status === 'abandoned' && 'abandoned'}
                    </button>
                  </li>
                </ul>
              </div>
            )}

            {/* ACTION BUTTONS */}
            <div className='d-flex flex-rows align-items-center'>
              {/* add booking */}
              {!isClientLoggedIn && <ManualBookingButton />}

              {/* search */}
              <BookingsListPatronSearch onSearch={handlePatronSearch} />

              {/* bookings filter */}
              <BookingsListFilter
                eventId={eventId}
                onFilter={handleFilter}
                onReset={handleReset}
                isLoading={bookingsLoading}
              />

              {/* Export button */}
              <Tippy content='Export Bookings' placement='top'>
                <button
                  className='btn btn-icon btn-sm btn-outline btn-active-secondary me-2'
                  onClick={handleExport}
                >
                  {exporting ? (
                    <span className='spinner-border spinner-border-sm'></span>
                  ) : (
                    <KTIcon
                      iconName='cloud-download'
                      className='fs-3 text-dark'
                      iconType='outline'
                    />
                  )}
                </button>
              </Tippy>
            </div>
          </div>
        </div>
      </div>

      {/* START::  SHOW BOOKINGS */}
      {bookings.length > 0 && (
        <>
          <BookingsTable />
          {/* pagination */}
          {bookings.length < pagination.totalResults && (
            <div className='my-10'>
              <PaginationControl
                page={pagination.page}
                total={pagination.totalResults}
                limit={pagination.limit}
                changePage={handlePaginationChange}
              />
            </div>
          )}
        </>
      )}
      {/* END:  SHOW BOOKINGS*/}

      {/* START:: SHOW THE  NO BOOKINGS MESSAGE */}
      {bookingsChecked === true && bookings.length === 0 && (
        <div className='min-h-250px d-flex flex-grow-1 border-1 justify-content-center border-dashed rounded bg-light'>
          <div className='d-flex justify-content-center align-items-center flex-column'>
            <div className='d-flex'>
              <KTIcon
                className='svg-icon-3x svg-icon-primary mb-5 '
                iconName='flaticon-safebox-1'
              />
              <span className='fs-2x fw-bolder text-dark'>Oh no. You don't have bookings yet.</span>
            </div>
            <div className='fs-5 text-muted text-center'>
              You can create bookings manually or promote your event on social media or your
              community.
            </div>
            <div className='d-flex flex-row p-5 '>
              <ManualBookingButton />
              {/* <button
                onClick={() => navigate('/bookings/create')}
                className='btn btn-primary btn-lg'
              >
                Create Booking
              </button> */}
            </div>
          </div>
        </div>
      )}
      {/* END:  SHOW THE NO BOOKINGS MESSAGE */}
    </>
  )
}
