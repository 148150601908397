/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {Link, useLocation, useParams} from 'react-router-dom'
import {useBookingStore} from '../core/bookingsStore'
import {KTIcon} from '../../../../_metronic/helpers'
import {formatNumber, getCurrencySymbol, loadScript} from '../../../../_helpers/_helpers'
import {bookingStatusLabel} from '../../../../_helpers/_templateHelpers'
import clsx from 'clsx'
import {PageLoadingSpinner} from '../../../../_components/PageLoadingSpinner'
import {PatronModel, usePatronStore} from '../../patrons/core/patronStore'
import {BookingStatusSwitcher} from '../../events/EventEditorPartials/BookingStatusSwitcher'
import {initialTransaction, useTransactionStore} from '../../transactions/core/transactionsStore'
import toast, {Toaster} from 'react-hot-toast'
import {useAppSettingsStore} from '../../settings/core/appSettingsStore'
import {Modal} from 'react-bootstrap'
import {useClientStore} from '../../clients/core/clientStore'
import Swal from 'sweetalert2'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import PhoneField from '../../../../_components/PhoneField'
import * as Yup from 'yup'

// create validation for patron form (name, email, phone)
const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
})

const BookingHeader: React.FC = () => {
  const location = useLocation()

  const [statusLabel, setStatusLabel] = useState<string>('')
  const [showPayment, setShowPayment] = useState<boolean>(false)
  const [showLoading, setShowLoading] = useState<boolean>(false)
  const [isPaymentLoading, setIsPaymentLoading] = useState<boolean>(false)
  const [isStatusLoading, setIsStatusLoading] = useState<boolean>(false)
  const {currentBooking, saveBooking, updateCurrentBooking} = useBookingStore()
  const {transactions, postTransaction} = useTransactionStore()
  const {isPatronLoggedIn} = usePatronStore()
  const {isClientLoggedIn} = useClientStore()
  const {bookingId} = useParams<{bookingId: string}>()
  const {appSettings} = useAppSettingsStore()
  const {patchPatron} = usePatronStore()

  const [showPatronModal, setShowPatronModal] = useState<boolean>(false)
  // paystack

  useEffect(() => {
    if (!bookingId) return

    if (currentBooking) {
      // set status label
      setStatusLabel(bookingStatusLabel(currentBooking))

      // set show payment
      if (
        currentBooking.event?.sellTickets &&
        currentBooking.stats.ticketsValue > currentBooking.stats.transactionsValue &&
        currentBooking.status === 'pending'
      ) {
        setShowPayment(true)
      } else {
        setShowPayment(false)
      }
    }
    // eslint-disable-next-line
  }, [currentBooking.stats, currentBooking.bookingTickets, transactions])

  // when transaction is updated
  const handleInitializePayment = async () => {
    setShowLoading(true)
    setIsPaymentLoading(true)

    try {
      const transactionPayload: any = {
        ...initialTransaction,
        patron: currentBooking.patron.id,
        booking: currentBooking.id,
        event: currentBooking.event.id,
        status: 'pending',
        type: 'payment',
        amount: currentBooking.stats.ticketsValue,
        description: 'Booking Payment: ' + currentBooking.id,
        gateway: 'paystack',
        gatewayTransactionId: currentBooking.id,
        reconciled: false,
        account: currentBooking.event.account,
        limitToEvent: false,
      }

      const transaction = await postTransaction(transactionPayload)

      // launch paystack payment modal

      const paymentProps = {
        key: appSettings.paystackPublicKey,
        email: currentBooking.patron.email
          ? currentBooking.patron.email
          : `${currentBooking.patron.phone}@skybookings.com`,
        currency: currentBooking.event.currency ?? 'ZAR',
        text: 'Pay Now',
        ref: transaction.id,
        amount: transaction.amount * 100,
        callback: () => handlePaymentDone(),
      }

      // load paystack popup
      await loadScript('https://js.paystack.co/v1/inline.js')
      const handler = window.PaystackPop.setup(paymentProps)
      handler.openIframe()
    } catch (err) {
      console.error(err)
    }

    setShowLoading(false)
    setIsPaymentLoading(false)
  }

  const handlePaymentDone = async () => {
    // show swwal
    Swal.fire({
      title: 'Payment Successful',
      text: 'Please give it a few seconds before the payment is confirmed.',
      icon: 'success',
      showCancelButton: false,
      showConfirmButton: true,
      confirmButtonText: 'Ok',
    }).then(() => {
      updateCurrentBooking({status: 'complete'})
      setShowPayment(false)
    })

    setShowPayment(false)
  }

  const handleSwitchStatus = (status: string) => {
    if (!currentBooking.id) return

    setIsStatusLoading(true)
    saveBooking({...currentBooking, status}).finally(() => {
      setIsStatusLoading(false)
    })
  }

  const handleSubmitPatron = async (values: PatronModel) => {
    // handle form submission
    if (!currentBooking.id) return

    patchPatron({...values, id: currentBooking.patron.id})
      .finally(() => {
        setShowPatronModal(false)
      })
      .catch((err) => {
        toast.error('An error occurred while updating patron details')
      })
  }

  return (
    <>
      {showLoading && <PageLoadingSpinner />}
      <Toaster />
      <div className='card mb-5 mb-xl-10 border border-secondary border-1'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            {/* event image */}
            <div className='d-flex me-7 mb-4'>
              <div
                style={{
                  backgroundImage: `url(${currentBooking.event?.artwork})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundColor: 'black',
                }}
                className='w-50px w-lg-150px h-50px h-lg-150px symbol symbol-50px symbol-lg-150px'
              />
              <div className='d-flex align-items-center mb-2 d-block d-sm-none ms-5'>
                <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                  Booking for {currentBooking.event.name}
                </a>
              </div>
            </div>
            {/* end event image */}

            {/* booking title */}
            <div className='d-flex flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2 d-none d-sm-block'>
                    <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                      Booking for {currentBooking.event.name}
                    </a>
                  </div>

                  {/* start: indicators */}
                  <div className='d-flex flex-wrap  fs-6 mb-4 pe-2'>
                    <button
                      type='button'
                      onClick={() => setShowPatronModal(true)}
                      className='btn btn-sm btn-link d-flex p-0 align-items-start flex-grow-1 text-dark me-5 mb-2'
                    >
                      <KTIcon iconName='profile-circle' iconType='outline' className='fs-4 me-1' />
                      <span className='text-capitalize'>
                        {isPatronLoggedIn
                          ? `organised by ${currentBooking.event.organiserName}`
                          : `booked by ${currentBooking.patron.name}`}
                      </span>
                    </button>
                    <a href='#' className='d-flex flex-grow-1 text-dark me-5 mb-2'>
                      <KTIcon iconName='geolocation' iconType='outline' className='fs-4 me-1' />
                      {currentBooking.event.location}
                    </a>
                  </div>

                  <div className='d-flex flex-wrap w-100'>
                    {/* start:: ticket sales */}
                    {currentBooking.event.sellTickets && (
                      <div className='border border-gray-300 flex-grow border-dashed rounded min-w-125px py-3 px-4 mb-3'>
                        <div className='d-flex align-items-start'>
                          <div className='fs-2 fw-bolder'>
                            {getCurrencySymbol(currentBooking.event.currency || 'ZAR')}{' '}
                            {formatNumber(currentBooking.value, 0)}
                          </div>
                        </div>
                        <div className='fs-7 fw-bold text-gray-600'>Booking Value</div>
                      </div>
                    )}
                    {/* end tickets */}

                    {/* start payment button */}
                    {showPayment && (
                      <div className='border border-info justify-content-center flex-grow-1 border-dashed bg-light-info bg-active-info rounded min-w-125px py-3 px-4 ms-3 me-lg-3 mb-3'>
                        <div className='d-flex justify-content-center'>
                          <KTIcon
                            iconName='tag'
                            iconType='outline'
                            className='fs-1 text-info  me-2'
                          />
                          <div className='fs-2 fw-bolder'>
                            {getCurrencySymbol(currentBooking.event.currency || 'ZAR')}{' '}
                            {formatNumber(
                              currentBooking.value - currentBooking.stats.transactionsValue,
                              0
                            )}
                          </div>
                        </div>
                        <div className='fs-7 fw-bold text-info text-center '>
                          {isPaymentLoading && (
                            <span
                              className='spinner-border spinner-border-sm me-2'
                              role='status'
                              aria-hidden='true'
                            ></span>
                          )}
                          <button
                            className='btn btn-link fw-bold text-info py-0 fs-7'
                            onClick={() => handleInitializePayment()}
                          >
                            Pay Balance
                          </button>
                        </div>
                      </div>
                    )}

                    {/* copy tickets link */}

                    {/* end payment button */}

                    {/* start:: number of tickets */}
                    <div
                      className={clsx(
                        'border border-gray-300 flex-grow-1 border-dashed rounded min-w-125px py-3 px-4 mb-3',
                        {
                          'ms-2': !showPayment,
                        }
                      )}
                    >
                      <Link to='../tickets' className='d-flex align-items-center'>
                        <KTIcon
                          iconName='barcode'
                          iconType='outline'
                          className='fs-1 text-primary me-2'
                        />
                        <div className='fs-2 fw-bolder'>
                          {currentBooking &&
                            currentBooking.stats &&
                            currentBooking.stats.ticketsCount}
                        </div>
                      </Link>
                      <div className='fs-7 fw-bold text-gray-600 '>
                        {currentBooking.stats.ticketsCount === 1
                          ? 'Ticket Booked'
                          : 'Tickets Booked'}
                      </div>
                    </div>
                    {/* end number of tickets */}
                  </div>
                  {/* end indicators */}
                </div>
              </div>
            </div>
            {/* end booking title */}

            {/* booking tools (status switcher etc.) */}
            <div className='d-flex flex-column'>
              <div className='d-flex flex-lg-row align-items-start justify-content-end my-4'>
                {/* start:: View event */}
                <Link
                  to={`/event/${currentBooking.event.id}`}
                  className='btn btn-sm btn-primary me-3 d-none d-block'
                >
                  View Event
                </Link>
                {/* end:: View event */}

                {/* status button */}
                {!isPatronLoggedIn && !isClientLoggedIn && (
                  <BookingStatusSwitcher
                    onSwitchStatus={handleSwitchStatus}
                    isLoading={isStatusLoading}
                    // ['pending', 'rejected', 'cancelled', 'complete'];
                    toggleBtnClass={clsx(
                      'btn-sm',
                      {'btn-success': currentBooking.status === 'complete'},
                      {'btn-warning': currentBooking.status === 'pending'},
                      {'btn-info': currentBooking.status === 'rejected'},
                      {'btn-danger': currentBooking.status === 'cancelled'},
                      {'btn-secondary': currentBooking.status === 'abandoned'}
                    )}
                    toggleBtnLabel={`status: ${currentBooking.status}`}
                  />
                )}
              </div>
              <div className='d-flex d-none d-sm-block justify-content-end fw-bold text-muted w-100 text-end'>
                <div dangerouslySetInnerHTML={{__html: statusLabel}} />
              </div>
            </div>

            {/* end booking info */}
          </div>

          {/* booking nav */}
          <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === `/bookings/${bookingId}/tickets` && 'active')
                  }
                  to={`/bookings/${bookingId}/tickets`}
                >
                  Tickets
                </Link>
              </li>
              {currentBooking.event?.sellTickets && (
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (location.pathname === `/bookings/${bookingId}/transactions` && 'active')
                    }
                    to={`/bookings/${bookingId}/transactions`}
                  >
                    Payments
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>

      <Formik
        enableReinitialize
        initialValues={currentBooking.patron as PatronModel}
        onSubmit={handleSubmitPatron}
        validationSchema={validationSchema}
      >
        {(form) => (
          <Form>
            <Modal
              show={showPatronModal}
              onHide={() => setShowPatronModal(false)}
              centered
              className='modal-dialog-centered modal-dialog-scrollable'
            >
              <Modal.Header>
                <h5 className='modal-title'>Edit Patron</h5>
                <button
                  type='button'
                  className='btn-close'
                  onClick={() => setShowPatronModal(false)}
                />
              </Modal.Header>

              <Modal.Body>
                {/* name */}
                <div className='fv-row mb-5'>
                  <div className='row'>
                    <div className='col'>
                      <label className='form-label fw-bold fs-6'>Name</label>

                      <Field name='name' disabled={isPatronLoggedIn} className='form-control ' />
                      <ErrorMessage name='name' component='div' className='text-danger my-3' />
                    </div>
                  </div>
                </div>

                {/* email */}
                <div className='fv-row mb-5'>
                  <div className='row'>
                    <div className='col'>
                      <label className='form-label fw-bold fs-6'>Email</label>

                      <Field name='email' disabled={isPatronLoggedIn} className='form-control' />
                      <ErrorMessage name='email' component='div' className='text-danger my-3' />
                    </div>
                  </div>
                </div>

                {/* phone */}
                <div className='fv-row'>
                  <div className='row'>
                    <div className='col'>
                      <label className='form-label fw-bold fs-6'>Phone</label>

                      <PhoneField name='phone' disabled={isPatronLoggedIn} />
                      <ErrorMessage name='phone' component='div' className='text-danger my-3' />
                    </div>
                  </div>
                </div>

                {!isPatronLoggedIn && (
                  <>
                    <div className='separator my-10'></div>
                    <div className='fv-row mb-5'>
                      <div className='row'>
                        <div className='col'>
                          <div className='d-flex me-5'>
                            <label className='form-check cursor-pointer'>
                              <Field type='checkbox' name='isBanned' className='form-check-input' />
                              <span className='form-check-label fw-bold fs-6'>Ban Patron</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* unsubscribed from email */}
                    <div className='fv-row mb-5'>
                      <div className='row'>
                        <div className='col'>
                          <div className='d-flex me-5'>
                            <label className='form-check cursor-pointer'>
                              <Field
                                type='checkbox'
                                name='unsubscribedFromEmail'
                                className='form-check-input'
                                disabled={form.values.isBanned}
                              />
                              <span className='form-check-label fw-bold fs-6'>
                                Unsubscribed from Email
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* unsubscribed from sms */}
                    <div className='fv-row mb-5'>
                      <div className='row'>
                        <div className='col'>
                          <div className='d-flex me-5'>
                            <label className='form-check cursor-pointer'>
                              <Field
                                type='checkbox'
                                name='unsubscribedFromSMS'
                                className='form-check-input'
                                disabled={form.values.isBanned}
                              />
                              <span className='form-check-label fw-bold fs-6'>
                                Unsubscribed from SMS
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </Modal.Body>
              <Modal.Footer>
                <button
                  type='button'
                  onClick={() => setShowPatronModal(false)}
                  className='btn btn-light btn-active-light-primary me-2'
                >
                  Cancel
                </button>
                {!isPatronLoggedIn && (
                  <button
                    type='button'
                    onClick={() => form.submitForm()}
                    className='btn btn-primary'
                    disabled={!form.isValid || form.isSubmitting}
                  >
                    {form.isSubmitting ? (
                      <span
                        className='spinner-border spinner-border-sm me-2'
                        role='status'
                        aria-hidden='true'
                      ></span>
                    ) : (
                      'Save Changes'
                    )}
                  </button>
                )}
              </Modal.Footer>
            </Modal>
          </Form>
        )}
      </Formik>
    </>
  )
}

export {BookingHeader}
