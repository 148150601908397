import {create, StateCreator} from 'zustand'
import {devtools} from 'zustand/middleware'
import {_checkEmailVerification, _sendVerificationEmail} from './awsApi'
import {accountsStore} from '../../pages/accounts/core/accountsStore'

type AWSStore = {
  sendVerificationEmail: (email: string) => Promise<void>
  checkEmailVerification: (email: string) => Promise<boolean>
}

const createStore: StateCreator<AWSStore> = (set, get) => ({
  sendVerificationEmail: async (email: string) => {
    // send email
    await _sendVerificationEmail(email)
  },

  checkEmailVerification: async (email: string) => {
    // check email
    const response = await _checkEmailVerification(email)

    if (response.data.verified) {
      // update the account store
      accountsStore.getState().updateAccount({
        verifiedAWSEmail: email,
      })
    }

    return response.data.verified
  },
})

export const awsStore = create(devtools(createStore))
export const useAWSStore = awsStore
