import React from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {TicketModel, useEventsStore} from '../core/eventsStore'
import {formatDateDMY, formatNumber} from '../../../../_helpers/_helpers'
import clsx from 'clsx'
import {getCSSVariableValue, isMobileDevice} from '../../../../_metronic/assets/ts/_utils'
type TicketItemProps = {
  ticket: TicketModel
  onDelete?: (id: string) => void
  onEdit?: (id: string) => void
}
const TicketItem: React.FC<TicketItemProps> = ({ticket, onDelete, onEdit}: TicketItemProps) => {
  const {currentEvent} = useEventsStore()

  const handleEdit = () => {
    if (onEdit && ticket.id) onEdit(ticket.id)
  }

  const handleDelete = () => {
    if (onDelete && ticket.id) onDelete(ticket.id)
  }

  return (
    <div
      className={clsx(
        'd-flex flex-row bg-light border-top-1 border-top border-end border-bottom w-100 mb-3 py-5 pe-5 ps-0',
        {
          'success-border': ticket.canBook.canBook,
          'danger-border': !ticket.canBook.canBook,
        }
      )}
    >
      <div
        className='d-flex w-100 px-5'
        style={{borderLeft: `5px ${getCSSVariableValue('success')}`}}
      >
        <div className='d-flex flex-column'>
          {/* TICKET NAME */}
          <button
            onClick={handleEdit}
            className='btn btn-custom p-0 d-flex align-items-center mb-3'
          >
            <div className='fw-bolder text-start text-dark me-3 fs-5 pe-3 align-items-start d-flex flex-column'>
              <div className='d-flex align-items-center mb-2'>
                {ticket.isPrivate && (
                  <KTIcon iconName='lock-3' iconType='solid' className='text-dark me-2 fs-3' />
                )}
                <span>{ticket.name}</span>
              </div>

              {ticket.canBook.canBook === false ? (
                <span className='badge badge-light-danger'>{ticket.canBook.reason}</span>
              ) : (
                <>
                  {new Date(ticket.startDate).toISOString() &&
                    new Date(ticket.endDate).toISOString() && (
                      <span className='text-muted fw-semibold fs-7'>
                        <span className='badge badge-light'>
                          {' '}
                          {formatDateDMY(new Date(ticket.startDate).toISOString())}
                        </span>
                        {' to '}
                        <span className='badge badge-light'>
                          {formatDateDMY(new Date(ticket.endDate).toISOString())}
                        </span>
                      </span>
                    )}
                </>
              )}
            </div>
          </button>

          {/* TICKET DETILS */}
          <div className='d-flex'>
            <div className='d-flex w-100 me-10'>
              {currentEvent.sellTickets && (
                <span className='fw-bolder badge badge-light text-dark me-3'>
                  Price: <span className='fw-normal ms-2'>{formatNumber(ticket.price, 2)}</span>
                </span>
              )}
              <span className='fw-bolder badge badge-light text-dark me-3'>
                QTY: <span className='fw-normal ms-2'>{ticket.qty} Spaces</span>
              </span>
              <span className='fw-bolder badge badge-light text-dark me-3'>
                <span className='fw-normal me-2'>
                  {' '}
                  {ticket.checkpointSettings &&
                    ticket.checkpointSettings.reduce((acc, item) => acc + item.qty, 0)}{' '}
                </span>
                Check Points
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* ACTION BUTTONS */}
      {!isMobileDevice() && (
        <div className='d-flex align-items-center '>
          <button
            className='btn btn-icon btn-light-dark btn-active-parimary btn-sm me-3'
            onClick={handleEdit}
          >
            <KTIcon iconName='pencil' iconType='outline' />
          </button>
          <button
            className='btn btn-icon btn-light-dark btn-active-danger btn-sm'
            onClick={handleDelete}
          >
            <KTIcon iconName='trash' iconType='outline' />
          </button>
        </div>
      )}
    </div>
  )
}
export {TicketItem}
