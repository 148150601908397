import axios from 'axios'
const AWS_ENDPOINT = '/aws'
const SEND_VERIFICATION_EMAIL = `${AWS_ENDPOINT}/sendVerificationEmail`
const CHECK_EMAIL_VERIFICATION = `${AWS_ENDPOINT}/checkEmailVerification`

export function _sendVerificationEmail(email: string) {
  return axios.post(SEND_VERIFICATION_EMAIL, {
    email,
  })
}

export function _checkEmailVerification(email: string) {
  return axios.post(CHECK_EMAIL_VERIFICATION, {
    email,
  })
}
