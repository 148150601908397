import {useCallback, useEffect, useRef, useState} from 'react'
import {Modal, Button, Form} from 'react-bootstrap'
import Cropper from 'react-easy-crop'
import {uploadFile} from '../_helpers/_helpers'
import {getCSSVariableValue} from '../_metronic/assets/ts/_utils'

type Props = {
  title?: string
  description?: string
  label?: string
  onImageSave?: (image: string) => void
  initialImage?: string
  uploadError?: string
  aspectRatio: number
  maxImageWidth?: number
}

const ImageInput = ({
  initialImage,
  title,
  label,
  description,
  onImageSave,
  aspectRatio,
  maxImageWidth = 250,
}: Props) => {
  const [showModal, setShowModal] = useState(false)
  const [image, setImage] = useState<string>('')
  // const uploadTitle = title || 'Upload Image'
  const [uploading, setUploading] = useState<boolean>(false)
  const [uploadProgress, setUploadProgress] = useState<number>(0)
  const uploadDescription =
    description ||
    'Your artwork should be a square image, at least 500x500 pixels, and no larger than 5MB. Please upload only images in .jpg, .jpeg, or .png format.'
  // const uploadLabel = label || 'Upload Image'

  const [croppedPixelArea, setCroppedPixelArea] = useState<any>()
  const [crop, setCrop] = useState({x: 0, y: 0})
  const [zoom, setZoom] = useState(1)
  const [layout, setLayout] = useState<string>('default')

  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleClose = () => setShowModal(false)
  const handleShow = () => setShowModal(true)

  const handleFileChange = (e) => {
    const selectedImage = e.target.files[0]

    const url = URL.createObjectURL(selectedImage)
    setImage(url)
    handleShow()
  }

  const onCropComplete = useCallback(async (croppedArea, croppedAreaPixels) => {
    setCroppedPixelArea(croppedAreaPixels)

    // update the profile picture to the cropped image
    if (aspectRatio > 1) {
      setLayout('landscape')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function getCroppedImg(
    imageSrc: string,
    crop: {x: number; y: number; width: number; height: number}
  ) {
    const image = await createImage(imageSrc)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    const maxSize = Math.max(image.width, image.height)
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea
    canvas.height = safeArea

    // translate canvas context to a central location on image to allow rotating around the center
    ctx!.translate(safeArea / 2, safeArea / 2)
    ctx!.translate(-safeArea / 2, -safeArea / 2)

    // draw rotated image and store data.
    ctx!.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5)
    const data = ctx!.getImageData(0, 0, safeArea, safeArea)

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = crop.width
    canvas.height = crop.height

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx!.putImageData(
      data,
      Math.round(0 - safeArea / 2 + image.width * 0.5 - crop.x),
      Math.round(0 - safeArea / 2 + image.height * 0.5 - crop.y)
    )

    // As Base64 string
    // return canvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise<Blob>((resolve) => {
      canvas.toBlob((blob) => {
        resolve(blob!)
      }, 'image/jpeg')
    })
  }

  const handleSaveImage = async () => {
    try {
      // prepare and update state
      const croppedImage = await getCroppedImg(image, croppedPixelArea)

      if (!croppedImage) {
        return
      }

      // close the modal
      setUploading(true)
      setShowModal(false)

      // upload the cropped image
      const url: any = await uploadFile(croppedImage, (progress) => {
        setUploadProgress(progress)

        if (progress === 100) {
          setTimeout(() => {
            setUploadProgress(0)
          }, 1000)
        }
      })

      // update the croppedImage file extension
      setImage(url as string)
      setUploading(false)

      // emit the property to the parent component
      if (onImageSave) onImageSave(url)

      // close the moral
      handleClose()
    } catch (error) {
      console.error('Error uploading image', error)
      setUploading(false)
      // Handle error
    }
  }

  const handleFileButtonClick = () => {
    // Trigger the file input click event
    fileInputRef.current?.click()
  }

  const createImage = (url: string) =>
    new Promise<HTMLImageElement>((resolve, reject) => {
      const image = new Image()
      image.addEventListener('load', () => resolve(image))
      image.addEventListener('error', (error) => reject(error))
      image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
      image.src = url
    })

  useEffect(() => {
    // check if image is loadable
    if (initialImage) {
      const img = new Image()
      img.src = initialImage
      img.onload = () => {
        setImage(initialImage)
      }
      img.onerror = () => {
        setImage('')
      }
    } else {
      setImage('')
    }

    // set layout based on ratio
  }, [initialImage])

  return (
    <>
      <button
        onClick={handleFileButtonClick}
        className='btn btn-custom btn-active-secondary w-100'
        style={{
          border: `1px solid ${getCSSVariableValue('--bs-secondary')}`,
          borderRadius: '5px',
          padding: '10px',
        }}
      >
        <div className='d-flex'>
          <div className='d-flex'>
            {image && image !== '' ? (
              <img
                className={`rounded`}
                src={image}
                alt='Metronic'
                style={{
                  width: `${maxImageWidth}px`,
                  height: `${maxImageWidth / aspectRatio}px`,
                }}
              />
            ) : (
              <div className='d-flex'>
                <i className='ki-solid ki-picture' style={{fontSize: '4rem'}}></i>
              </div>
            )}
          </div>
          <div className='d-flex flex-column mx-5 justify-content-center align-items-start '>
            <h3 className='fs-5'>{title}</h3>
            <p className='fs-7 text-start text-muted'>{uploadDescription}</p>

            <div className='d-flex flex-column'>
              {/* show progress bar */}
              {uploading === true && (
                <div className='progress min-w-200px w-100 h-3px'>
                  <div
                    className='progress-bar progress-bar-striped progress-bar-animated bg-success'
                    role='progressbar'
                    style={{width: `${uploadProgress}%`}}
                    aria-valuenow={uploadProgress}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
              )}
            </div>
          </div>
        </div>
      </button>

      <Form.Control type='file' ref={fileInputRef} hidden={true} onChange={handleFileChange} />

      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Crop Profile Picture</Modal.Title>
        </Modal.Header>
        <Modal.Body className='h-500px'>
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={aspectRatio}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
          <Button variant='primary' onClick={handleSaveImage}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ImageInput
