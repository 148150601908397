import {Link, useNavigate} from 'react-router-dom'

import clsx from 'clsx'
import {useMessageStore} from './core/messagesStore'
import {
  daysUntilDate,
  formatNumber,
  removeHtmlTags,
  trimCharacters,
  trimString,
} from '../../../_helpers/_helpers'
import {KTIcon} from '../../../_metronic/helpers'

const MessagesTable = () => {
  const {messages, pagination, setCurrentMessage, cloneMessage} = useMessageStore()
  const navigate = useNavigate()

  const handleNavigate = (message) => {
    setCurrentMessage(message)
    navigate(`/messages/${message.id}/`)
  }
  return (
    <>
      {/* begin::Table */}
      <div className='table-responsive'>
        <div className='text-gray-600 fw-bold'>
          {/* end::Table head */}
          {/* begin::Table body */}
          <div className='d-flex flex-column'>
            {messages.length > 0 &&
              messages.map((message) => {
                return (
                  <div
                    key={message.id!}
                    onClick={() => handleNavigate(message)}
                    className='bg-hover-light px-0 border-1 border-bottom  d-flex py-5 cursor-pointer'
                  >
                    {/* START:: SOMETHING */}
                    <div className='bg-hover d-flex text-start p-0 flex-column flex-grow-1'>
                      <div className='mb-2 d-flex align-items-center mx-5'>
                        <div className='symbol symbol-50px me-5'>
                          {/* FIRST LETTER */}
                          <span
                            className={clsx(`symbol-label text-white fw-bolder`, {
                              'bg-secondary': message.status === 'draft',
                              'bg-success': message.status === 'sent',
                              'bg-danger': message.status === 'failed',
                              'bg-warning': message.status === 'pending',
                              'bg-info': message.status === 'scheduled',
                              'bg-dark': message.status === 'sending',
                            })}
                          >
                            {message.name ? message.name.charAt(0).toUpperCase() : 'A'}
                          </span>
                        </div>

                        {/* NAME & DATES */}
                        <div className='d-flex flex-column min-w-450px justify-content-start'>
                          <div className='d-flex align-items-center'>
                            <span className='text-dark fw-bold text-hover-primary mb-1 fs-5 me-3'>
                              {trimCharacters(message.name, 50)}
                            </span>
                            <span
                              className={clsx(`badge`, {
                                'badge-secondary': message.status === 'draft',
                                'badge-success': message.status === 'sent',
                                'badge-danger': message.status === 'failed',
                                'badge-warning': message.status === 'pending',
                                'badge-info': message.status === 'scheduled',
                                'badge-dark': message.status === 'sending',
                              })}
                            >
                              {message.status}
                            </span>
                            {message.channels &&
                              message.channels.length > 0 &&
                              message.channels.map((channel) => {
                                return (
                                  <span
                                    key={`channel-${channel}`}
                                    className={
                                      'badge badge-light border border-secondary text-dark ms-2'
                                    }
                                  >
                                    {channel}
                                  </span>
                                )
                              })}
                          </div>
                          <div className='d-flex align-items-center'>
                            <span className='text-start fw-normal text-muted'>
                              Created{' '}
                              {message.dateCreated && daysUntilDate(message.dateCreated, true)} ago
                            </span>
                            {message.status === 'failed' && (
                              <span className='text-start fw-normal d-flex align-items-center text-muted ms-3'>
                                <KTIcon
                                  iconName='explamation-circle'
                                  iconType='outline'
                                  className='me-1 fs-5 p-0'
                                />
                                {message.note}
                              </span>
                            )}
                            {message.scheduledFor && message.status !== 'sent' && (
                              <span className='text-start fw-normal d-flex align-items-center text-muted mt-1 ms-3'>
                                <KTIcon
                                  iconName='timer'
                                  iconType='outline'
                                  className='me-1 fs-5 p-0'
                                />
                                Sending in{' '}
                                {message.scheduledFor && daysUntilDate(message.scheduledFor, true)}{' '}
                              </span>
                            )}

                            {message.status === 'sent' && (
                              <span className='text-start fw-normal d-flex align-items-center text-muted mt-1 ms-3'>
                                <KTIcon
                                  iconName='check-circle'
                                  iconType='outline'
                                  className='me-1 fs-5 p-0'
                                />
                                Sent{' '}
                                {message.dateCreated && daysUntilDate(message.dateCreated, true)}
                                {'ago '}
                              </span>
                            )}
                          </div>
                        </div>

                        {/* LOGS */}
                        <div className='d-flex flex-column min-w-250px justify-content-start'>
                          <div className='d-flex align-items-start'>
                            {/* recipients */}
                            {message.status !== 'sent' && (
                              <div className='w-75px d-flex flex-column align-items-center rounded bg-gray-200 p-3 ms-3'>
                                <span className='fw-normal fw-bolder text-dark fs-5 p-0'>
                                  {message.recipients.number}
                                </span>
                                <span className='fw-normal fs-7 text-dark'>Recipients</span>
                              </div>
                            )}
                            {message.logs && message.logs.length > 0 && (
                              <>
                                <div className='w-75px d-flex flex-column align-items-center rounded bg-gray-200 p-3 ms-3'>
                                  <span className='fw-normal fw-bolder text-dark fs-5 p-0'>
                                    {message.dispatched ? message.dispatched : 0}
                                  </span>
                                  <span className='fw-normal fs-7 text-dark'>Dispatched</span>
                                </div>
                                {message.logs.map((log) => {
                                  let pct = 0
                                  if (message.dispatched && message.dispatched > 0 && log.patrons)
                                    pct = (log.patrons / message.dispatched) * 100
                                  return (
                                    <div
                                      key={`${message.id}-${log.action}`}
                                      className='w-75px d-flex flex-column align-items-center rounded bg-gray-200 p-3 ms-3'
                                    >
                                      <span className='fw-normal fw-bolder text-dark fs-5 p-0'>
                                        {formatNumber(pct, 0, false)}%
                                      </span>
                                      <span className='fw-normal fs-8 text-dark'>{`${log.patrons} ${log.action}`}</span>
                                    </div>
                                  )
                                })}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* END:: STATUS */}

                    {/* START:: VALUE */}

                    {/* END:: VALUE */}
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </>
  )
}

export {MessagesTable}
