const placeholders = {
  generic: [
    'recipientName',
    'recipientEmail',
    'recipientPhone',
    'skybookingsPhoneLink',
    'skybookingsEmailLink',
  ],
  event: [
    'eventName',
    'eventUrl',
    'patronLoginUrl',
    'artworkImg',
    'bannerImg',
    'startDate',
    'endDate',
    'handle',
    'location',
    'organiserName',
    'organiserEmail',
    'organiserPhone',
    'organiserWebsite',
    'bookingLink',
    'eventDate',
    'eventBookingButton',
    'eventBookingLink',
  ],
  booking: ['bookingName', 'bookingStatus', 'bookingDate', 'bookingLink'],
}

export default placeholders

//    eventTemplateData.eventName = event.name;
// eventTemplateData.eventUrl = `${config.urls.eventUrl}/${event.handle}`;
// eventTemplateData.patronLoginUrl = `${config.urls.patronLoginUrl}/`;
// eventTemplateData.artwork = event.artwork;
// eventTemplateData.artworkImg = `<img src='${event.artwork}' alt='${event.name}' style='width: 100%; height: auto;'/>`;
// eventTemplateData.banner = event.banner;
// eventTemplateData.bannerImg = `<img src='${event.banner}' alt='${event.name}' style='width: 100%; height: auto;'/>`;
// eventTemplateData.startDate = moment(event.startDate).format('D M YY');
// eventTemplateData.endDate = moment(event.endDate).format('D M YY');
// eventTemplateData.handle = event.handle;
// eventTemplateData.location = event.location;
// eventTemplateData.organiserName = event.organiserName;
// eventTemplateData.organiserEmail = event.organiserEmail;
// eventTemplateData.organiserPhone = event.organiserPhone;
// eventTemplateData.organiserWebsite = event.organiserWebsite;
// eventTemplateData.organiserX = event.organiserX;
// eventTemplateData.organiserIG = event.organiserIG;
// eventTemplateData.organiserFB = event.organiserFB;
// eventTemplateData.organiserWhatsapp = event.organiserWhatsapp;
// eventTemplateData.organiserYoutube = event.organiserYoutube;
// eventTemplateData.bookingButton = `<a style="border-radius:5px;color:white;background-color:black;border:none;padding:5px 7px;" href='${config.urls.eventUrl}/${event.handle}/booking'>${event.buttonLabel}</a>`;
// eventTemplateData.eventBookingButton = `<p><a style="border-radius:5px;color:white;background-color:black;border:none;padding:5px 7px;" href='${config.urls.eventUrl}/${event.handle}/booking'>${event.buttonLabel}</a></p>`;
// eventTemplateData.eventBookingLink = `<a href='${config.urls.eventUrl}/${event.handle}/booking'>${event.buttonLabel}</a>`;
