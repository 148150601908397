import './MentionList.scss'

import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react'

const MentionList = forwardRef((props: any, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0)

  const selectItem = (index) => {
    const item = props.items[index]

    if (item) {
      props.command({id: item})
    }
  }

  const upHandler = () => {
    setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length)
  }

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length)
  }

  const enterHandler = () => {
    selectItem(selectedIndex)
  }

  useEffect(() => setSelectedIndex(0), [props.items])

  useImperativeHandle(ref, () => ({
    onKeyDown: ({event}) => {
      if (event.key === 'ArrowUp') {
        upHandler()
        return true
      }

      if (event.key === 'ArrowDown') {
        downHandler()
        return true
      }

      if (event.key === 'Enter') {
        enterHandler()
        return true
      }

      return false
    },
  }))

  return (
    <div className='d-flex shadow border border-secondary flex-column menu min-w-250px w-100 menu-sub menu-sub-dropdown menu-rounded position-relative bg-white overflow-auto dropdown-menu'>
      {props.items.length ? (
        props.items.map((item, index) => (
          <div className='menu-item w-100'>
            <button
              className={`menu-link btn-link btn w-100 bg-hover-light ${
                index === selectedIndex ? 'active' : ''
              }`}
              key={index}
              onClick={() => selectItem(index)}
            >
              <span className='menu-title px-3'>{item}</span>
            </button>
          </div>
        ))
      ) : (
        <div className='item p-5'>No result</div>
      )}
    </div>
  )
})
export default MentionList
