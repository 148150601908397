import {FC, useState} from 'react'
import {Modal} from 'react-bootstrap'
import TiptapEditor from '../../../../_components/TipTap/Tiptap'
import placeholders from '../../../../_helpers/_placeholders'
import {useFormikContext} from 'formik'
import {MessageModel} from '../core/messagesStore'

type Props = {
  onSave(shortContent): void
}
const MessageEditorShortContent: FC<Props> = ({onSave}) => {
  const [show, setShow] = useState(false)
  const {values} = useFormikContext()
  const v = values as MessageModel
  const [shortContent, setShortcontent] = useState(
    v.shortContent ? v.shortContent.replace(/&lt;/g, '<').replace(/&gt;/g, '>') : ''
  )

  const handleSave = () => {
    setShow(false)
    onSave(shortContent)
  }
  return (
    <>
      <button
        type='button'
        onClick={() => setShow(true)}
        className='btn btn-outline btn-active-dark'
      >
        Edit SMS Content
      </button>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Message Content</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className='fw-row mb-10'>
            <label className='form-label fw-bold fs-6'>Short Content</label>
            <TiptapEditor
              name='shortContent'
              onChange={(content) => setShortcontent(content)}
              className='form-control form-contorl-solid'
              showSuggestions
              suggestions={[...placeholders.generic]}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className='bg-light d-flex justify-content-end'>
          <button onClick={() => setShow(false)} className='btn btn-outline btn-sm btn-active-dark'>
            Close
          </button>
          <button onClick={handleSave} className='btn btn-outline btn-sm btn-active-dark'>
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default MessageEditorShortContent
