import axios from 'axios'

export const PATRONS_ENDPOINT = `/patrons`

export type CreatePatronType = {
  name: string
  phone?: number
  email?: string
  isBanned?: boolean
  account?: string
  tags?: string[]
  fName?: string
  lName?: string
  address?: string
  title?: string
  dob?: string
  diet?: string
  mobileNetwork?: string
  jobTitle?: string
  company?: string
  industry?: string
  linkedIn?: string
  facebook?: string
  x?: string
}
export function _postPatronFromEvent(eventId, args: CreatePatronType) {
  return axios.post(`${PATRONS_ENDPOINT}/${eventId}`, args)
}

export function _postPatron(args: CreatePatronType) {
  return axios.post(`${PATRONS_ENDPOINT}/`, args)
}

export function _postPatrons(account, patrons: CreatePatronType[]) {
  return axios.post(`${PATRONS_ENDPOINT}/many`, {account, patrons})
}

export type UpdatePatronType = {
  name: string
  phone: number
  email: string
  isBanned: boolean
}
export const initialUpdatePatronType: UpdatePatronType = {
  name: '',
  phone: 0,
  email: '',
  isBanned: false,
}

export function _patchPatron(id, args: Partial<UpdatePatronType>) {
  return axios.patch(`${PATRONS_ENDPOINT}/${id}/`, args)
}

export function _getPatronMe() {
  return axios.get(`${PATRONS_ENDPOINT}/me`)
}

export function _getPatrons(query) {
  return axios.get(`${PATRONS_ENDPOINT}/`, {params: {...query}})
}

export function _getPatronsFromBookings(query) {
  return axios.get(`${PATRONS_ENDPOINT}/bookings`, {params: {...query}})
}

export function _exportPatrons(query) {
  return axios.get(`${PATRONS_ENDPOINT}/export/`, {params: {...query}})
}

export function _searchPatron(search) {
  return axios.get(`${PATRONS_ENDPOINT}/search/`, {params: {...search}})
}

export function _searchPatrons(search) {
  return axios.get(`${PATRONS_ENDPOINT}/search-many`, {params: {...search}})
}

export function _deletePatron(id) {
  return axios.delete(`${PATRONS_ENDPOINT}/${id}/`)
}

export function _createPatronOTP(type, data) {
  return axios.post(`${PATRONS_ENDPOINT}/otp/`, {
    [type]: data,
  })
}

export function _verifyPatronOTP(OTP: number) {
  return axios.post(`${PATRONS_ENDPOINT}/otp/verify/`, {
    OTP,
  })
}

export function _getPatronQueries(queries) {
  return axios.get(`${PATRONS_ENDPOINT}/queries/`, {params: {...queries}})
}

export function _postPatronQuery(query) {
  return axios.post(`${PATRONS_ENDPOINT}/queries/`, query)
}

export function _patchPatronQuery(id, query) {
  return axios.patch(`${PATRONS_ENDPOINT}/queries/${id}/`, query)
}

export function _deletePatronQuery(id) {
  return axios.delete(`${PATRONS_ENDPOINT}/queries/${id}/`)
}

// PATRON TAGS
export function _getPatronTags(account) {
  return axios.get(`${PATRONS_ENDPOINT}/tags/`, {params: {...account}})
}

export function _patchPatronTags(tags) {
  return axios.patch(`${PATRONS_ENDPOINT}/tags/`, tags)
}
